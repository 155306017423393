import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useDarkModeFlag } from '../../contexts/DarkModeProvider';
import { twFonts } from '../../theme';

type IBodyProps = React.HTMLAttributes<HTMLDivElement> & { variant?: 'contrast' };

export const Body1 = React.forwardRef<HTMLDivElement, IBodyProps>((props: IBodyProps, ref) => {
  const { children, variant, className, ...rest } = props;
  const isDark = useDarkModeFlag();
  const color = variant === 'contrast' ? 'text-bodyContrast' : isDark ? 'text-white' : 'text-black';
  return (
    <div className={twMerge(twFonts.Body1, color, className)} {...rest} ref={ref}>
      {children}
    </div>
  );
});

export const useBody1Classes = (props?: { variant?: 'contrast' }): string => {
  const { variant } = props ?? {};
  const isDark = useDarkModeFlag();
  const color = variant === 'contrast' ? 'text-bodyContrast' : isDark ? 'text-white' : 'text-black';
  return `${color} ${twFonts.Body1}`;
};
