import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useDarkModeFlag } from '../../contexts/DarkModeProvider';
import { twFonts } from '../../theme';

export const Heading = (props: React.HTMLAttributes<HTMLDivElement> & { variant?: 'contrast' }) => {
  const { children, className, variant, ...rest } = props;
  const isDark = useDarkModeFlag();
  return (
    <div className={twMerge(className, getColor(variant === 'contrast', isDark), twFonts.Heading)} {...rest}>
      {children}
    </div>
  );
};

function getColor(isContrast: boolean, isDark: boolean) {
  if (isContrast) {
    return isDark ? 'text-bodyContrast' : 'text-blackContrast';
  } else {
    return isDark ? 'text-white' : 'text-black';
  }
}
