import { Navigate, useLocation } from 'react-router-dom';
import { ANON_PATH } from '../../constants';
import { useSession } from '../../contexts/SessionProvider';
import { isAdmin, isManager, isRainMaker } from '../../utils/helper';
import { PresenceWrapper } from '../page/PresenceWrapper';
import { AuthenticatedRoute } from './AuthenticatedRoutes';
import { checkRedirects } from './checkRedirects';

export const RainMakerRoutes = ({
  children,
  fallback,
  hasExit,
}: {
  children: React.ReactElement | null;
  fallback?: React.ReactElement;
  hasExit?: boolean;
}) => {
  if (hasExit) {
    return <AuthenticatedRoute children={children} fallback={fallback} hasExit={hasExit} />;
  }
  return (
    <PresenceWrapper>
      <Content children={children} fallback={fallback} />
    </PresenceWrapper>
  );
};

const Content = ({
  children,
  fallback,
  hasExit,
}: {
  children: React.ReactElement | null;
  fallback?: React.ReactElement;
  hasExit?: boolean;
}) => {
  const { session } = useSession();
  const location = useLocation();

  if (!session) {
    return <Navigate to={ANON_PATH} state={{ from: location }} replace />;
  }

  const redirect = checkRedirects(session);
  if (redirect) {
    return redirect;
  }

  if (!isAdmin(session) && !isManager(session) && !isRainMaker(session)) {
    return <Navigate to="/error" state={{ from: location }} />;
  }

  return <AuthenticatedRoute children={children} fallback={fallback} hasExit={hasExit} />;
};
