import { twMerge } from 'tailwind-merge';
import { useDarkModeFlag } from '../../contexts/DarkModeProvider';
import theme, { twFonts } from '../../theme';

export const SubHeading = (props: React.HTMLAttributes<HTMLDivElement> & { variant?: 'contrast' }) => {
  const { children, className, variant, ...rest } = props;
  const isDark = useDarkModeFlag();
  return (
    <div className={twMerge(getColor(variant === 'contrast', isDark), twFonts.SubHeading, className)} {...rest}>
      {children}
    </div>
  );
};

function getColor(isContrast: boolean, isDark: boolean) {
  if (isContrast) {
    return isDark ? theme.colors.blackContrast : theme.colors.bodyContrast;
  } else {
    return isDark ? theme.colors.white : theme.colors.black;
  }
}
