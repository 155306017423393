import React from 'react';
import { twMerge } from 'tailwind-merge';
import { ButtonSize, useButtonPadding } from '../../../hooks/useButtonPadding';
import { Button, ButtonProps } from './Button';

export type SecondaryButtonProps = Omit<ButtonProps, 'kind'> & { border?: boolean; size?: ButtonSize };
export const SecondaryButton = React.memo(
  React.forwardRef<HTMLButtonElement, SecondaryButtonProps>((props, ref) => {
    const { border, className, variant, size, ...rest } = props;
    let bgColor;
    let bgPressed;
    let bgDisabled;
    let textColor;
    let fillColor;
    let outlineColor;
    switch (variant) {
      case 'dark':
        bgColor = 'bg-neutral-800';
        bgPressed = 'active:bg-primary-900 enabled:hover:bg-primary-900';
        bgDisabled = 'disabled:bg-black';
        textColor = 'text-white';
        fillColor = 'fill-white';
        outlineColor = border ? 'border-neutral-800' : 'outline-neutral-800';
        break;
      case 'contrast':
        bgColor = 'bg-transparent';
        bgPressed = 'active:bg-neutral-300 enabled:hover:bg-neutral-300';
        bgDisabled = 'disabled:bg-neutral-200';
        textColor = 'text-black group-disabled:text-neutral-600';
        fillColor = 'fill-black group-disabled:fill-neutral-600';
        outlineColor = border
          ? 'border-neutral-600 disabled:border-neutral-500'
          : 'outline-neutral-600 disabled:outline-neutral-500 -outline-offset-1';
        break;
      case 'contrast-dark':
        bgColor = 'bg-transparent';
        bgPressed = 'active:bg-neutral-800 enabled:hover:bg-neutral-800';
        bgDisabled = 'disabled:bg-black';
        textColor = 'text-white group-disabled:text-neutral-700';
        fillColor = 'fill-white group-disabled:fill-neutral-700';
        outlineColor = border
          ? 'border-neutral-600 disabled:border-neutral-700'
          : 'outline-neutral-600 disabled:outline-neutral-700';
        break;
      default:
        bgColor = 'bg-primary-100';
        bgPressed = 'active:bg-primary-200 enabled:hove:bg-primary-200';
        bgDisabled = 'disabled:bg-neutral-200';
        textColor = 'text-rain group-disabled:text-neutral-600';
        fillColor = 'fill-rain group-disabled:fill-neutral-600';
        outlineColor = border ? 'border-rain disabled:border-neutral-500' : 'outline-rain disabled:outline-neutral-500';
        break;
    }
    const padding = useButtonPadding(size);
    return (
      <Button
        {...rest}
        className={twMerge(
          bgColor,
          'group',
          border ? 'border' : 'outline outline-1',
          outlineColor,
          padding,
          className,
          bgPressed,
          bgDisabled,
        )}
        fillColor={fillColor}
        textColor={textColor}
        variant={variant}
        ref={ref}
      >
        {props.children as any}
      </Button>
    );
  }),
);
