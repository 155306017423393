import { twMerge } from 'tailwind-merge';
import { useDarkModeFlag } from '../../contexts/DarkModeProvider';
import { twFonts } from '../../theme';

type IBodyProps = React.HTMLAttributes<HTMLDivElement> & { variant?: 'contrast'; clip?: boolean };

export const Body2 = (props: IBodyProps) => {
  const { children, clip, className, variant, ...rest } = props;
  const isDark = useDarkModeFlag();
  const color = variant === 'contrast' ? 'text-bodyContrast' : isDark ? 'text-white' : 'text-black';
  return (
    <div className={twMerge(twFonts.Body2, color, clip ? 'line-clamp-2' : undefined, className)} {...rest}>
      {children}
    </div>
  );
};
