export type ButtonSize = 'lg' | 'sm' | 'md';

export const useButtonPadding = (size: ButtonSize = 'md') => {
  const isLargeSize = size === 'lg';
  const isSmallSize = size === 'sm';

  if (isLargeSize) {
    return 'py-3.5 px-7 md:py-5 md:px-10';
  }
  if (isSmallSize) {
    return 'py-1 px-2';
  }
  return 'py-2.5 px-5 md:py-3 md:px-6';
};
