import React from 'react';

export function createCtx<T extends unknown | null>(initial?: T) {
  const ctx = React.createContext<T | undefined>(initial || undefined);
  function useCtx() {
    const c = React.useContext(ctx);
    if (c !== undefined) {
      return c;
    }
    if (initial !== undefined) {
      return initial;
    }
    throw new Error('useCtx must be inside a Provider with a value');
  }
  return [useCtx, ctx.Provider, ctx.Consumer] as const;
}
