import { gql, useLazyQuery } from '@apollo/client';
import { CompanyUploadUrlQuery, ProfileUploadUrlQuery } from '../gql/graphql';

const COMPANY_UPLOAD_URL_QUERY = gql`
  query companyUploadUrl($companyId: String!, $usage: String!, $type: String!, $sha256: String) {
    companyUploadUrl(companyId: $companyId, usage: $usage, type: $type, sha256: $sha256) {
      uuid
      url
      getUrl
    }
  }
`;

const PROFILE_UPLOAD_URL_QUERY = gql`
  query profileUploadUrl($profileId: String!, $usage: String!, $type: String!) {
    uploadUrl(profileId: $profileId, usage: $usage, type: $type) {
      uuid
      url
    }
  }
`;

export const useUploadCompanyMedia = () => {
  return useLazyQuery<CompanyUploadUrlQuery>(COMPANY_UPLOAD_URL_QUERY);
};

export const useUploadProfileMedia = () => {
  return useLazyQuery<ProfileUploadUrlQuery>(PROFILE_UPLOAD_URL_QUERY);
};
