import { TriangleDownIcon, TriangleUpIcon } from '@radix-ui/react-icons';
import { twMerge } from 'tailwind-merge';
import { Sort } from '../../gql/graphql';
import { HStack } from '../HStack';
import { VStack } from '../VStack';

export type Ordering<Fields> = { field: Fields; dir: Sort };

export function ToggleSort<Fields>({
  children,
  field,
  onSort,
  sort,
}: {
  children: React.ReactNode;
  field?: Ordering<Fields>['field'];
  sort?: Ordering<Fields>;
  onSort: (s: Ordering<Fields>) => void;
}) {
  return (
    <div
      className={twMerge('flex flex-1 select-none', field && 'cursor-pointer')}
      onClick={
        field
          ? () => {
              if (field === sort?.field) {
                onSort({ field, dir: sort.dir === Sort.Asc ? Sort.Desc : Sort.Asc });
              } else {
                onSort({ field: field!, dir: Sort.Asc });
              }
            }
          : undefined
      }
    >
      <HStack className="flex-1 items-center justify-between">
        {children}
        <VStack>
          <TriangleUpIcon
            width={20}
            height={20}
            className={field === sort?.field && sort?.dir === Sort.Asc ? 'text-primary-400' : ''}
          />
          <TriangleDownIcon
            width={20}
            height={20}
            className={`mt-[-12px] ${field === sort?.field && sort?.dir === Sort.Desc ? 'text-primary-400' : ''}`}
          />
        </VStack>
      </HStack>
    </div>
  );
}
