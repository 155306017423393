import { HStack } from '../HStack';
import { VStack } from '../VStack';
import { renderError } from './ErrorMessage';
import { HelperText } from './HelperText';

export const FormControlWrapper = ({
  children,
  error,
  helperText,
}: {
  children: React.ReactNode;
  error?: string;
  helperText?: React.ReactNode;
}) => {
  return (
    <VStack className="flex-1 z-0">
      {children}
      {error || helperText ? (
        <HStack className="justify-between items-center">
          {renderError(error)}
          {renderHelpText()}
        </HStack>
      ) : null}
    </VStack>
  );

  function renderHelpText() {
    if (typeof helperText === 'string') {
      return <HelperText>{helperText}</HelperText>;
    } else if (helperText) {
      return helperText;
    }
    return null;
  }
};
