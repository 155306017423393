import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useDarkModeFlag } from '../contexts/DarkModeProvider';
import { Display } from './typography/Display';

export const ImagePlaceholder = (props: React.HTMLAttributes<HTMLDivElement> & { name: string; isPerson: boolean }) => {
  const { name, isPerson, className, ...rest } = props;
  const isDark = useDarkModeFlag();
  const [fontSize, setFontSize] = React.useState<string>('16px');
  return (
    <div
      className={twMerge(
        `${isDark ? 'bg-neutral-600' : 'bg-neutral-300'} flex justify-center items-center aspect-square w-full h-full`,
        props.onClick ? 'cursor-pointer' : undefined,
        className,
      )}
      {...rest}
      ref={(r) => {
        if (r) {
          const styles = window.getComputedStyle(r);
          const min = Math.min(parseInt(styles.height), parseInt(styles.width));
          setFontSize(`${Math.max(0.25 * min, 16)}px`);
        }
      }}
    >
      {name ? (
        <Display
          size="xl"
          className={twMerge('select-none', isDark ? 'text-white' : 'text-neutral-600')}
          style={{ fontSize }}
        >
          {isPerson ? getPersonInitials(name) : getEntityInitial(name)}
        </Display>
      ) : null}
    </div>
  );
};

function getEntityInitial(name: string) {
  return name[0].toUpperCase();
}

function getPersonInitials(name: string) {
  const split = name.split(' ');
  return [split?.[0]?.[0], split?.[1]?.[0]].join('').toUpperCase();
}
