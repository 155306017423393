import React from 'react';
import { useBreakpointValue } from '../hooks/useBreakpointValue';
import { Divider } from './Divider';
import { HStack } from './HStack';
import { VStack, VStackProps } from './VStack';
import { Body2 } from './typography/Body2';
import { Heading } from './typography/Heading';
import { SubHeading } from './typography/SubHeading';

export type TopicProps = {
  children?: React.ReactNode;
  contentClassName?: string;
  containerClassName?: string;
  headerClassName?: string;
  divide?: boolean;
  icon?: React.ReactNode;
  small?: boolean;
  subtitle?: React.ReactNode;
  title: React.ReactNode;
  titleRightElement?: React.ReactNode;
  containerSpace?: VStackProps['space'];
};
export const Topic = React.forwardRef<HTMLDivElement, TopicProps>(
  (
    {
      title,
      subtitle,
      titleRightElement,
      small,
      divide,
      contentClassName,
      containerClassName,
      children,
      headerClassName,
      icon,
      containerSpace,
    },
    ref,
  ) => {
    const { space, contentSpace } = useBreakpointValue({
      base: { space: small ? '1.5' : '2', contentSpace: '3' },
      md: { space: '2', contentSpace: '4' },
    });

    const Title = small ? SubHeading : Heading;
    return (
      <VStack space={containerSpace || space} className={containerClassName} ref={ref}>
        <VStack space={contentSpace} className={headerClassName}>
          <VStack space="1">
            <HStack className="justify-between w-full">
              <HStack className="items-center w-full" space={icon ? '3' : undefined}>
                <Title>{title}</Title>
                {icon}
              </HStack>
              {titleRightElement ?? null}
            </HStack>
            {subtitle ? <Body2 className="text-body">{subtitle}</Body2> : null}
          </VStack>
          {divide ? <Divider /> : null}
        </VStack>
        <VStack space={space} className={contentClassName}>
          {children}
        </VStack>
      </VStack>
    );
  },
);
