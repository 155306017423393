import * as ScrollArea from '@radix-ui/react-scroll-area';
import React, { UIEventHandler } from 'react';
import { twMerge } from 'tailwind-merge';

type ScrollViewProps = {
  children: React.ReactNode;
  className?: string;
  id?: string;
  onScroll?: UIEventHandler<HTMLDivElement>;
};

export const ScrollView = React.forwardRef<HTMLDivElement, ScrollViewProps>(
  ({ children, className, id, onScroll }, ref) => (
    <ScrollArea.Root className="overflow-hidden flex flex-1">
      {/* https://github.com/radix-ui/primitives/issues/926 */}
      <ScrollArea.Viewport
        className={twMerge('w-full h-full scroll-smooth [&>div]:!flex [&>div]:flex-col [&>div]:h-full', className)}
        id={id}
        onScroll={onScroll}
        ref={ref}
      >
        {children}
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar
        orientation="vertical"
        className="flex select-none touch-none p-0.5 transition-colors duration-[160ms] ease-out hover:bg-neutral-400 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
      >
        <ScrollArea.Thumb className="flex-1 bg-neutral-700 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
      </ScrollArea.Scrollbar>
    </ScrollArea.Root>
  ),
);
