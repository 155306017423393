export type Sizes = '2xl' | 'xl' | 'lg' | 'md' | 'sm';

export const font = "'ABC Whyte', 'sans-serif'";
export const sidePadding = ['16px', '16px', '24px', '40px', '60px'];
export const verticalPadding = ['12px', '12px', '24px', '48px'];
export const twSidePadding = 'px-1 md:px-6 lg:px-10 xl:px-[60px]';
export const twNegSidePadding = '-mx-1 md:-mx-6 lg:-mx-10 xl:-mx-[60px]';
export const twVerticalPadding = 'pt-3 md:pt-6 lg:pt-12 pb-[120px]';
export const twNegVerticalPadding = '-mt-3 md:-mt-6 lg:-mt-12 -mb-[120px]';
export const logoSize = '80px';
export const flex = { flex: 1 };
export const preWrap = { whiteSpace: 'pre-wrap' } as any;

export type SpaceScale =
  | 'px'
  | '0'
  | '0.5'
  | '1'
  | '1.5'
  | '2'
  | '2.5'
  | '3'
  | '3.5'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '14'
  | '16'
  | '20'
  | '24'
  | '28'
  | '32'
  | '36'
  | '40'
  | '44'
  | '48'
  | '52'
  | '56'
  | '60'
  | '64'
  | '72'
  | '80'
  | '96';

export const fontSizes = {
  DisplayXXLarge: { base: '52px', md: '64px' },
  DisplayXLarge: { base: '32px', md: '40px' },
  DisplayLarge: { base: '20px', md: '24px' },
  DisplayMedium: { base: '18px', md: '20px' },
  DisplaySmall: { base: '16px', md: '18px' },
  Heading: { base: '16px', md: '18px' },
  SubHeading: { base: '14px', md: '16px' },
  Caption: { base: '10px', md: '12px' },
  Body1: { base: '16px', md: '16px' },
  Body2: { base: '14px', md: '14px' },
  ButtonText: { base: '14px', md: '14px' },
};
export const twFonts = {
  Body1: 'text-base md:text-base font-normal',
  Body2: 'text-sm font-normal md:text-sm md:font-medium',
  ButtonText: 'text-sm font-medium',
  Caption: 'text-[10px] leading-[0.75rem] md:text-xs font-medium',
  DisplayXXLarge: 'text-[52px] leading-[56px] md:text-[64px] md:leading-[68px] -tracking-[.02em] font-medium',
  DisplayXLarge: 'text-[32px] leading-[46px] md:text-[40px] md:leading-[56px] -tracking-[.02em] font-medium',
  DisplayLarge:
    'text-[20px] leading-[28px] -tracking-[.03em] md:text-[24px] md:leading-[32px] -tracking-[.02em] font-medium',
  DisplayMedium:
    'text-[18px] leading-[22px] -tracking-[.03em] md:text-[20px] md:leading-[28px] -tracking-[.02em] font-medium',
  DisplaySmall: 'text-[16px] leading-[22px] md:text-[18px] md:leading-[28px] -tracking-[.02em] font-medium',
  Heading:
    'text-[16px] leading-[20px] -tracking-[.03em] md:text-[18px] md:leading-[28px] -tracking-[.02em] font-medium',
  SubHeading: 'text-[14px] leading-[20px] md:text-[16px] md:leading-[22px] -tracking-[.02em] font-medium',
};

export const lineHeights = {
  DisplayXXLarge: { base: '56px', md: '68px' },
  DisplayXLarge: { base: '46px', md: '56px' },
  DisplayLarge: { base: '28px', md: '32px' },
  DisplayMedium: { base: '22px', md: '28px' },
  DisplaySmall: { base: '22px', md: '28px' },
  Heading: { base: '20px', md: '28px' },
  SubHeading: { base: '20px', md: '22px' },
  Caption: { base: '12px', md: '14px' },
  Body1: { base: '20px', md: '24px' },
  Body2: { base: '18px', md: '20px' },
  ButtonText: { base: '18px', md: '20px' },
};

export const letterSpacings = {
  DisplayXXLarge: { base: '-0.02em', md: '-0.02em' },
  DisplayXLarge: { base: '-0.02em', md: '-0.02em' },
  DisplayLarge: { base: '-0.03em', md: '-0.02em' },
  DisplayMedium: { base: '-0.03em', md: '-0.02em' },
  DisplaySmall: { base: '-0.02em', md: '-0.02em' },
  Heading: { base: '-0.02em', md: '-0.03em' },
  SubHeading: { base: '-0.02em', md: '-0.02em' },
  Caption: { base: '0.04em', md: '0.04em' },
  Body1: { base: '-0.02em', md: '-0.03em' },
  Body2: { base: '-0.02em', md: '-0.02em' },
  ButtonText: { base: '0.02em', md: '0.02em' },
};

const _regular = 500;
const _book = 400;
export const fontWeights = {
  DisplayXXLarge: { base: _regular, md: _regular },
  DisplayXLarge: { base: _regular, md: _regular },
  DisplayLarge: { base: _regular, md: _regular },
  DisplayMedium: { base: _regular, md: _regular },
  DisplaySmall: { base: _regular, md: _regular },
  Heading: { base: _regular, md: _regular },
  SubHeading: { base: _regular, md: _regular },
  Caption: { base: _regular, md: _regular },
  Body1: { base: _book, md: _book },
  Body2: { base: _book, md: _regular },
  ButtonText: { base: _regular, md: _regular },
};

const neutral = {
  100: '#FFFFFF',
  200: '#F8F8F8',
  300: '#EEEEEE',
  400: '#E5E5E5',
  500: '#BEBEBE',
  600: '#999999',
  700: '#7F7F7F',
  800: '#3F3F3F',
  900: '#191919',
} as const;

const primary = {
  100: '#F6F4FF',
  200: '#E2D1FF',
  300: '#C6A2FF',
  400: '#A974FF',
  500: '#7117FF',
  600: '#5A12CC',
  700: '#430E99',
  800: '#2D0966',
  900: '#160533',
} as const;

const red = {
  100: '#FFDEDE',
  200: '#FFBDBD',
  300: '#FF9C9C',
  400: '#FF7B7B',
  500: '#FF5A5A',
  600: '#CC4848',
  700: '#993636',
  800: '#662424',
  900: '#331212',
} as const;

const green = {
  100: '#DFF4F1',
  200: '#BEE9E2',
  300: '#9EDFD4',
  400: '#7DD4C5',
  500: '#5DC9B7',
  600: '#4AA192',
  700: '#38796E',
  800: '#255049',
  900: '#132825',
} as const;

const yellow = {
  100: '#FEF8D0',
  200: '#FDF0A2',
  300: '#FCE973',
  400: '#FBE145',
  500: '#FADA16',
  600: '#C8AE12',
  700: '#96830D',
  800: '#645709',
  900: '#322C04',
};

const rainTheme = {
  colors: {
    // Add new color
    primary,
    neutral,
    body: neutral[900],
    bodyContrast: neutral[400],
    white: neutral[100],
    black: neutral[900],
    blackContrast: neutral[100],
    green,
    yellow,
    red,
    danger: red[600],
    warning: yellow[500],
    success: green[600],
    rain: primary[500],
    rainLight: primary[100],
  },
  fonts: {
    button: font,
    heading: font,
    body: font,
    mono: font,
  },
};
export default rainTheme;
