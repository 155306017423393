import * as React from "react";
const SvgLogoDark = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "-364.166 16.232 945.547 281.431", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1, 0, 0, 1, -854.166443, -383.768127)" }, /* @__PURE__ */ React.createElement("path", { className: "st0", d: "M682.1774292,580.9206543c-4.289978-7.7709961-10.3209229-14.135498-18.0918579-19.0979004 c-7.7769165-4.9560547-17.828125-8.3740234-30.152771-10.2519531c12.5952759-2.4121094,23.9840088-6.8349609,34.1730957-13.267334 c10.1831055-6.4326172,18.2236938-14.8757324,24.1228027-25.3293457 c5.8931274-10.4528809,8.8446655-22.645752,8.8446655-36.5854492c0-14.4741211-3.5548706-27.4707031-10.654541-38.998291 c-7.1036987-11.5217285-17.4885864-20.6359863-31.1575928-27.3391113C645.5911865,403.3540039,629.2393188,400,610.2115479,400H490 v281.4311523h40.2040405V565.2409668h58.296875c12.8659668,0,23.5195312,1.6081543,31.9626465,4.8242188 c8.4431152,3.2163086,15.1392822,8.3803711,20.102478,15.4780273c4.9562378,7.1057129,8.5050659,16.6843262,10.654541,28.7463379 l11.256897,67.1416016h43.8227539l-14.0715332-70.7592773 C689.8165283,598.6098633,686.4614258,588.6975098,682.1774292,580.9206543z M604.180603,529.0561523h-73.9765625V436.987793 h73.9765625c16.6155396,0,29.8840332,3.8195801,39.8024902,11.4584961 c9.9124146,7.6391602,14.8756104,19.0966797,14.8756104,34.375c0,15.2780762-5.0251465,26.8046875-15.076416,34.5754395 C633.7310791,525.1738281,620.5264893,529.0561523,604.180603,529.0561523z", style: {
  fill: "rgb(255, 255, 255)"
} }), /* @__PURE__ */ React.createElement("rect", { x: 1006.0128784, y: 400, className: "st0", width: 40.2039185, height: 281.4311523, style: {
  fill: "rgb(255, 255, 255)"
} }), /* @__PURE__ */ React.createElement("path", { className: "st0", d: "M1293.0617676,613.0830078L1142.6975098,400h-54.2766113v281.4311523h40.2050781 c0,0,0-227.1555176,0-233.5881348l164.8374023,233.5881348h39.8034668V400h-40.2050781 C1293.0617676,400,1293.0617676,608.8000488,1293.0617676,613.0830078z", style: {
  fill: "rgb(255, 255, 255)"
} }), /* @__PURE__ */ React.createElement("rect", { x: 1384.4069824, y: 400, className: "st0", width: 51.1401367, height: 51.1401367, style: {
  fill: "rgb(255, 255, 255)"
} }), /* @__PURE__ */ React.createElement("path", { className: "st0", d: "M824.7400513,400L719,681.4299316h43.4200439l26.5999756-74.3798828H913.539978l26.5999756,74.3798828 h43.4200439L877.8199463,400H824.7400513z M802.25,570.0600586c0,0,47.9500122-133.7399902,49.0300293-136.6899414 c1.0800171,2.9499512,49.0299683,136.6899414,49.0299683,136.6899414H802.25z", style: {
  fill: "rgb(255, 255, 255)"
} })));
export default SvgLogoDark;
