import { FieldPath, FieldValues, RegisterOptions, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';

export const useFormWrapper = <TFieldValues extends FieldValues = FieldValues, TContext = any>(
  props?: UseFormProps<TFieldValues, TContext>,
): UseFormReturn<TFieldValues, TContext> => {
  const formProps = useForm<TFieldValues>(props);
  const { register, ...rest } = formProps;
  return {
    ...rest,
    register: <TFieldName extends FieldPath<TFieldValues>>(
      name: TFieldName,
      options?: RegisterOptions<TFieldValues, TFieldName>,
    ) => {
      return register(name, {
        ...options,
        onChange: (e) => {
          if (formProps.getFieldState(name).error) {
            rest.clearErrors(name);
          }
          if (options?.onChange) {
            options.onChange(e);
          }
        },
      });
    },
  };
};
