import { AlertStatus } from '../internal/AlertBanner';
import { Body2 } from '../typography/Body2';
import { renderError } from './ErrorMessage';
import { HelperText } from './HelperText';

export interface Status {
  status: AlertStatus;
  content?: React.ReactNode;
}
export const StatusText = (props: { status?: Status; errorOnly?: boolean }) => {
  const { status } = props;
  const invalid = status?.status.status === 'error';
  return (
    <HelpOrErrorMessage invalid={invalid} title={!invalid && props.errorOnly ? undefined : status?.status.title} />
  );
};

export function HelpOrErrorMessage({ invalid, title }: Readonly<{ invalid: boolean; title?: string }>) {
  if (!title) {
    return null;
  }
  if (invalid) {
    return renderError(title);
  }
  return (
    <HelperText>
      <Body2 className="text-success">{title}</Body2>
    </HelperText>
  );
}
