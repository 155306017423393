import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSession } from '../../contexts/SessionProvider';
import { useQueryParams } from '../../hooks/useQueryParams';
import { getFrom, joinSearches } from '../../utils/helper';
import { PresenceWrapper } from '../page/PresenceWrapper';
import { SplitScreenBg } from '../page/SplitScreen';

export const AnonymousRoutes = ({ children }: { children: React.ReactElement }) => {
  return (
    <PresenceWrapper fallback={<SplitScreenBg noNav noLogout />}>
      <AnonymousRoutesWrapper children={children} />
    </PresenceWrapper>
  );
};

const AnonymousRoutesWrapper = ({ children }: { children: React.ReactElement }) => {
  const { session } = useSession();
  const location = useLocation();
  const query = useQueryParams();

  if (session !== null) {
    const from = getFrom(location);
    const moreSearch = query.toString();
    let to;
    if (from) {
      to = from;
      to.search = joinSearches(to.search, moreSearch);
    } else {
      to = '/' + joinSearches(moreSearch);
    }
    return <Navigate to={to} replace />;
  }
  return children;
};
