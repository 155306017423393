import { ApolloProvider, useQuery } from '@apollo/client';
import type { Router } from '@remix-run/router';
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import React from 'react';
import { RawIntlProvider } from 'react-intl';
import { ReactNotifications } from 'react-notifications-component';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import { Loading } from './components/Loading';
import { LoggingProvider, useLogger } from './contexts/LoggingProvider';
import { SessionProvider } from './contexts/SessionProvider';
import { GetSessionInformationQuery } from './gql/graphql';
import './quill.css';
import { getRouter } from './router';
import apolloClient, { logout } from './utils/apollo';
import { GET_SESSION } from './utils/graphql';
import { getServerUrl } from './utils/helper';
import { intl } from './utils/intl';
import { config } from './utils/rollbar';

function ThemedApp({ router }: Readonly<{ router: Router }>) {
  const { loading, error, data } = useQuery<GetSessionInformationQuery>(GET_SESSION);
  const { setSessionContext } = useLogger();

  React.useEffect(() => {
    if (data?.getSessionInformation) {
      setSessionContext(data.getSessionInformation);
    }
    if (!data?.getSessionInformation?.email) {
      return;
    }
    let intervalId: any;
    const startSessionCookieRefresh = () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      intervalId = setInterval(() => {
        fetch(`${getServerUrl()}/session/status`, {
          credentials: import.meta.env.MODE === 'development' ? 'include' : undefined,
        })
          .then((res) => {
            if (res.status === 403) {
              logout();
            }
          })
          .catch((error) => {
            console.log('could not refresh session cookie', error);
          });
      }, 300 * 1000);
    };
    const onBlur = () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    window.addEventListener('focus', startSessionCookieRefresh);
    window.addEventListener('blur', onBlur);
    startSessionCookieRefresh();
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      window.removeEventListener('focus', startSessionCookieRefresh);
      window.removeEventListener('blur', onBlur);
    };
  }, [data, setSessionContext]);

  if (loading) {
    return (
      <div className="flex flex-1 justify-center items-center">
        <Loading pageLoader />
      </div>
    );
  }

  if (error) {
    if (window.location.pathname !== '/logout') {
      window.location.href = '/error.html';
    }
    return null;
  }

  return (
    <SessionProvider data={data}>
      <ReactNotifications />
      <RouterProvider router={router} />
    </SessionProvider>
  );
}

function App() {
  return (
    <RollbarProvider config={config}>
      <ApolloProvider client={apolloClient}>
        <ErrorBoundary>
          <RawIntlProvider value={intl}>
            <LoggingProvider>
              <ThemedApp router={getRouter()} />
            </LoggingProvider>
          </RawIntlProvider>
        </ErrorBoundary>
      </ApolloProvider>
    </RollbarProvider>
  );
}

export default App;
