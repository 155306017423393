import { Location, NavigateFunction, UIMatch, generatePath, useMatches } from 'react-router-dom';
import { CompanyQuery, GetQuestDetailsQuery, StartupProfileQuery } from '../gql/graphql';

export type RouteHandle =
  | {
      crumb: (
        data: StartupProfileQuery & GetQuestDetailsQuery & CompanyQuery,
        matchDetails: {
          location: Location;
          match: Match;
          matches: Match[];
          navigate: NavigateFunction;
        },
      ) => React.ReactNode;
      pattern: string;
    }
  | undefined;
export type Match = UIMatch<unknown, RouteHandle>;

export const useReplaceParams: (
  pattern?: string,
) => (newParamsPartial: Record<string, string>) => string | undefined = (pattern) => {
  const matches = useMatches() as Match[];
  const match = matches.at(-1);
  pattern ??= match?.handle?.pattern;
  return (newParamsPartial: Record<string, string>) =>
    pattern ? generatePath(pattern, { ...match?.params, ...newParamsPartial }) : undefined;
};
