import { ErrorBoundary, RollbarContext } from '@rollbar/react';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Navigate, Outlet, RouteObject, useLocation, useOutlet } from 'react-router-dom';
import { Loading } from './components/Loading';
import {
  BreadcrumbItem,
  CompanyBreadcrumbItem,
  DealDetailsBreadcrumbItem,
  DealsBreadcrumbItem,
  HomeBreadcrumbItem,
  HomeNavBreadcrumbItem,
  StartupBreadcrumbItem,
} from './components/navigation/Breadcrumb';
import { PresenceWrapper } from './components/page/PresenceWrapper';
import { SplitScreen } from './components/page/SplitScreen';
import { AdminRoutes } from './components/routes/AdminRoutes';
import { AnonymousRoutes } from './components/routes/AnonymousContentWrapper';
import { AuthenticatedRoute } from './components/routes/AuthenticatedRoutes';
import { CompanyRoutes } from './components/routes/CompanyRoutes';
import { RainMakerRoutes } from './components/routes/RainMakerRoutes';
import { StartupRoutes } from './components/routes/StartupRoutes';
import { StaticAppRoutes } from './components/routes/StaticAppRoutes';
import { checkRedirects } from './components/routes/checkRedirects';
import { ANON_PATH } from './constants';
import { ActionCenterProvider } from './contexts/ActionCenterProvider';
import { DrawerProvider } from './contexts/DrawerProvider';
import { useSession } from './contexts/SessionProvider';
import { StartupProfileQueryProvider } from './contexts/StartupProfileQueryProvider';
import { ToastProvider } from './contexts/ToastProvider';
import { RouteHandle } from './hooks/useReplaceParams';
import ErrorPage from './pages/Error';
import ForbiddenPage from './pages/Forbidden';
import Welcome from './pages/Welcome';
import { capitalise, getFrom, getFromDeal, isAdmin, isManager, isRainMaker, isStartup } from './utils/helper';

const UserVerificationScreen = React.lazy(() => import('./pages/anon/UserVerification'));
const ForgotPasswordScreen = React.lazy(() => import('./pages/anon/ForgotPassword'));
const GetStartedScreen = React.lazy(() => import('./pages/anon/GetStarted'));
const HoldingPage = React.lazy(() => import('./pages/HoldingPage'));
const LoginScreen = React.lazy(() => import('./pages/anon/Login'));
const LogoutScreen = React.lazy(() => import('./pages/anon/Logout'));
const ResetPasswordScreen = React.lazy(() => import('./pages/anon/ResetPassword'));
const SignupScreen = React.lazy(() => import('./pages/anon/Signup'));
const TermsAndConditionsScreen = React.lazy(() => import('./pages/TermsAndConditions'));

const RainMakerEditProfile = React.lazy(() => import('./pages/rm/Profile'));
const RainMakerWealthScreen = React.lazy(() => import('./pages/rm/Rewards'));

const StartupScreen = React.lazy(() => import('./pages/startup/Index'));

const QuestDetails = React.lazy(() => import('./pages/quest/Details'));
const QuestListScreen = React.lazy(() => import('./pages/quest/Index'));

const CompanyListScreen = React.lazy(() => import('./pages/company/Index'));
const CompanyDetails = React.lazy(() => import('./pages/company/Details'));

const AdminRmRewardsScreen = React.lazy(() => import('./pages/admin/RmRewards'));
const AdminManageUsersScreen = React.lazy(() => import('./pages/admin/ManageUsers'));
const Blotter = React.lazy(() => import('./pages/Blotter'));
const Email = React.lazy(() => import('./pages/Email'));
const RainPoints = React.lazy(() => import('./pages/RainPoints'));
const UploadScreen = React.lazy(() => import('./pages/Upload'));

function RootRedirect() {
  const { session } = useSession();
  const location = useLocation();
  if (!session) {
    return <Navigate to={ANON_PATH} state={{ from: location }} replace />;
  }
  const redirect = checkRedirects(session);
  if (redirect) {
    return redirect;
  }
  const from = getFrom(location);
  if (from) {
    return <Navigate to={from} replace />;
  }
  if (session.defaultView) {
    return <Navigate to={session.defaultView} replace />;
  } else if (isRainMaker(session) || isAdmin(session) || isManager(session)) {
    return <Navigate to="/deals" replace />;
  } else if (isStartup(session)) {
    return <Navigate to="/deals" replace />;
  }
  return <Navigate to="/hold" state={{ winLoc: window.location.href }} />;
}

const fallbackUi = () => {
  const path = window.location.pathname;
  const exluded = new Set<string>(['/', '/deals', '/error']);
  if (!exluded.has(path)) {
    window.location.assign('/');
    return <Loading pageLoader />;
  }
  return <ErrorPage />;
};
const purpleFallbackNoNav = <SplitScreen left={null} right={null} noNav />;

function LayoutWrapper() {
  const location = useLocation();
  const currentPath = location.pathname;
  const outlet = useOutlet();
  return (
    <AnimatePresence mode="wait">
      <ErrorBoundary fallbackUI={fallbackUi} key={currentPath}>
        <ActionCenterProvider skip={currentPath === '/logout'}>
          <ToastProvider>
            <DrawerProvider>
              <StartupProfileQueryProvider>{outlet}</StartupProfileQueryProvider>
            </DrawerProvider>
          </ToastProvider>
        </ActionCenterProvider>
      </ErrorBoundary>
    </AnimatePresence>
  );
}

type AppRoutes = (Omit<RouteObject, 'handle' | 'children'> & {
  handle?: RouteHandle;
  children?: AppRoutes;
})[];

export const appRoutes: RouteObject[] = [
  {
    element: <LayoutWrapper />,
    path: '/',
    handle: {
      pattern: '/',
      crumb() {
        return <HomeBreadcrumbItem key="Home" />;
      },
    },
    children: [
      {
        index: true,
        element: (
          <RollbarContext context="/">
            <PresenceWrapper>
              <RootRedirect />
            </PresenceWrapper>
          </RollbarContext>
        ),
      },
      {
        path: 'hold',
        element: (
          <RollbarContext context="hold">
            <HoldingPage />
          </RollbarContext>
        ),
      },
      {
        path: 'logout',
        element: (
          <RollbarContext context="logout">
            <LogoutScreen />
          </RollbarContext>
        ),
      },
      {
        path: 'terms',
        element: (
          <RollbarContext context="terms">
            <AuthenticatedRoute hasExit>
              <TermsAndConditionsScreen />
            </AuthenticatedRoute>
          </RollbarContext>
        ),
      },
      {
        path: 'welcome',
        element: (
          <AuthenticatedRoute hasExit>
            <Welcome />
          </AuthenticatedRoute>
        ),
      },
      {
        path: 'clients/:startupId',
        handle: {
          crumb: (data, { match, location }) => {
            const title = data.startupProfile?.company?.name ?? 'Client';
            const fromDeal = getFromDeal({ match, location });
            return <StartupBreadcrumbItem title={title} key={title} fromDeal={fromDeal} />;
          },
          pattern: '/clients/:startupId',
        },
        element: (
          <RollbarContext context="startup">
            <StartupRoutes>
              <Outlet />
            </StartupRoutes>
          </RollbarContext>
        ),
        children: [
          {
            index: true,
            element: <StartupScreen />,
          },
          {
            id: 'deal',
            path: 'deals/:questId',
            handle: {
              crumb({ quest, startupProfile }) {
                const questName = quest?.name ?? 'Deal';
                return [
                  <DealsBreadcrumbItem title="Deals" key="Deals" quests={startupProfile?.quests} />,
                  <DealDetailsBreadcrumbItem title={capitalise(questName)} key={questName} />,
                ];
              },
              pattern: '/clients/:startupId/deals/:questId',
            },
            element: (
              <StaticAppRoutes>
                <QuestDetails />
              </StaticAppRoutes>
            ),
            children: [
              {
                id: 'dealTab',
                path: ':questTabId',
                handle: {
                  crumb(_, matchDetails) {
                    let title;
                    switch (matchDetails.match.params.questTabId!) {
                      case 'steps':
                      case 'notes':
                      case 'docusign':
                      case 'activities':
                        title = capitalise(matchDetails.match.params.questTabId);
                        break;
                      case 'docs':
                        title = 'Documents';
                        break;
                      default:
                        title = capitalise(matchDetails.match.params.questTabId ?? '');
                        break;
                    }
                    return <BreadcrumbItem title={title} key={title ?? 'DD'} />;
                  },
                  pattern: '/clients/:startupId/deals/:questId/:questTabId',
                },
                children: [
                  {
                    id: 'gigCard',
                    path: ':gigId',
                  },
                ],
              },
            ],
          },
          {
            path: ':startupTabId',
            element: <StartupScreen />,
            handle: {
              crumb(data, { match }) {
                const startupTabId = match.params.startupTabId!;
                let title;
                switch (startupTabId) {
                  case 'deals':
                    return <DealsBreadcrumbItem title="Deals" key="Deals" quests={data.startupProfile?.quests} />;
                  case 'profile':
                  case 'docusign':
                  case 'activities':
                    title = capitalise(startupTabId);
                    break;
                  case 'docs':
                    title = 'Documents';
                    break;
                  case 'factsheet':
                    title = 'Fact Sheet';
                    break;
                  case 'rm-conflicts':
                    title = 'Declare Conflict';
                    break;
                  case 'view-rm-conflicts':
                    title = 'View Conflicts';
                    break;
                  case 'su-conflicts':
                    title = 'Client Conflicts';
                    break;
                }
                return <BreadcrumbItem title={title ?? ' '} key={title ?? 'SU'} />;
              },
              pattern: '/clients/:startupId/:startupTabId',
            },
          },
        ],
      },
      {
        path: 'deals',
        handle: {
          crumb() {
            return <HomeNavBreadcrumbItem key="Deals" />;
          },
          pattern: '/deals',
        },
        element: (
          <RollbarContext context="deals">
            <StaticAppRoutes>
              <QuestListScreen />
            </StaticAppRoutes>
          </RollbarContext>
        ),
      },
      {
        path: 'companies',
        handle: {
          crumb() {
            return <HomeNavBreadcrumbItem key="Companies" />;
          },
          pattern: '/companies',
        },
        element: (
          <RollbarContext context="companies">
            <StaticAppRoutes>
              <CompanyListScreen />
            </StaticAppRoutes>
          </RollbarContext>
        ),
      },
      {
        path: 'companies/:companyId',
        handle: {
          crumb: () => {
            const title = 'Company Details';
            return <CompanyBreadcrumbItem title={title} key={title} />;
          },
          pattern: '/companies/:companyId',
        },
        element: (
          <RollbarContext context="company">
            <CompanyRoutes>
              <Outlet />
            </CompanyRoutes>
          </RollbarContext>
        ),
        children: [
          {
            index: true,
            element: <CompanyDetails />,
          },
        ],
      },
      {
        id: 'adminRmRewards',
        path: 'admin/rm-rewards/:rmId',
        element: (
          <RollbarContext context="admin/rm-rewards/:rmId">
            <AdminRoutes>
              <AdminRmRewardsScreen />
            </AdminRoutes>
          </RollbarContext>
        ),
      },
      {
        id: 'adminManageUsers',
        path: 'admin/users',
        handle: {
          pattern: '/admin/users',
          crumb() {
            return <BreadcrumbItem key="manage_users" title="Manage Users" />;
          },
        },
        element: (
          <RollbarContext context="admin/users">
            <AdminRoutes>
              <AdminManageUsersScreen />
            </AdminRoutes>
          </RollbarContext>
        ),
      },
      {
        path: 'rm',
        element: (
          <RollbarContext context="rm">
            <Outlet />
          </RollbarContext>
        ),
        children: [
          {
            path: 'profile',
            element: (
              <RainMakerRoutes fallback={purpleFallbackNoNav} hasExit>
                <RainMakerEditProfile />
              </RainMakerRoutes>
            ),
          },
          {
            element: (
              <RainMakerRoutes>
                <Outlet />
              </RainMakerRoutes>
            ),
            handle: {
              crumb() {
                return <HomeNavBreadcrumbItem key="rm-rewards" />;
              },
              pattern: '/rm/rewards',
            },
            children: [
              {
                index: true,
                element: null,
              },
              {
                path: 'rewards',
                element: <RainMakerWealthScreen />,
              },
            ],
          },
        ],
      },
      {
        id: 'blotter',
        path: 'blotter',
        handle: {
          crumb() {
            return <HomeNavBreadcrumbItem key="Blotter" />;
          },
          pattern: '/blotter',
        },
        element: (
          <RollbarContext context="blotter">
            <RainMakerRoutes>
              <Blotter />
            </RainMakerRoutes>
          </RollbarContext>
        ),
      },
      {
        id: 'email',
        path: 'emails/:emailId',
        element: (
          <RollbarContext context="emails/:emailId">
            <AuthenticatedRoute>
              <Email />
            </AuthenticatedRoute>
          </RollbarContext>
        ),
      },
      {
        path: 'upload',
        element: (
          <RollbarContext context="upload">
            <AuthenticatedRoute fallback={<Loading />}>
              <UploadScreen />
            </AuthenticatedRoute>
          </RollbarContext>
        ),
      },
      {
        path: 'points',
        element: (
          <RollbarContext context="points">
            <AuthenticatedRoute>
              <RainPoints />
            </AuthenticatedRoute>
          </RollbarContext>
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <RollbarContext context="forgot-password">
            <AnonymousRoutes>
              <ForgotPasswordScreen />
            </AnonymousRoutes>
          </RollbarContext>
        ),
      },
      {
        path: 'get-started',
        element: (
          <RollbarContext context="get-started">
            <AnonymousRoutes>
              <GetStartedScreen />
            </AnonymousRoutes>
          </RollbarContext>
        ),
      },
      {
        path: 'login',
        element: (
          <RollbarContext context="login">
            <AnonymousRoutes>
              <LoginScreen />
            </AnonymousRoutes>
          </RollbarContext>
        ),
      },
      {
        path: 'reset-password',
        element: (
          <RollbarContext context="reset-password">
            <AnonymousRoutes>
              <ResetPasswordScreen />
            </AnonymousRoutes>
          </RollbarContext>
        ),
      },
      {
        path: 'signup',
        element: (
          <RollbarContext context="signup">
            <AnonymousRoutes>
              <SignupScreen />
            </AnonymousRoutes>
          </RollbarContext>
        ),
      },
      {
        path: 'verify',
        element: (
          <RollbarContext context="verify">
            <AnonymousRoutes>
              <UserVerificationScreen />
            </AnonymousRoutes>
          </RollbarContext>
        ),
      },
    ],
  },
  {
    path: 'error',
    element: <ErrorPage />,
  },
  {
    path: 'forbidden',
    element: <ForbiddenPage />,
  },
  {
    path: '*',
    element: <RootRedirect />,
  },
] satisfies AppRoutes;
