import React, { CSSProperties } from 'react';
import { twMerge } from 'tailwind-merge';
import { SpaceScale } from '../theme';

const DEFAULT_CLASS_NAME = 'flex flex-col min-h-0 min-w-0 items-stretch relative basis-auto shrink-0';

export type VStackProps = {
  children: React.ReactNode;
  className?: string;
  space?: SpaceScale;
  style?: CSSProperties;
};
export const VStack = React.forwardRef<HTMLDivElement, VStackProps>((props, ref) => {
  const gapClass = props.space ? `gap-y-${props.space}` : '';
  const className = twMerge(DEFAULT_CLASS_NAME, gapClass, props.className);
  return (
    <div ref={ref} className={className} style={props.style}>
      {props.children}
    </div>
  );
});
