import { useNavigate } from 'react-router-dom';
import forbiddenImg from '../assets/403.jpeg';
import { VStack } from '../components/VStack';
import { PrimaryButton } from '../components/buttons/core/PrimaryButton';
import { Body1 } from '../components/typography/Body1';

export default function ForbiddenPage() {
  const navigate = useNavigate();

  return (
    <VStack className="justify-center items-center w-full flex-grow bg-[#e7f4fd]">
      <img alt="Forbidden" src={forbiddenImg} />
      <Body1>Sorry, access denied!</Body1>
      <PrimaryButton
        variant="contrast"
        className="mt-3 w-[550px] max-w-[80%]"
        onClick={() => {
          navigate('/');
        }}
        size="lg"
      >
        Home
      </PrimaryButton>
    </VStack>
  );
}
