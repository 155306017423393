import { useIsPresent } from 'framer-motion';
import React from 'react';
import theme from '../../theme';
import { SplitScreen } from './SplitScreen';

export const defaultFallback = <SplitScreen left={null} right={null} backgroundColor={theme.colors.black} />;

export function PresenceWrapper({
  children,
  fallback,
}: {
  children: React.ReactElement;
  fallback?: React.ReactElement;
}) {
  const isPresent = useIsPresent();
  if (!isPresent) {
    return fallback ?? defaultFallback;
  }
  return <React.Suspense children={children} fallback={fallback ?? defaultFallback} />;
}
