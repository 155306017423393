import { twMerge } from 'tailwind-merge';

const DEFAULT_CLASS_NAME = 'shrink-0';

type DividerProps =
  | { orientation?: 'vertical'; h?: string; w?: string; color?: string; style?: React.CSSProperties; className?: string }
  | {
      orientation?: 'horizontal';
      h?: string;
      w?: never;
      color?: string;
      style?: React.CSSProperties;
      className?: string;
    };

export const Divider = ({ orientation = 'horizontal', color, h, w, style, className }: DividerProps) => {
  const colorClassName = color ?? 'bg-neutral-400';
  if (orientation === 'vertical') {
    const width = w ?? 'w-[1px]';
    const height = h ?? 'align-stretch';
    return (
      <div
        className={twMerge(DEFAULT_CLASS_NAME, 'inline-block', height, width, colorClassName, className)}
        style={style}
      />
    );
  }
  const height = h ?? 'h-[1px]';
  return <hr className={twMerge(DEFAULT_CLASS_NAME, 'border-t-0', height, colorClassName, className)} style={style} />;
};
