import { LazyQueryExecFunction, MutationTuple, gql } from '@apollo/client';
import { AlertStatus } from '../components/internal/AlertBanner';
import { LoggingContext } from '../contexts/LoggingProvider';
import { QuestUploadUrlQuery } from '../gql/graphql';

export const GET_SESSION_FRAGMENT = gql`
  fragment SessionInfo on Session {
    as
    companyId
    defaultView
    email
    expireAfter
    isCorporate
    name
    rainMakerId
    roles
    slackChannelId
    termsAgreed
    userId
  }
`;

export const ALLOCATIONS_FRAGMENT = gql`
  fragment AllocationsFields on QuestAllocation {
    uuid
    equity
    gig {
      uuid
    }
    rainMaker {
      uuid
      avatar
      name
    }
  }
`;

export const QUEST_SUMMARY_FRAGMENT = gql`
  fragment QuestSummaryFields on Quest {
    uuid
    name
    nextSteps
    updatedAt
    version
    crew {
      uuid
      name
    }
    startup {
      uuid
      company {
        uuid
        name
        logo
      }
    }
  }
`;

export const GIG_SUMMARY_FRAGMENT = gql`
  fragment GigSummaryFields on Gig {
    uuid
    allocationToRain
    createdAt
    createdByProfileId
    index
    name
    nextGigAllocation
    startDate
    status
    type
    version
    createdBy {
      uuid
    }
  }
`;

export const GIG_FRAGMENT = gql`
  fragment GigFields on Gig {
    uuid
    createdAt
    updatedAt
    allocationToRain
    compensation
    completedAt
    createdByProfileId
    index
    name
    nextGigAllocation
    objective
    outcome
    price
    startDate
    status
    targetDuration
    version
    type
    attachments {
      uuid
      fileName
      fileType
      updatedAt
      url
      startupFactsUuid
    }
    companies {
      uuid
      name
    }
    createdBy {
      uuid
    }
    meetingDetails {
      uuid
      end
      location
      attendees {
        email
        name
      }
    }
    participants {
      uuid
      isLead
      rainMaker {
        uuid
        email
        name
        members {
          uuid
          email
        }
      }
    }
  }
`;

export const QUEST_MEETING_FRAGMENT_SUMMARY = gql`
  fragment QuestMeetingFields on QuestMeeting {
    id
    meetingDate
    updatedAt
    version
    meta {
      attendees
    }
    updatedBy {
      uuid
      contact {
        uuid
        name
      }
    }
  }
`;

export const CREW_MEMBERS_QUERY = gql`
  query crewMembers($crewUuid: ID!) {
    members(crewUuid: $crewUuid) {
      uuid
      roles {
        uuid
        name
      }
      rainMaker {
        uuid
        name
        members {
          uuid
          email
        }
      }
    }
  }
`;

export const REV_SUCCESS_FRAGMENT = gql`
  fragment RevenueSuccessFields on RevenueSuccess {
    uuid
    createdAt
    updatedAt
    allocationToRain
    amount
    effectiveDate
    status
    version
    buyer {
      uuid
      name
      logo
    }
    attachments {
      uuid
      fileName
      fileType
      url
    }
  }
`;

export const NEXT_ALLOCATION_FRAGMENT = gql`
  fragment NextGigAllocationParams on StartupProfile {
    allocationToRain
    effectiveDate
    speedSensitivity
    targetHorizon
    targetMilestoneCount
    targetMilestoneVesting
    targetRevenueIncrement
    targetVesting
    twms
    twrs
  }
`;

export const GET_SESSION = gql`
  ${GET_SESSION_FRAGMENT}
  query getSessionInformation {
    getSessionInformation {
      ...SessionInfo
    }
  }
`;

export const RM_HOME_QUESTS_QUERY = gql`
  query rmHomeQuests {
    quests(statuses: [live]) {
      total
      cursor
      data {
        uuid
        name
        status
        updatedAt
        startup {
          uuid
          company {
            uuid
            name
            logo
          }
        }
      }
    }
  }
`;

export const QUEST_LIST_QUERY = gql`
  ${QUEST_SUMMARY_FRAGMENT}
  query questList($statuses: [QuestStatus!] = [live], $orderBy: [QuestSort!], $startupId: ID, $companyId: ID) {
    quests(statuses: $statuses, orderBy: $orderBy, startupId: $startupId, companyId: $companyId) {
      total
      cursor
      data {
        ...QuestSummaryFields
      }
    }
  }
`;

export const GET_QUEST_DETAILS = gql`
  ${GIG_SUMMARY_FRAGMENT}
  ${NEXT_ALLOCATION_FRAGMENT}
  query getQuestDetails($uuid: ID!, $asStartup: Boolean!) {
    quest(uuid: $uuid) {
      uuid
      createdAt
      contractAddress
      name
      description
      product
      slackChannelId
      status
      timing
      version
      participants {
        uuid
        crewUuid
        isLead
        shareOfCapTable
        rainMaker {
          uuid
          avatar
          name
        }
        roles {
          uuid
          name
        }
      }
      crew {
        uuid
        milestoneSuccessPercentage @skip(if: $asStartup)
        name
        revenueSuccessPercentage @skip(if: $asStartup)
      }
      startup {
        uuid
        currency
        nextGigAllocation
        ...NextGigAllocationParams
        company @skip(if: $asStartup) {
          uuid
          name
          logo
        }
      }
    }
    gigs(
      questUuid: $uuid
      status: { notIn: ["declined", "draft", "expired", "failed"] }
      orderBy: [{ createdAt: desc }, { index: desc }]
    ) {
      ...GigSummaryFields
    }
  }
`;

export const GET_QUEST_MEETINGS = gql`
  ${QUEST_MEETING_FRAGMENT_SUMMARY}
  query meetings($questUuid: ID!, $cursor: QuestMeetingCursor) {
    meetings(questUuid: $questUuid, cursor: $cursor) {
      ...QuestMeetingFields
    }
  }
`;

export const GET_QUEST_MEETING_DETAILS = gql`
  query meetingContent($id: ID!, $transcript: Boolean!, $notes: Boolean!) {
    meetingContent(id: $id) {
      id
      notes @include(if: $notes)
      transcript @include(if: $transcript)
    }
  }
`;

export const QUEST_MUTATION = gql`
  mutation updateBlotter($input: UpdateBlotterInput!, $updateLead: Boolean = false) {
    blotter(input: $input) {
      uuid
      name
      nextSteps
      product
      status
      timing
      version
      participants(where: { roles: { some: { name: lead } } }) @include(if: $updateLead) {
        uuid
        rainMaker {
          uuid
          name
        }
      }
    }
  }
`;

export const UPDATE_QUEST_PARTICIPANTS_MUTATION = gql`
  mutation updateQuestParticipants($input: UpdateQuestParticipantsInput!) {
    updateQuestParticipants(input: $input) {
      uuid
      updatedAt
      version
      participants {
        uuid
        crewUuid
        isLead
        shareOfCapTable
        rainMaker {
          uuid
          avatar
          name
        }
        roles {
          uuid
          name
        }
      }
    }
  }
`;

export const GET_GIG_DETAILS = gql`
  ${GIG_FRAGMENT}
  query getGigDetails($uuid: ID!) {
    gig(uuid: $uuid) {
      ...GigFields
    }
  }
`;

export const CREATE_GIG_MUTATION = gql`
  ${GIG_FRAGMENT}
  mutation createGig($gigForm: GigForm!) {
    gig(gigForm: $gigForm) {
      ...GigFields
    }
  }
`;

export const CREATE_GENERIC_EVENT = gql`
  ${GIG_FRAGMENT}
  mutation createGenericEvent($gigForm: GigForm!) {
    createGenericEvent(gigForm: $gigForm) {
      ...GigFields
    }
  }
`;

export const CREATE_NDA_SIGNED_EVENT = gql`
  ${GIG_FRAGMENT}
  mutation createNdaSignedEvent($gigForm: GigForm!) {
    createNdaSignedEvent(gigForm: $gigForm) {
      ...GigFields
    }
  }
`;

export const UPSERT_TICKET = gql`
  ${GIG_FRAGMENT}
  mutation upsertTicket($ticketForm: TicketForm!) {
    upsertTicket(ticketForm: $ticketForm) {
      ...GigFields
    }
  }
`;

export const ESCROW_GIG_MUTATION = gql`
  mutation escrowGigPayment($gigUuid: ID!, $version: Int!) {
    escrowGigPayment(gigUuid: $gigUuid, version: $version) {
      uuid
      status
      version
    }
  }
`;

export const CANCEL_PAYMENT_INTENT_MUTATION = gql`
  mutation cancelPaymentIntent($gigUuid: ID!) {
    cancelIntent(gigUuid: $gigUuid)
  }
`;

export const CANCEL_GIG_MUTATION = gql`
  mutation cancelGigProposal($gigUuid: ID!) {
    cancelGigProposal(gigUuid: $gigUuid)
  }
`;

export const DECLINE_GIG = gql`
  mutation declineGigProposal($gigUuid: ID!, $version: Int!, $questUuid: ID!) {
    declineGigProposal(gigUuid: $gigUuid, version: $version, questUuid: $questUuid) {
      uuid
      status
      version
      quest {
        uuid
      }
    }
  }
`;

export const ACCEPT_GIG = gql`
  mutation acceptGigProposal($gigUuid: ID!, $version: Int!, $questUuid: ID!) {
    acceptGigProposal(gigUuid: $gigUuid, version: $version, questUuid: $questUuid) {
      uuid
      index
      startDate
      status
      targetDuration
      version
      quest {
        uuid
        status
      }
    }
  }
`;

export const REPORT_GIG_ISSUE_MUTATION = gql`
  mutation reportGigIssue($gigUuid: ID!, $version: Int!, $issue: String!) {
    reportGigIssue(gigUuid: $gigUuid, version: $version, issue: $issue) {
      uuid
      status
      updatedAt
      version
      quest {
        uuid
      }
    }
  }
`;

export const COMPLETE_GIG_MUTATION = gql`
  mutation completeGig($form: CompleteGigForm) {
    completeGig(form: $form) {
      uuid
      completedAt
      objective
      outcome
      status
      updatedAt
      version
      type
      attachments {
        uuid
        fileName
        fileType
        updatedAt
        url
      }
      quest {
        uuid
      }
    }
  }
`;

export const CREATE_REVENUE_SUCCESS = gql`
  ${REV_SUCCESS_FRAGMENT}
  mutation createRevenueSuccess($revenueForm: RevenueForm!) {
    revenueSuccess(revenueForm: $revenueForm) {
      ...RevenueSuccessFields
    }
  }
`;

export const COMPANY_CONTACT_RELATION_FRAGMENT = gql`
  fragment ContactRelationshipFields on Relationship {
    uuid
    type
    contacts {
      uuid
      name
      notes
      email
      linkedin
      phone
      relationships {
        uuid
        type
        companies {
          uuid
          name
          startupProfile {
            uuid
          }
        }
      }
      sourceContact {
        uuid
        name
      }
    }
  }
`;

export const COMPANY_PROFILE_FIELDS = gql`
  ${COMPANY_CONTACT_RELATION_FRAGMENT}
  fragment CompanyFields on Company {
    uuid
    about
    city
    foundedYear
    linkedin
    logo
    name
    website
    country {
      label
      value
    }
    relationships {
      ...ContactRelationshipFields
    }
  }
`;

export const STARTUP_PROFILE_FIELDS = gql`
  ${COMPANY_PROFILE_FIELDS}
  fragment StartupFields on StartupProfile {
    uuid
    arr
    averageContractValue
    averageSalesCycle
    biggestContractValue
    goToMarketStrategy
    keyCompetitors
    keyInvestors
    lastFundraiseAmount
    lastFundraiseDate
    lastFundraiseValuation
    pipelineCustomers
    runway
    salesDescription
    topCustomers
    totalCustomers
    totalFundsRaised
    twelveMonthRevenues
    company {
      ...CompanyFields
    }
    currentMarkets {
      label
      value
    }
    quests {
      uuid
      name
    }
  }
`;

export const FACT_FRAGMENT = gql`
  fragment FactSheetFields on StartupFacts {
    uuid
    updatedAt
    asset1
    asset2
    isDraft
    liability1
    liability2
    other
    version
    attachments {
      uuid
      fileName
      startupFactsUuid
      url(download: true)
    }
    updatedBy {
      uuid
      contact {
        uuid
        name
      }
    }
  }
`;

export const RM_CONFLICT_FRAGMENT = gql`
  fragment RmConflictFields on RainMakerConflict {
    uuid
  }
`;

export const GET_RM_CONFLICTS = gql`
  query rainMakerConflict($companyId: ID!) {
    rainMakerConflict(companyId: $companyId, all: true) {
      uuid
      createdAt
      investments
      board
      employment
      competitorInvestments
      competitorBoard
      competitorEmployment
      competitorInterest
      other
      user {
        uuid
        contact {
          uuid
          name
        }
      }
    }
  }
`;

export const UPDATE_STARTUP_PROFILE_MUTATION = gql`
  ${STARTUP_PROFILE_FIELDS}
  mutation updateStartupProfile($input: UpdateStartupProfileInput) {
    updateStartupProfile(input: $input) {
      ...StartupFields
    }
  }
`;

export const UPDATE_RM_PROFILE_MUTATION = gql`
  mutation updateRmProfile($input: UpdateBaseProfileInput, $withAvatar: Boolean = false) {
    updateRmProfile(input: $input) {
      uuid
      about
      avatar @include(if: $withAvatar)
    }
  }
`;

export const QUEST_UPLOAD_URL_QUERY = gql`
  query questUploadUrl($uuid: ID!, $sha256: String!) {
    questUploadUrl(uuid: $uuid, sha256: $sha256) {
      uuid
      url
      getUrl
    }
  }
`;

export const RAIN_MAKER_WEALTH_QUERY = gql`
  query rainMakerWealth($from: DateTime!, $until: DateTime!, $rmId: String) {
    capTableLog(from: $from, until: $until, rmId: $rmId) {
      uuid
      createdAt
      equity
      gig {
        uuid
        index
        type
      }
      quest {
        uuid
        status
      }
      startup {
        uuid
        company {
          uuid
          name
          logo
        }
      }
    }
  }
`;

export const UPDATE_GIG_PARTICIPANTS_MUTATION = gql`
  mutation updateGigParticipants($input: UpdateGigParticipantsInput!) {
    updateGigParticipants(input: $input) {
      uuid
      participants {
        uuid
        rainMaker {
          uuid
          name
        }
      }
      meetingDetails {
        uuid
        attendees {
          email
          name
        }
      }
      version
    }
  }
`;

export const UPDATE_TICKET_ASSIGNEES_MUTATION = gql`
  mutation updateTicketAssignees($input: UpdateTicketAssigneesInput!) {
    updateTicketAssignees(input: $input) {
      uuid
      participants {
        uuid
        rainMaker {
          uuid
          name
        }
      }
    }
  }
`;

export const SHARE_DEALROOM_FILE_MUTATION = gql`
  mutation shareDealroomFile($input: S3ShareFileOptions!) {
    shareDealroomFile(input: $input)
  }
`;

export const ARCHIVE_QUEST_MUTATION = gql`
  mutation archive($id: ID!, $reason: String!, $version: Int!) {
    archive(id: $id, reason: $reason, version: $version) {
      uuid
      status
      version
    }
  }
`;

export const CREATE_QUEST_MEETING_MUTATION = gql`
  ${QUEST_MEETING_FRAGMENT_SUMMARY}
  mutation upsertMeeting($input: QuestMeetingForm!) {
    upsertMeeting(input: $input) {
      ...QuestMeetingFields
    }
  }
`;

export const UPDATE_QUEST_MEETING_CONTENT_MUTATION = gql`
  mutation updateContent($input: QuestMeetingContentForm!, $transcript: Boolean!, $notes: Boolean!) {
    updateContent(input: $input) {
      id
      notes @include(if: $notes)
      transcript @include(if: $transcript)
      version
    }
  }
`;

export const getQuestUploadUrl = (getUploadUrl: LazyQueryExecFunction<QuestUploadUrlQuery, any>, questUuid: string) => {
  return async ({ sha256, file }: { sha256: string; file: File }) => {
    const res = await getUploadUrl({
      variables: {
        uuid: questUuid,
        sha256,
        name: file.name,
        contentType: file.type,
      },
    });
    if (!res.data) {
      throw new Error('Missing data in response');
    }
    return res.data.questUploadUrl;
  };
};

export const updateProfile = async <Variables, Mutation>({
  loggerContext,
  mutation,
  setStatus,
  variables,
}: {
  loggerContext?: LoggingContext;
  mutation: MutationTuple<Mutation, Variables>;
  setStatus?: (status: { status: AlertStatus; content?: React.ReactNode } | undefined) => void;
  variables: Variables;
}) => {
  const [updateProfileMutation, { error: submissionError }] = mutation;
  try {
    const res = await updateProfileMutation({
      variables,
    });
    const data = res.data as any;
    if (!data?.updateStartupProfile && !data?.updateRmProfile) {
      throw new Error('Failed to save changes');
    }
    if (setStatus) {
      setStatus({
        status: { status: 'success', title: 'Changes saved.' },
      });
      setTimeout(() => {
        setStatus(undefined);
      }, 3000);
    }
    return data;
  } catch (error) {
    setStatus?.({
      status: { status: 'error', title: 'Failed to save changes' },
    });
    if (loggerContext) {
      loggerContext.logError('failed to save profile', { submissionError, error, variables });
    }
    throw error;
  }
};

export const READ_EMAIL_QUERY = gql`
  query readEmail($id: ID!) {
    readEmail(id: $id) {
      index
      attachments {
        name
        contentType
        url
      }
    }
  }
`;

export const LOAD_ACTION_CENTER_ITEMS = gql`
  query getActionItems {
    actionItems {
      uuid
      createdAt
      description
      url
    }
  }
`;

export const RAIN_MAKER_PROFILE_QUERY = gql`
  query rainMakerProfile($profileId: String!) {
    rainMakerProfile(profileId: $profileId) {
      uuid
      about
      avatar
      city
      linkedin
      name
      country {
        label
        value
      }
    }
  }
`;

export const COMPANY_LIST_QUERY = gql`
  ${COMPANY_PROFILE_FIELDS}
  query companies($nameFilter: String, $cursor: String, $take: Int, $orderBy: [CompanySort!]) {
    companies(nameFilter: $nameFilter, cursor: $cursor, take: $take, orderBy: $orderBy) {
      ...CompanyFields
      startupProfile {
        uuid
      }
    }
  }
`;

export const COMPANY_DETAILS_QUERY = gql`
  ${COMPANY_PROFILE_FIELDS}
  query company($companyId: ID!) {
    company(companyId: $companyId) {
      ...CompanyFields
    }
  }
`;
