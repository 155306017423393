import { Cross2Icon, ExclamationTriangleIcon } from '@radix-ui/react-icons';
import * as Toast from '@radix-ui/react-toast';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { HStack } from '../components/HStack';
import { PrimaryButton, PrimaryButtonProps } from '../components/buttons/core/PrimaryButton';
import { SecondaryButton, SecondaryButtonProps } from '../components/buttons/core/SecondaryButton';
import { Body1 } from '../components/typography/Body1';
import '../radix.css';
import { createCtx } from './common';

export type ActionObject = (
  | {
      type: 'PrimaryButton';
      props?: PrimaryButtonProps;
    }
  | {
      type: 'SecondaryButton';
      props?: SecondaryButtonProps;
    }
) & { url?: string };

export type OpenOptions = {
  action?: React.ReactNode | ActionObject;
  description?: React.ReactNode;
  level?: 'error';
  title: React.ReactNode;
};

export type ToastId = number;
type OpenToast = (options: OpenOptions) => ToastId;
type CloseToast = (id: ToastId) => void;
type ClearToasts = () => void;

const [useCtx, Provider] = createCtx<[OpenToast, CloseToast, ClearToasts]>();

export const ToastProvider = ({ children }: { children?: React.ReactNode }) => {
  const [toasts, setToasts] = React.useState<Map<ToastId, React.ReactNode>>(new Map());
  const navigate = useNavigate();
  const open = React.useCallback(
    (options: OpenOptions) => {
      const action = options.action;
      let actionElement: React.ReactNode;
      if (isActionObject(action)) {
        const url = action.url;
        const props = {
          size: 'sm' as const,
          onClick: typeof url === 'string' ? () => navigate(url) : undefined,
          ...action.props,
          className: twMerge('self-end', action.props?.className),
        };
        switch (action.type) {
          case 'PrimaryButton':
            actionElement = <PrimaryButton {...props} />;
            break;
          case 'SecondaryButton':
            actionElement = <SecondaryButton {...props} />;
            break;
        }
      } else {
        actionElement = action;
      }
      let title = typeof options.title === 'string' ? <Body1 children={options.title} /> : options.title;
      title =
        options.level === 'error' ? (
          <HStack space="2" className="items-center">
            <ExclamationTriangleIcon />
            {title}
          </HStack>
        ) : (
          title
        );
      const id = Date.now();
      setToasts((before) => {
        const after = new Map(before);
        after.set(
          id,
          <ToastCard
            key={id}
            title={title}
            action={actionElement}
            description={options.description}
            onClose={() => {
              // set timeout to let exit animation finish
              setTimeout(() => {
                setToasts((before) => {
                  const after = new Map(before);
                  after.delete(id);
                  return after;
                });
              }, 1000);
            }}
          />,
        );
        return after;
      });
      return id;
    },
    [navigate],
  );
  const close = React.useCallback((id: ToastId) => {
    // TODO: let exit animation finish
    setToasts((before) => {
      const after = new Map(before);
      after.delete(id);
      return after;
    });
  }, []);
  const clear = React.useCallback(() => {
    // TODO: let exit animation finish
    setToasts(new Map());
  }, []);
  const renderedToasts = React.useMemo(() => {
    return Array.from(toasts)
      .sort(([key1], [key2]) => key2 - key1)
      .map(([_, node]) => node);
  }, [toasts]);
  const location = useLocation();
  React.useEffect(() => {
    if ((location.state as null | { toast?: OpenOptions })?.toast) {
      open(location.state.toast);
    }
  }, [location.state, open]);
  return (
    <Provider value={[open, close, clear]}>
      {children}
      <Toast.Provider swipeDirection="right">
        {renderedToasts}
        <Toast.Viewport className="ToastViewport" />
      </Toast.Provider>
    </Provider>
  );
};
function isActionObject(input: any): input is ActionObject {
  return ['PrimaryButton', 'SecondaryButton'].includes(input?.type);
}

type ToastCardProps = {
  action?: React.ReactNode;
  description?: React.ReactNode;
  onClose: () => void;
  title: React.ReactNode;
};
const ToastCard = (props: ToastCardProps) => {
  const { action, description, onClose, title } = props;
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  const onOpenChange = React.useCallback(
    (v: boolean) => {
      if (!v) {
        onClose();
      }
      setIsOpen(v);
    },
    [onClose],
  );
  return (
    <Toast.Root className="ToastRoot" open={isOpen} duration={10000} onOpenChange={onOpenChange}>
      <Toast.Title className="ToastTitle">{title}</Toast.Title>
      <Toast.Description className="ToastDescription">{description}</Toast.Description>
      {action && (
        <Toast.Action className="ToastAction" asChild altText="Reload">
          {action}
        </Toast.Action>
      )}
      <Toast.Close className="self-start justify-self-end ToastAction">
        <Cross2Icon />
      </Toast.Close>
    </Toast.Root>
  );
};
/*
// How to use:
1. With hook
const [toast, close, clear] = useToast();
const id = toast({
    title: <Body1>Outdated info</Body1>,
    description: <Body2>This page has been updated. Reload to view the latest version.</Body2>,
    action: (
    <PrimaryButton size="sm" variant="contrast" className="self-end" onClick={() => location.reload()}>
        Reload
    </PrimaryButton>
    ),
    level: 'error',
});
close(id);
clear();

2. With navigate (only serialisable button props are supported)
const navigate = useNavigate();
navigate(to, {state: {toast: {
  title: '',
}}});
*/
export const useToast = useCtx;
