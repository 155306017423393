import React from 'react';
import { FieldValues, FormState } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { Caption } from '../../typography/Caption';
import { FormControlWrapper } from '../FormControlWrapper';
import { HelperText } from '../HelperText';
import { NamedTextarea, NamedTextareaProps } from './NamedTextarea';

export type FormTextAreaProps<TFieldValues extends FieldValues = FieldValues> = NamedTextareaProps & {
  charLimit?: number;
  formState: FormState<TFieldValues>;
  isOptional?: boolean;
  maxHeight?: number;
  name: string;
};

export const FormTextArea = React.memo(
  React.forwardRef<HTMLTextAreaElement, FormTextAreaProps>((props, ref) => {
    const { charLimit, formState, onChange, rows, maxHeight, ...namedTextAreaProps } = props;
    const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);
    const initialHeight = React.useRef<number>(0);
    const [length, setLength] = React.useState<number>(
      (props.defaultValue as string)?.length ?? (props.value as string)?.length ?? 0,
    );
    const onChangeText = React.useCallback(
      (e: React.FormEvent<HTMLTextAreaElement>) => {
        setLength(e.currentTarget.value.length);
        if (onChange) {
          onChange(e);
        }
      },
      [onChange],
    );

    React.useEffect(() => {
      if (textareaRef.current) {
        // We need to reset the height momentarily to get the correct scrollHeight for the textarea
        textareaRef.current.style.height = '0px';
        const scrollHeight = textareaRef.current.scrollHeight;
        const max = maxHeight ?? 280;
        if (scrollHeight >= max) {
          textareaRef.current.style.height = `${max}px`;
        } else {
          if (scrollHeight > initialHeight.current) {
            // We then set the height directly, outside of the render loop
            // Trying to set this with state or a ref will product an incorrect value.
            textareaRef.current.style.height = scrollHeight + 'px';
          } else {
            textareaRef.current.style.height = `${initialHeight.current}px`;
          }
        }
      }
    }, [maxHeight, length]);

    const errorMessage =
      formState.isDirty && typeof formState.errors[props.name]?.message
        ? (formState.errors[props.name]?.message as string)
        : undefined;
    return (
      <FormControlWrapper
        helperText={<TextAreaHelperText length={length} charLimit={charLimit ?? 0} color={props.color} />}
        error={errorMessage}
      >
        <NamedTextarea
          {...namedTextAreaProps}
          error={errorMessage}
          ref={(r) => {
            textareaRef.current = r;
            if (r && !initialHeight.current) {
              const lineHeight = parseInt(getComputedStyle(r).lineHeight, 10);
              initialHeight.current = lineHeight * (r.rows + 1);
            }
            if (typeof ref === 'function') {
              ref(r);
            } else if (ref) {
              ref.current = r;
            }
          }}
          rows={rows ?? 5}
          onChange={onChangeText}
        />
      </FormControlWrapper>
    );
  }),
);

function TextAreaHelperText(props: { length: number; charLimit: number; color?: string }) {
  if (props.charLimit && props.length !== undefined) {
    const isExceeded = props.length > props.charLimit;
    const fontColor = isExceeded ? 'text-danger' : props.color;
    return (
      <HelperText>
        <Caption className={twMerge(isExceeded ? 'font-bold' : undefined, fontColor)}>
          {`${props.length}/${props.charLimit}`}
        </Caption>
      </HelperText>
    );
  }
  return null;
}
