import { useRollbar } from '@rollbar/react';
import React from 'react';
import { GetSessionInformationQuery } from '../gql/graphql';
import { createCtx } from './common';

export type LoggingContext = {
  logDebug: (message: string, ctx?: Record<string, any>) => void;
  logInfo: (message: string, ctx?: Record<string, any>) => void;
  logWarn: (message: string, ctx?: Record<string, any>) => void;
  logError: (message: string, ctx?: Record<string, any>) => void;
  setSessionContext: (ctx: Exclude<GetSessionInformationQuery['getSessionInformation'], undefined | null>) => void;
};

const [useCtx, LoggingContextProvider] = createCtx<LoggingContext>();

export const LoggingProvider = ({ children }: { children: React.ReactNode }) => {
  const rollbar = useRollbar();
  const sessionContext = React.useRef<Exclude<GetSessionInformationQuery['getSessionInformation'], undefined | null>>();

  const value = React.useMemo(() => {
    function logDebug(message: string, ctx?: Record<string, any>) {
      log('debug', message, ctx);
    }

    function logInfo(message: string, ctx?: Record<string, any>) {
      log('info', message, ctx);
    }

    function logWarn(message: string, ctx?: Record<string, any>) {
      log('warn', message, ctx);
    }

    function logError(message: string, ctx?: Record<string, any>) {
      log('error', message, ctx);
    }

    function log(method: 'debug' | 'info' | 'warn' | 'error', message: string, ctx?: Record<string, any>) {
      // const context = ctx ? { ...ctx, session } : { session };
      // const payload = rollbar.options.payload || {};
      // payload.person = { ...session, id: session.email || 'anoymous' };
      rollbar[method](message, { ...ctx, session: sessionContext.current });
    }

    function setSessionContext(ctx: Exclude<GetSessionInformationQuery['getSessionInformation'], undefined | null>) {
      sessionContext.current = ctx;
    }
    return { logDebug, logInfo, logWarn, logError, setSessionContext };
  }, [rollbar]);

  return <LoggingContextProvider value={value}>{children}</LoggingContextProvider>;
};

export const useLogger = useCtx;
