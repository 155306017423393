import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ANON_PATH } from '../../constants';
import { useSession } from '../../contexts/SessionProvider';
import { PresenceWrapper } from '../page/PresenceWrapper';

export const AuthenticatedRoute = ({
  children,
  hasExit,
  fallback,
}: {
  children: React.ReactNode;
  hasExit?: boolean;
  fallback?: React.ReactElement;
}) => {
  if (hasExit) {
    return (
      <React.Suspense fallback={fallback}>
        <AuthenticatedRouteWrapper children={children} />
      </React.Suspense>
    );
  }
  return (
    <PresenceWrapper fallback={fallback}>
      <AuthenticatedRouteWrapper children={children} />
    </PresenceWrapper>
  );
};

const AuthenticatedRouteWrapper = ({ children }: { children: React.ReactNode }) => {
  const { session } = useSession();
  const location = useLocation();
  if (location.pathname === '/logout') {
    return null;
  }
  /* checkRedirects (goes to /terms or /hold) should not be used here because this is also used for /terms and /welcome */
  if (!session) {
    if (new URLSearchParams(location.search).has('token')) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return <Navigate to={ANON_PATH} state={{ from: location }} replace />;
  }
  return children;
};
