import React from 'react';
import { createCtx } from './common';

const [useCtx, Provider] = createCtx<boolean>(false);

export const DarkModeProvider = ({ children, value }: { children: React.ReactNode; value?: boolean }) => {
  return <Provider value={value ?? true} children={children} />;
};

export const useDarkModeFlag = useCtx;
