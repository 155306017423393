import { ExternalLinkIcon } from '@radix-ui/react-icons';
import { twMerge } from 'tailwind-merge';
import { useDarkModeFlag } from '../../contexts/DarkModeProvider';
type LinkProps = React.HTMLProps<HTMLAnchorElement> & { variant?: 'headerCard' | 'contrast' };

export const Link = (props: LinkProps) => {
  const { children, variant, className, ...rest } = props;
  const isDark = useDarkModeFlag();
  let color;
  switch (variant) {
    case 'headerCard': {
      color = 'text-neutral-600';
      break;
    }
    case 'contrast': {
      color = isDark ? 'text-white' : 'text-black';
      break;
    }
    default: {
      color = isDark ? 'text-primary-400 hover:text-rain' : 'text-rain';
      break;
    }
  }
  return (
    <a className={twMerge(color, 'cursor-pointer text-sm', className)} {...rest}>
      {children}
      {rest.target === '_blank' ? <ExternalLinkIcon className="inline-flex ml-1" /> : null}
    </a>
  );
};
