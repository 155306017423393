import React from 'react';
import { twMerge } from 'tailwind-merge';

export type PressableProps = Omit<React.HTMLProps<HTMLDivElement>, 'disabled'>;
export const Pressable = React.forwardRef<HTMLDivElement, PressableProps>(
  ({ children, className, onClick, onDoubleClick, ...rest }, ref) => {
    const { 'aria-disabled': ariaDisabled } = rest;
    const _onClick = React.useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        if (ariaDisabled) return;
        onClick?.(e);
      },
      [onClick, ariaDisabled],
    );
    const _onDoubleClick = React.useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        if (ariaDisabled) return;
        onDoubleClick?.(e);
      },
      [ariaDisabled, onDoubleClick],
    );
    return (
      <div
        aria-disabled={!onClick}
        onClick={_onClick}
        onDoubleClick={_onDoubleClick}
        className={twMerge(
          // on iOS, paddings are added to div's with type "button" and would may make content disappears
          'appearance-none',
          'hover:cursor-pointer aria-disabled:hover:cursor-not-allowed',
          className,
        )}
        ref={ref}
        {...rest}
      >
        {children}
      </div>
    );
  },
);
