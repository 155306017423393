import { Navigate } from 'react-router-dom';
import { SessionContextInterface } from '../../contexts/SessionProvider';

export const checkRedirects = (context: Exclude<SessionContextInterface['session'], null>) => {
  if (!context?.termsAgreed) {
    return <Navigate to="/terms" replace />;
  }
  if (!context.roles?.find((role) => role === 'access')) {
    return <Navigate to="/hold" replace />;
  }
  return null;
};
