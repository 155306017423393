import { useQuery } from '@apollo/client';
import { ChevronRightIcon, TriangleDownIcon } from '@radix-ui/react-icons';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { DarkModeProvider, useDarkModeFlag } from '../../contexts/DarkModeProvider';
import { useSession } from '../../contexts/SessionProvider';
import { useStartupProfileQuery } from '../../contexts/StartupProfileQueryProvider';
import { Quest, QuestListQuery, QuestListQueryVariables, QuestStatus } from '../../gql/graphql';
import { useIsMobileView } from '../../hooks/useIsMobile';
import { useReplaceParams } from '../../hooks/useReplaceParams';
import '../../radix-navigation-menu.css';
import { QUEST_LIST_QUERY } from '../../utils/graphql';
import { capitalise, getQuestBaseUrl, hasRole } from '../../utils/helper';
import { DocusignLabel } from '../DocusignForm';
import { HStack } from '../HStack';
import { Loading } from '../Loading';
import { VStack } from '../VStack';
import { Body1 } from '../typography/Body1';
import { Body2 } from '../typography/Body2';

export const Breadcrumb = ({ breadcrumbs }: { breadcrumbs: React.ReactNode[] }) => {
  const isMobileView = useIsMobileView();
  return (
    <NavigationMenu.Root className={twMerge('NavigationMenuRoot', isMobileView ? 'my-2' : undefined)}>
      <NavigationMenu.List className="NavigationMenuList items-baseline flex-wrap gap-x-2">
        {breadcrumbs.flatMap((x, i) => (i === 0 ? x : [<ToIcon key={`toIcon_${i}`} />, x]))}
        <NavigationMenu.Indicator className="NavigationMenuIndicator">
          <div className="Arrow" />
        </NavigationMenu.Indicator>
      </NavigationMenu.List>
      {isMobileView ? (
        <div className="ViewportPosition">
          <NavigationMenu.Viewport className="NavigationMenuViewport" />
        </div>
      ) : null}
    </NavigationMenu.Root>
  );
};

export const HomeBreadcrumbItem = () => {
  const { session } = useSession();
  const navigate = useNavigate();
  return (
    <BreadcrumbItem title="Home">
      <VStack className="w-screen md:w-[200px]">
        {hasRole(session?.roles, 'researcher') ? (
          <ListItem onClick={() => navigate('/companies')} title="All companies" />
        ) : null}
        <ListItem onClick={() => navigate('/deals')} title="Live deals" />
        <ListItem onClick={() => navigate('/blotter')} title="Blotter" />
        <ListItem onClick={() => navigate('/rm/rewards')} title="Rewards" />
      </VStack>
    </BreadcrumbItem>
  );
};

export const HomeNavBreadcrumbItem = () => {
  const location = useLocation();
  let title: string;
  switch (location.pathname) {
    case '/companies':
      title = 'All Companies';
      break;
    case '/deals':
      title = 'Live Deals';
      break;
    case '/blotter':
      title = 'Blotter';
      break;
    case '/rm/rewards':
      title = 'Rewards';
      break;
    default:
      title = '';
  }
  return <BreadcrumbItem title={title} />;
};

// requires path to have param :startupId
export const DealsBreadcrumbItem = ({
  quests,
  title = 'Deals',
}: {
  quests?: Pick<Quest, 'uuid' | 'name'>[] | null;
  title?: string;
}) => {
  const { startupId } = useParams();
  const navigate = useNavigate();
  const { data, loading } = useQuery<QuestListQuery, QuestListQueryVariables>(QUEST_LIST_QUERY, {
    variables: {
      startupId: startupId!,
    },
    skip: Boolean(quests),
  });
  if (!quests) {
    quests = data?.quests.data;
  }
  if (!quests) {
    return <BreadcrumbItem title={title} children={loading ? <Loading /> : null} />;
  }
  return (
    <BreadcrumbItem title={title}>
      <VStack className="w-screen md:w-[200px]">
        {quests.map((quest) => (
          <ListItem
            key={quest.uuid}
            title={capitalise(quest.name)}
            onClick={() => navigate(getQuestBaseUrl(startupId!, quest.uuid))}
          />
        ))}
      </VStack>
    </BreadcrumbItem>
  );
};

export const DealDetailsBreadcrumbItem = ({ title }: { title: string }) => {
  const replaceParams = useReplaceParams();
  const navigate = useNavigate();
  const switchTab = (questTabId: string) => {
    const newPath = replaceParams({ questTabId });
    newPath && navigate(newPath);
  };
  return (
    <BreadcrumbItem title={title}>
      <VStack className="w-screen md:w-[200px]">
        <ListItem onClick={() => switchTab('steps')} title="Steps" />
        <ListItem onClick={() => switchTab('docs')} title="Documents" />
        <ListItem onClick={() => switchTab('docusign')} title={<DocusignLabel />} />
        <ListItem onClick={() => switchTab('notes')} title="Notes" />
        <ListItem onClick={() => switchTab('activities')} title="Activities" />
      </VStack>
    </BreadcrumbItem>
  );
};

export const CompanyBreadcrumbItem = ({ title }: { title: string }) => {
  return <BreadcrumbItem title={title} />;
};

export const StartupBreadcrumbItem = ({ title, fromDeal }: { title: string; fromDeal?: string }) => {
  const { session } = useSession();
  const isResearcher = hasRole(session.roles, 'researcher');
  const canViewConflict = hasRole(session.roles, 'view_conflicts');
  const replaceParams = useReplaceParams('/clients/:startupId/:startupTabId');
  const navigate = useNavigate();
  const switchTab = (tab: string, query?: Record<string, string>) => {
    const newPath = replaceParams({ startupTabId: tab });
    newPath &&
      navigate(
        `${newPath}${query ? `?${new URLSearchParams(query)}` : ''}`,
        fromDeal ? { state: { fromDeal } } : undefined,
      );
  };
  const [{ data }] = useStartupProfileQuery();
  return (
    <BreadcrumbItem title={title}>
      <VStack className="w-screen md:w-[200px]">
        <ListItem
          onClick={() => switchTab('deals', { statuses: [QuestStatus.Live, QuestStatus.Watch].join(',') })}
          title="Deals"
        />
        <ListItem onClick={() => switchTab('factsheet')} title="Fact Sheet" />
        <ListItem onClick={() => switchTab('profile')} title="Profile" />
        <ListItem onClick={() => switchTab('docs')} title="Documents" />
        {!data?.rainMakerConflict?.length && !isResearcher ? (
          <ListItem onClick={() => switchTab('rm-conflicts')} title="Declare Conflict" />
        ) : null}
        {canViewConflict ? (
          <>
            <ListItem onClick={() => switchTab('view-rm-conflicts')} title="View DM Conflicts" />
            <ListItem onClick={() => switchTab('su-conflicts')} title="Client Conflict" />
          </>
        ) : null}
        <ListItem onClick={() => switchTab('docusign')} title={<DocusignLabel />} />
        <ListItem onClick={() => switchTab('activities')} title="Activities" />
      </VStack>
    </BreadcrumbItem>
  );
};

// onClick is used only if children do not exist and title is string
export const BreadcrumbItem = ({
  children,
  onClick,
  title,
}: {
  children?: React.ReactNode;
  onClick?: () => void;
  title: React.ReactNode;
}) => {
  const isDark = useDarkModeFlag();
  const hasDropdown = Boolean(children);
  const isMobile = useIsMobileView();
  const trigger = React.useMemo(() => {
    const triggerContent =
      typeof title !== 'string' ? (
        title
      ) : (
        <HStack className="items-center">
          <Body1>{title}</Body1>
          {hasDropdown ? (
            <TriangleDownIcon
              className={twMerge(
                'transition-transform group-data-[state=open]:-rotate-180',
                isDark ? 'text-neutral-500' : undefined,
              )}
              aria-hidden
            />
          ) : null}
        </HStack>
      );
    return (
      <NavigationMenu.Trigger
        className="NavigationMenuTrigger group cursor-default"
        onClick={(e) => {
          if (!isMobile) {
            e.preventDefault();
          }
        }}
      >
        {triggerContent}
      </NavigationMenu.Trigger>
    );
  }, [hasDropdown, isDark, title, isMobile]);

  const itemContent = React.useMemo(() => {
    if (hasDropdown) {
      return (
        <>
          {trigger}
          <NavigationMenu.Content className="NavigationMenuContent bg-white shadow rounded py-2">
            <DarkModeProvider value={false}>{children}</DarkModeProvider>
          </NavigationMenu.Content>
        </>
      );
    }
    const content = typeof title !== 'string' ? title : <Body1 onClick={onClick}>{title}</Body1>;
    return (
      <NavigationMenu.Item asChild>
        <NavigationMenu.Link>{content}</NavigationMenu.Link>
      </NavigationMenu.Item>
    );
  }, [children, hasDropdown, onClick, title, trigger]);
  return <NavigationMenu.Item>{itemContent}</NavigationMenu.Item>;
};

const ToIcon = () => {
  const isDark = useDarkModeFlag();
  return <ChevronRightIcon className={twMerge('self-center', isDark ? 'text-neutral-500' : undefined)} />;
};

const ListItem = ({
  className,
  onClick,
  title,
}: {
  className?: string;
  onClick?: () => void;
  title: React.ReactNode;
}) => {
  return (
    <NavigationMenu.Link className="cursor-pointer" onClick={onClick} asChild>
      <div className="hover:bg-primary-100 transition-colors">
        <Body2 className={twMerge('ListItemLink text-rain', className)}>{title}</Body2>
      </div>
    </NavigationMenu.Link>
  );
};
