import { motion } from 'framer-motion';
import { useDarkModeFlag } from '../../contexts/DarkModeProvider';
import theme, { Sizes } from '../../theme';
import { Display } from '../typography/Display';

export const AnimatedLogo = (props: { delay?: number; size?: Sizes }) => {
  const { delay, size } = props;
  const isDark = useDarkModeFlag();
  const duration = 0.1;
  const transitions: { duration: number; delay: number }[] = [];
  for (let i = 0; i < 3; i++) {
    const last = i === 0 ? delay ?? 0 : transitions.at(-1)!.delay;
    transitions.push({
      duration,
      delay: last + duration,
    });
  }
  return (
    <Display size={size ?? 'xl'}>
      <motion.span>R</motion.span>
      <motion.span
        initial={{ width: 0, x: '-4px', opacity: 0.5, visibility: 'hidden', position: 'absolute' }}
        animate={{ width: 'auto', x: 0, opacity: 1, visibility: 'visible', position: 'static' }}
        transition={transitions[0]}
        style={{ display: 'inline-block', letterSpacing: '-2px' }}
      >
        A
      </motion.span>
      <motion.span
        initial={{ width: 0, x: '-4px', opacity: 0.5, visibility: 'hidden', position: 'absolute' }}
        animate={{ width: 'auto', x: 0, opacity: 1, visibility: 'visible', position: 'static' }}
        transition={transitions[1]}
        style={{ display: 'inline-block', letterSpacing: '-4px' }}
      >
        I
      </motion.span>
      <motion.span
        initial={{ width: 0, x: '-4px', opacity: 0.5, visibility: 'hidden', position: 'absolute' }}
        animate={{ width: 'auto', x: 0, opacity: 1, visibility: 'visible', position: 'static' }}
        transition={transitions[2]}
        style={{ display: 'inline-block', letterSpacing: '-1px' }}
      >
        N
      </motion.span>
      <motion.span
        style={{
          color: isDark ? theme.colors.white : theme.colors.rain,
          display: 'inline-block',
          y: '-3px',
          scaleY: -1,
          position: 'absolute',
        }}
      >
        .
      </motion.span>
    </Display>
  );
};
