import { twMerge } from 'tailwind-merge';
import { useDarkModeFlag } from '../../contexts/DarkModeProvider';
import { twFonts } from '../../theme';

export const Caption = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const { children, className, ...rest } = props;
  const isDark = useDarkModeFlag();
  const color = isDark ? 'text-white' : 'text-black';
  return (
    <div className={twMerge(twFonts.Caption, color, className)} {...rest}>
      {children}
    </div>
  );
};

export const CaptionLight = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const { className, ...rest } = props;
  const isDark = useDarkModeFlag();
  return (
    <Caption className={twMerge('uppercase', isDark ? 'text-neutral-500' : 'text-neutral-700', className)} {...rest} />
  );
};
