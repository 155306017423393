import { Cross1Icon } from '@radix-ui/react-icons';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useBreakpointValue } from '../../hooks/useBreakpointValue';
import { Pressable } from '../Pressable';
import { VStack } from '../VStack';

export function Drawer(
  props: Readonly<{
    backgroundColor?: string;
    children: React.ReactNode;
    isOpen: boolean;
    left?: boolean;
    onClose: () => void;
  }>,
) {
  const { backgroundColor, isOpen, onClose, left, children } = props;
  return (
    <AnimatePresence initial={false}>
      {isOpen ? (
        // z-100: z-index should be larger than anything else
        <div className="w-full h-full absolute overflow-hidden z-50">
          <DrawerOverlay key="drawer-overlay" onClose={onClose} />
          <DrawerBody
            key="drawer-body"
            left={left}
            backgroundColor={backgroundColor}
            children={children}
            onClose={onClose}
          />
        </div>
      ) : null}
    </AnimatePresence>
  );
}

function CloseDrawerButton(props: { onClose: () => void }) {
  return (
    <Pressable className="p-2 h-8 w-8 self-end" onClick={props.onClose}>
      <Cross1Icon className="text-white w-4 h-4" />
    </Pressable>
  );
}

const slideFromLeftVariants = {
  visible: { x: 0 },
  hidden: { x: '-100%' },
};
const slideFromRightVariants = {
  visible: { x: 0 },
  hidden: { x: '100%' },
};
function DrawerBody(
  props: Readonly<{
    backgroundColor?: string;
    children: React.ReactNode;
    left?: boolean;
    onClose: () => void;
  }>,
) {
  const { backgroundColor, children, left, onClose } = props;
  // TODO: fix tabsHeight
  // const tabsHeight = useHeaderHeight().tabs;
  const style = {
    backgroundColor,
    overflowY: 'auto',
    position: 'absolute',
  };
  const { moreStyle } = useBreakpointValue({
    base: {
      moreStyle: {
        // bottom: tabsHeight,
        // width: '100svw',
        [left ? 'left' : 'right']: 0,
        width: 'clamp(300px, 60%, 100svw)',
        maxWidth: '100svw',
        height: '100%',
      } as const,
    },
    md: {
      moreStyle: {
        [left ? 'left' : 'right']: 0,
        minWidth: '400px',
        maxWidth: '500px',
        width: '30%',
        height: '100%',
      } as const,
    },
  });
  return (
    <motion.div
      style={{ ...style, ...moreStyle }}
      initial="hidden"
      animate="visible"
      exit="hidden"
      transition={{ bounce: 0 }}
      variants={left ? slideFromLeftVariants : slideFromRightVariants}
    >
      <VStack className="p-3 md:p-6 pb-9 md:pb-6 gap-y-3 md:gap-y-6">
        <CloseDrawerButton onClose={onClose} />
        {children}
      </VStack>
    </motion.div>
  );
}

function DrawerOverlay(props: Readonly<{ onClose: () => void }>) {
  const { onClose } = props;
  const variants = {
    visible: {
      background: 'rgba(0,0,0,0.4)',
      backdropFilter: 'blur(5px)',
      WebkitBackdropFilter: 'blur(5px)',
    },
    hidden: {
      background: 'rgba(0,0,0,0)',
      backdropFilter: 'blur(0px)',
      WebkitBackdropFilter: 'blur(0px)',
    },
  };
  const style = {
    position: 'absolute',
    height: '100%',
    width: '100%',
  } as const;
  return (
    <motion.div style={style} variants={variants} initial="hidden" animate="visible" exit="hidden" onClick={onClose} />
  );
}
