import { NetworkStatus, useQuery } from '@apollo/client';
import { Cross1Icon } from '@radix-ui/react-icons';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useNavigate } from 'react-router-dom';
import { HStack } from '../components/HStack';
import { Pressable } from '../components/Pressable';
import { Link } from '../components/typography/Link';
import { GetActionItemsQuery, GetActionItemsQueryVariables } from '../gql/graphql';
import ActionCentre from '../pages/ActionCentre';
import { LOAD_ACTION_CENTER_ITEMS } from '../utils/graphql';
import { useSession } from './SessionProvider';
import { createCtx } from './common';

const [useCtx, ActionCenterContextProvider] = createCtx<{
  items: GetActionItemsQuery['actionItems'];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>();

export const ActionCenterProvider = ({ children, skip }: { children: React.ReactNode; skip: boolean }) => {
  const { session } = useSession();
  const { data, refetch, networkStatus } = useQuery<GetActionItemsQuery, GetActionItemsQueryVariables>(
    LOAD_ACTION_CENTER_ITEMS,
    {
      skip: skip || !session?.email,
      fetchPolicy: 'cache-and-network',
    },
  );
  const [open, setOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (data?.actionItems?.length && Store.getCounter() === 0 && !open) {
      Store.addNotification({
        // title: 'You have outstanding tasks!',
        // message: '',
        type: 'warning',
        insert: 'top',
        container: 'top-full',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        content: (
          <div className="animate__animated animate__fadeIn rnc__notification-item rnc__notification-item--warning w-full">
            <div className="rnc__notification-content">
              <HStack className="justify-between items-center">
                <div className="rnc__notification-title">
                  {`You have ${data.actionItems.length} outstanding task${data.actionItems.length === 1 ? '' : 's'}. `}
                  <Link
                    className="mt-2 text-white underline inline-block text-base md:text-base"
                    onClick={() => setOpen(true)}
                  >
                    View
                  </Link>
                </div>
                <Pressable onClick={Store.removeAllNotifications}>
                  <Cross1Icon className="text-white" />
                </Pressable>
              </HStack>
            </div>
          </div>
        ),
        dismiss: {
          click: false,
          duration: 0,
          touch: false,
        },
      });
    }
  }, [data?.actionItems, navigate, open]);

  React.useEffect(() => {
    if (open) {
      Store.removeAllNotifications();
    }
  }, [open]);

  return (
    <ActionCenterContextProvider
      value={{
        items: data?.actionItems ?? [],
        setOpen: () => {
          setOpen(true);
          if (networkStatus === NetworkStatus.ready) {
            refetch();
          }
        },
      }}
    >
      <AnimatePresence mode="wait">{open ? <ActionCentre onClose={() => setOpen(false)} /> : null}</AnimatePresence>
      {children}
    </ActionCenterContextProvider>
  );
};

export const useActionCentre = useCtx;
