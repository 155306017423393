import React from 'react';
import { twMerge } from 'tailwind-merge';
import CheckedIcon from '../../../assets/icons/icon_checked.svg?react';
import UncheckedIcon from '../../../assets/icons/icon_unchecked.svg?react';
import theme from '../../../theme';
import { HStack } from '../../HStack';
import { Pressable } from '../../Pressable';

export type RadioProps = {
  children: React.ReactNode;
  label?: React.ReactNode;
  iconPlacement?: 'left' | 'right';
  isDisabled?: boolean;
  isSelected: boolean;
  onSelect?: () => void;
  onToggle?: (selected: boolean) => void;
};
export function Radio(props: RadioProps) {
  const { iconPlacement = 'right', isSelected, isDisabled, onSelect, onToggle } = props;
  const _onClick = React.useCallback(() => {
    if (onToggle) {
      onToggle(!isSelected);
    }
    if (onSelect && !isSelected && !isDisabled) {
      onSelect();
    }
  }, [onSelect, onToggle, isSelected, isDisabled]);

  const radioIcon = <RadioIcon label={props.label} selected={props.isSelected} />;
  return (
    <Pressable
      onClick={_onClick}
      aria-selected={isSelected}
      className={twMerge('bg-neutral-200 hover:bg-primary-100 aria-selected:bg-primary-200 pl-2 pr-3 py-2 transition')}
    >
      <HStack space="3" className="items-center select-none">
        {iconPlacement === 'left' ? radioIcon : null}
        {props.children}
        {iconPlacement === 'right' ? radioIcon : null}
      </HStack>
    </Pressable>
  );
}

export function RadioIcon({ label, selected }: { label?: React.ReactNode; selected: boolean }) {
  const box = selected ? <CheckedIcon color={styles.color} /> : <UncheckedIcon color={styles.color} />;
  if (label) {
    return (
      <HStack className="items-center" space="2">
        {label}
        {box}
      </HStack>
    );
  }
  return box;
}

const styles = {
  color: theme.colors.rain,
};
