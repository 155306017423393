import Rollbar from 'rollbar';

export const config: Rollbar.Configuration = {
  accessToken: '97b6097f1674463e8ab2858b1d43bbc6',
  environment: import.meta.env.VITE_ROLLBAR_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: import.meta.env.MODE !== 'development',
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
};

export const RollbarInstance = new Rollbar(config);
