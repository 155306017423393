import { ApolloClient } from '@apollo/client';
import React from 'react';
import { FieldValues, FormState } from 'react-hook-form';
import { Option, OptionValue } from '../../components/form/input/Select';
import { StartupProfileQuery } from '../../gql/graphql';
import { monthFormatter } from '../helper';

export type StartupProfile = Exclude<StartupProfileQuery['startupProfile'], null | undefined>;

export const emptyAsNull = (v: string | undefined | null, prefix?: string) =>
  v?.length ? `${prefix ?? ''}${v}` : null;
export const emptyAsUndefined = (v: string | undefined | null, prefix?: string) =>
  v?.length ? `${prefix ?? ''}${v}` : undefined;
export function makeOption<T extends string | null | undefined>(
  v: T,
): T extends string ? Option<T> : T extends null ? null : undefined;
export function makeOption<T extends OptionValue | null | undefined>(
  v: T,
  labelFunction: (v: NonNullable<T>) => string,
): T extends string ? Option<T> : T extends null ? null : undefined;
export function makeOption<T, V extends OptionValue>(
  v: T,
  labelFunction: (v: NonNullable<T>) => string,
  valueFunction: (v: NonNullable<T>) => V,
): T extends null ? null : T extends undefined ? undefined : Option<V>;
export function makeOption(v: any, labelFunction?: (v: any) => string, valueFunction?: (v: any) => any): Option<any> {
  if (v == null) {
    return v;
  }
  return {
    label: labelFunction ? labelFunction(v) : v,
    value: valueFunction ? valueFunction(v) : v,
  };
}
export const makeOptionFromEntry = <T extends string>(v: [string, T]) =>
  makeOption(
    v,
    (x) => x[0],
    (x) => x[1],
  );

export type UploadMediaResponse = {
  status: number;
  data: { uuid: string; url: string; __typename?: string };
  apolloClient: ApolloClient<any>;
};
export type UploadMediaUrlResponse = {
  data?: { uuid: string; url: string; __typename?: string };
  apolloClient: ApolloClient<any>;
};
export type UploadMediaMetadata = {
  event: React.ChangeEvent<HTMLInputElement>;
  fileReader: FileReader;
  file: File;
};

export const handleFileChange =
  ({
    getFetchOptions,
    getUploadMediaUrl,
    onCleanup,
    onError,
    onLoadStart,
    onUploadFail,
    onUploadSuccess,
  }: {
    getFetchOptions: (metadata: UploadMediaMetadata) => RequestInit;
    getUploadMediaUrl: (metadata: UploadMediaMetadata) => Promise<UploadMediaUrlResponse>;
    onCleanup: (metadata: Omit<UploadMediaMetadata, 'file'>) => void;
    onError: (err: any, metadata: Omit<UploadMediaMetadata, 'file'>) => void;
    onLoadStart: (metadata: Omit<UploadMediaMetadata, 'file'>) => void;
    onUploadFail: (metadata: UploadMediaMetadata) => void;
    onUploadSuccess: (res: UploadMediaResponse, metadata: UploadMediaMetadata) => void;
  }) =>
  (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    const { files } = target;
    const fileReader = new FileReader();
    const _metadata = { event: e, fileReader };
    fileReader.onloadstart = () => {
      onLoadStart(_metadata);
    };
    fileReader.onload = async () => {
      try {
        if (fileReader.readyState !== 2 || !files) {
          return;
        }
        const file = files[0];
        const metadata = { event: e, fileReader, file };
        const tryUploadFile = async (): Promise<UploadMediaResponse> => {
          const { data, apolloClient } = await getUploadMediaUrl(metadata);
          if (!data) {
            throw new Error('Did not receive upload url');
          }
          const fetchOptions = getFetchOptions(metadata);
          const url = data.url;
          const res = await fetch(url, fetchOptions);
          return { status: res.status, data, apolloClient };
        };
        const invalidateUploadUrlCache = (res: UploadMediaResponse) => {
          const { apolloClient, data } = res;
          const { cache } = apolloClient;
          const uploadUrlId = cache.identify(data);
          cache.modify({
            id: uploadUrlId,
            fields(fieldValue, details) {
              if (fieldValue === uploadUrlId) {
                return details.INVALIDATE;
              }
            },
          });
        };
        const onUpload = (res: UploadMediaResponse) => {
          const { status } = res;
          if (status >= 200 && status < 300) {
            onUploadSuccess(res, metadata);
            invalidateUploadUrlCache(res);
          } else {
            onUploadFail(metadata);
          }
        };
        let res = await tryUploadFile();
        if (res.status === 403) {
          invalidateUploadUrlCache(res);
          res = await tryUploadFile();
          onUpload(res);
        } else {
          onUpload(res);
        }
      } catch (err) {
        onError(err, _metadata);
      } finally {
        onCleanup(_metadata);
        target.value = '';
      }
    };
    if (e.target.files) {
      fileReader.readAsDataURL(e.target.files[0]);
    }
  };

export const monthLabels: Option[] = [];
const date = new Date();
date.setDate(15);
date.setHours(0);
date.setMinutes(0);
date.setSeconds(0);
date.setMilliseconds(0);
for (let i = 0; i < 600; ++i) {
  const mmmYyyy = monthFormatter(date);
  monthLabels.push({ label: mmmYyyy, value: mmmYyyy });
  const currMonth = date.getMonth();
  if (currMonth === 0) {
    date.setFullYear(date.getFullYear() - 1);
    date.setMonth(11);
  } else {
    date.setMonth(currMonth - 1);
  }
}
Object.freeze(monthLabels);

export const extractFormFieldError = (formState: FormState<FieldValues>, fieldName: string) => {
  const { isDirty, errors } = formState;
  if (!isDirty || !formState.dirtyFields[fieldName]) {
    return undefined;
  }
  if (errors[fieldName]?.message) {
    return formState.errors[fieldName]?.message as string;
  }
  if (errors) {
    const parts = fieldName.split('.');
    let path: any = formState.errors;
    if (parts?.length) {
      for (let i = 0; i < parts.length; ++i) {
        const err = path[parts[i]];
        if (!err) {
          return undefined;
        }
        path = err;
      }
      if (path) {
        return path?.message as string;
      }
    }
  }
  return undefined;
};

export const salesCycleOptions = [
  { label: '1-3 Months', value: '1-3 months' },
  { label: '3-6 Months', value: '3-6 months' },
  { label: '6-9 Months', value: '6-9 months' },
  { label: '9-12 Months', value: '9-12 months' },
  { label: '12-18 Months', value: '12-18 months' },
  { label: '18 Months+', value: '18 months+' },
];

export const ACCEPT_PDF_ONLY = { 'application/pdf': ['.pdf'] };
