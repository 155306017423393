import { QueryResult, useQuery } from '@apollo/client';
import { useSession } from '../contexts/SessionProvider';
import { RainMakerProfileQuery } from '../gql/graphql';
import { RAIN_MAKER_PROFILE_QUERY } from '../utils/graphql';
import { getProfileId } from '../utils/helper';

export const useProfile = (
  kind: 'rm' | 'admin' | 'manager',
): {
  loading: boolean;
  profile: RainMakerProfileQuery['rainMakerProfile'] | undefined;
} => {
  const { session } = useSession();
  const q = RAIN_MAKER_PROFILE_QUERY;
  // switch (kind) {
  //   case 'rm':
  //     q = RAIN_MAKER_PROFILE_QUERY;
  //     break;
  // }
  const profileId = getProfileId(session);
  const res = useQuery<RainMakerProfileQuery>(q, {
    variables: {
      profileId,
    },
    skip: !profileId || !q,
    fetchPolicy: 'cache-first',
  });
  switch (kind) {
    case 'rm':
      return {
        loading: res.loading,
        profile: (res as QueryResult<RainMakerProfileQuery>).data?.rainMakerProfile,
      };
    default:
      return {
        loading: false,
        profile: undefined,
      };
  }
};
