import React from 'react';
import { GetSessionInformationQuery } from '../gql/graphql';
import { createCtx } from './common';

export type SessionContextInterface = {
  authenticated: boolean;
  session: Exclude<GetSessionInformationQuery['getSessionInformation'], undefined | null>;
  update: (sessionInformation: SessionContextInterface['session']) => void;
};

const [useCtx, SessionContextProvider] = createCtx<SessionContextInterface>();

const sessionContext: { context: SessionContextInterface | undefined } = {
  context: undefined,
};
export const SessionProvider = ({
  children,
  data,
}: {
  children: React.ReactNode;
  data?: GetSessionInformationQuery;
}) => {
  sessionContext.context = {
    authenticated: Boolean(data?.getSessionInformation?.email),
    session: data?.getSessionInformation ?? (null as any),
    update: (sessionInformation: Exclude<GetSessionInformationQuery['getSessionInformation'], undefined | null>) => {
      if (sessionContext.context) {
        sessionContext.context.authenticated = Boolean(sessionInformation?.email);
        sessionContext.context.session = sessionInformation;
      }
    },
  };
  return <SessionContextProvider value={sessionContext.context}>{children}</SessionContextProvider>;
};

export const useSession = useCtx;
export const clearSession = () => {
  sessionContext.context = undefined;
};
export const getSessionUnsafe = () => {
  return sessionContext.context;
};
