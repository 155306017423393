import { motion, useAnimation } from 'framer-motion';
import React from 'react';
import { LEFT_WIDTH_MAX_PIXEL, LEFT_WIDTH_MIN_PIXEL, LEFT_WIDTH_PERCENT } from '../../constants';
import { DarkModeProvider } from '../../contexts/DarkModeProvider';
import { useBreakpointValue } from '../../hooks/useBreakpointValue';
import { useIsMobileView } from '../../hooks/useIsMobile';
import theme, { Sizes, twSidePadding } from '../../theme';
import { HStack } from '../HStack';
import { Logo } from '../Logo';
import { VStack } from '../VStack';
import { AnimatedLogo } from '../animated/AnimatedLogo';
import { Display } from '../typography/Display';

const paddingLeft = '40px';
const paddingRight = '8px';
const _transitions = [
  { delay: 0.5, duration: 0.5 }, // R. appears
  { delay: 1, duration: 1.7 }, // purple slides right
];
const transitions: { delay: number; duration: number }[] = [];
let delay = 0;
for (const t of _transitions) {
  delay += t.delay;
  transitions.push({ duration: t.duration, delay });
  delay += t.duration;
}
const _mobileTransitions = [
  { delay: 0, duration: 0.5 }, // purple expands vertically
  { delay: 0.25, duration: 0.5 }, // R. appears
  { delay: 1, duration: 1.7 }, // purple slides down
];
const mobileTransitions: { delay: number; duration: number }[] = [];
delay = 0;
for (const t of _mobileTransitions) {
  delay += t.delay;
  mobileTransitions.push({ duration: t.duration, delay });
  delay += t.duration;
}
const LeftComponentAfter = ({ size }: { size: Sizes }) => {
  return <Display size={size} children="Welcome." />;
};
const AfterContainer = (props: { children: React.ReactNode }) => {
  return (
    <motion.div
      initial={{ x: -20 }}
      animate={{ x: 0, transition: { ...transitions[1], duration: transitions[1].duration - 0.1 } }}
      style={{ paddingLeft, paddingRight }}
      children={props.children}
      exit={{ opacity: 0 }}
    />
  );
};
const LogoContainer = motion(
  React.forwardRef<HTMLDivElement>((_, ref) => {
    const isMobileView = useIsMobileView();
    return (
      <div className={`flex ${twSidePadding} pt-2 md:pt-0`} ref={ref}>
        <Logo height={isMobileView ? '40px' : '80px'} />
      </div>
    );
  }),
);
export const WelcomeScreen = ({
  onStart,
  onNext,
  leftExitColor,
}: {
  onStart?: () => void;
  onNext: () => void;
  leftExitColor: string;
}) => {
  const isMobileView = useIsMobileView();
  const nextRef = React.useRef<NodeJS.Timeout>();
  React.useEffect(() => {
    onStart?.();
    const lastTransition = transitions.at(-1)!;
    if (nextRef.current) {
      clearTimeout(nextRef.current);
    }
    nextRef.current = setTimeout(
      () => {
        onNext();
      },
      (lastTransition.delay + lastTransition.duration + 0.5) * 1000,
    );
    return () => {
      if (nextRef.current) {
        clearTimeout(nextRef.current);
      }
    };
  }, [onStart, onNext]);
  if (isMobileView) {
    return <Mobile leftExitColor={leftExitColor} />;
  }
  return <Desktop leftExitColor={leftExitColor} />;
};

const leftWidth = `clamp(${LEFT_WIDTH_MIN_PIXEL}px, ${LEFT_WIDTH_PERCENT}%, ${LEFT_WIDTH_MAX_PIXEL}px)`;
const leftInitial = {
  width: leftWidth,
  left: 0,
};
const leftAnimate = {
  width: '60%',
  left: '40%',
  right: 0,
  transition: { ...transitions[1], ease: [0.47, 0, 0.03, 1] },
};
const getLeftExit = (leftExitColor: string) => ({
  left: 0,
  width: leftWidth,
  backgroundColor: leftExitColor,
  transition: { duration: transitions[1].duration / 2, ease: [0.47, 0, 0.03, 1] },
});
const leftStyle = {
  backgroundColor: theme.colors.rain,
  height: '100%',
  position: 'absolute',
} as const;
function Desktop({ leftExitColor }: Readonly<{ leftExitColor: string }>) {
  const size = useBreakpointValue({
    base: '2xl',
    md: 'xl',
    lg: '2xl',
  });
  return (
    <HStack className="flex-1">
      <div className="bg-white flex flex-row items-center">
        <AfterContainer>
          <LeftComponentAfter size={size} />
        </AfterContainer>
      </div>
      <motion.div initial={leftInitial} animate={leftAnimate} exit={getLeftExit(leftExitColor)} style={leftStyle}>
        <DarkModeProvider>
          <LogoContainer initial={{ opacity: 0 }} exit={{ opacity: 1, transition: { delay: 0.5 } }} />
          <motion.div
            style={{ paddingLeft, position: 'absolute', top: '50%', y: '-50%' }}
            initial={{ x: -200 }}
            animate={{ x: 0, transition: { ...transitions[0], ease: [0, 1, 0.3, 1] } }}
            exit={{ opacity: 0 }}
          >
            <AnimatedLogo delay={transitions[1].delay} size={size} />
          </motion.div>
        </DarkModeProvider>
      </motion.div>
    </HStack>
  );
}

function Mobile({ leftExitColor }: Readonly<{ leftExitColor: string }>) {
  const controls = useAnimation();
  React.useEffect(() => {
    (async () => {
      await controls.start({ height: '50%' }, _mobileTransitions[0]);
      await controls.start(
        {
          bottom: 0,
        },
        {
          duration: mobileTransitions[2].duration,
          delay: mobileTransitions[2].delay - _mobileTransitions[0].duration - _mobileTransitions[0].delay,
          ease: [0.47, 0, 0.03, 1],
        },
      );
    })();
  }, [controls]);
  return (
    <VStack className="flex-1">
      <div className="flex flex-1 bg-white justify-center">
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{
            y: 0,
            opacity: 1,
            transition: { ...mobileTransitions[2], duration: mobileTransitions[2].duration - 0.1 },
          }}
          style={{ paddingLeft, paddingRight }}
          exit={{ opacity: 0 }}
        >
          <LeftComponentAfter size="2xl" />
        </motion.div>
      </div>
      <div className="flex flex-1 bg-white" />
      <motion.div
        initial={{ height: '158px' }}
        animate={controls}
        exit={{
          top: 0,
          height: '56px',
          backgroundColor: leftExitColor,
          transition: { duration: mobileTransitions[2].duration / 2, ease: [0.47, 0, 0.03, 1] },
        }}
        style={{ backgroundColor: theme.colors.rain, width: '100%', position: 'absolute' }}
      >
        <DarkModeProvider>
          <LogoContainer initial={{ opacity: 0 }} exit={{ opacity: 1, transition: { delay: 0.5 } }} />
          <motion.div
            style={{ paddingLeft, position: 'absolute', top: '50%', y: '-50%' }}
            initial={{ x: -200 }}
            animate={{ x: 0, transition: { ...mobileTransitions[1], ease: [0, 1, 0.3, 1] } }}
            exit={{ opacity: 0 }}
          >
            <AnimatedLogo delay={mobileTransitions[2].delay} size="2xl" />
          </motion.div>
        </DarkModeProvider>
      </motion.div>
    </VStack>
  );
}
