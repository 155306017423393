import React from 'react';
import { twMerge } from 'tailwind-merge';
import { ButtonSize, useButtonPadding } from '../../../hooks/useButtonPadding';
import { Button, ButtonProps } from './Button';

export type PrimaryButtonProps = Omit<ButtonProps, 'kind'> & {
  border?: boolean;
  regular?: boolean;
  size?: ButtonSize;
};

export const PrimaryButton = React.memo(
  React.forwardRef<HTMLButtonElement, PrimaryButtonProps>((props, ref) => {
    const { border, className, variant, size, isDisabled, disabled, ...rest } = props;
    const _isDisabled = isDisabled || disabled || props.isLoading;
    let bgColor: string = 'bg-primary-500 active:bg-primary-600 enabled:hover:bg-primary-600 disabled:bg-primary-700';
    let textColor: string = 'text-white group-disabled:text-neutral-500';
    let fillColor = 'fill-white group-disabled:fill-neutral-500';
    let borderColor;
    if (variant === 'dark') {
      textColor = 'text-white group-disabled:text-primary-400';
      fillColor = 'fill-white group-disabled:fill-primary-400';
    } else if (variant === 'contrast') {
      bgColor = 'bg-neutral-900 active:bg-neutral-800 enabled:hover:bg-neutral-800';
    } else if (variant === 'contrast-dark') {
      bgColor = 'bg-white active:bg-neutral-300 enabled:hover:bg-neutral-300';
      textColor = 'text-primary-500 group-disabled:text-neutral-700';
      fillColor = 'fill-primary-500 group-disabled:fill-neutral-700';
      borderColor = border ? 'border-rain disabled:border-neutral-500' : undefined;
    }

    const padding = useButtonPadding(size);
    return (
      <Button
        isDisabled={_isDisabled}
        {...rest}
        className={twMerge('group', bgColor, padding, border ? 'border' : undefined, borderColor, className)}
        textColor={textColor}
        fillColor={fillColor}
        ref={ref}
      >
        {props.children as any}
      </Button>
    );
  }),
);
