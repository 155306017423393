import { ApolloError } from '@apollo/client';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { VStack } from '../components/VStack';
import { PrimaryButton } from '../components/buttons/core/PrimaryButton';
import { Body1 } from '../components/typography/Body1';
import { Display } from '../components/typography/Display';
import { useLogger } from '../contexts/LoggingProvider';

export default function ErrorPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { logError } = useLogger();
  let { header, message, backable } = location.state ?? {};

  React.useEffect(() => {
    if (location?.state) {
      const err = location.state.error;
      const msg = typeof err === 'string' ? err : err ? err.message : 'internal error';
      logError(msg, location.state);
      if (err instanceof ApolloError && err.graphQLErrors[0]?.extensions?.code === 'UNAUTHENTICATED') {
        navigate('/get-started', { state: { from: location.state.from } });
      }
    }
  }, [logError, navigate, location.state]);

  header ??= 'Sorry, something went wrong';
  message ??=
    'You may have accessed an invalid link or there may be network connection issues. Please try again later.';
  backable ??= false;
  return (
    <VStack className="justify-center items-center w-full flex-grow">
      <Display size="xl" className="mb-8">
        {header}
      </Display>
      <Body1 className="mb-4 text-body">{message}</Body1>
      <PrimaryButton
        variant="contrast"
        className="mt-3 w-[200px]"
        onClick={() => {
          backable ? navigate(-1) : navigate('/');
        }}
        size="lg"
      >
        {backable ? 'Back' : 'Home'}
      </PrimaryButton>
    </VStack>
  );
}
