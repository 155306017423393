import { twMerge } from 'tailwind-merge';
import { useDarkModeFlag } from '../../../contexts/DarkModeProvider';
import { useFormLabelStyle } from '../../../hooks/useFormLabelStyle';

export const Label = (props: {
  id?: string;
  name?: string;
  // not needed when focus is controlled
  hasPlaceholder?: boolean;
  label: string;
  isFocused?: boolean;
  // add suffix to label
  isOptional?: boolean;
}) => {
  const { id, name, hasPlaceholder, label, isOptional, isFocused } = props;
  const isDark = useDarkModeFlag();
  // in case we need controlled focus
  const labelStyles = useFormLabelStyle({}, isFocused);
  const labelColor = isDark ? 'text-neutral-500' : 'text-neutral-700';
  const smallClasses = 'scale-90 -translate-y-4 top-[1.3rem]';
  // http://stackoverflow.com/a/35302732
  const hasValueClasses =
    'peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2';
  const focusClasses = 'peer-focus:scale-90 peer-focus:-translate-y-4 peer-focus:top-[1.3rem]';
  const labelPosition = 'absolute duration-100 transform -translate-y-4 scale-90 top-[1.3rem] z-10 origin-[0] left-1';
  return (
    <label
      htmlFor={id ?? name}
      className={twMerge(
        labelPosition,
        'text-sm font-medium px-2 peer-focus:px-2 uppercase cursor-text pointer-events-none',
        'flex max-w-full -z-0',
        labelColor,
        smallClasses,
        !hasPlaceholder && `${hasValueClasses} ${focusClasses}`,
      )}
      style={labelStyles}
    >
      {/* clip too-long-label with ellipsis */}
      <span className="whitespace-nowrap overflow-hidden text-ellipsis">{label}</span>
      {/* whiteSpace: pre - show white space prefix */}
      {isOptional ? <span className="whitespace-pre">{' (Optional)'}</span> : null}
    </label>
  );
};
