import SvgLogoDark from '../assets/logo-dark.svg?react';
import SvgLogo from '../assets/logo.svg?react';
import SvgLogoSymbolDark from '../assets/logo_symbol-white.svg?react';
import SvgLogoSymbol from '../assets/logo_symbol.svg?react';
import { useDarkModeFlag } from '../contexts/DarkModeProvider';
import { useIsMobileView } from '../hooks/useIsMobile';

export const Logo = ({
  color,
  width,
  height,
}: {
  color?: string;
  width?: string | number;
  height?: string | number;
}) => {
  const isMobileView = useIsMobileView();
  const size = isMobileView ? 50 : 100;
  const isDark = useDarkModeFlag();
  let Element;
  if (isDark) {
    Element = isMobileView ? SvgLogoSymbolDark : SvgLogoDark;
  } else {
    Element = isMobileView ? SvgLogoSymbol : SvgLogo;
  }
  return <Element width={width ?? size} height={height ?? size} fill={color} />;
};
