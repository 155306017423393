import EditIcon from '@material-design-icons/svg/filled/mode_edit.svg?react';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useDarkModeFlag } from '../../contexts/DarkModeProvider';
import theme from '../../theme';
import { ImagePlaceholder } from '../ImagePlaceholder';
import { Loading } from '../Loading';
import { Pressable } from '../Pressable';

export type AvatarProps = {
  className?: string;
  isLoading?: boolean;
  isPerson: boolean;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  uri: string | null | undefined;
  withBorder?: boolean;
} & (
  | {
      spanDirection?: never;
    }
  | {
      spanDirection: 'row' | 'column';
    }
);

export const Avatar = (props: AvatarProps) => {
  const { className, isLoading, uri, name, isPerson, onChange, onClick, spanDirection, withBorder } = props;
  const isDark = useDarkModeFlag();
  const placeholderProps = {
    name,
    isPerson,
    onClick,
  };
  const placeholder = <ImagePlaceholder {...placeholderProps} />;
  const [shouldUsePlaceholder, setShouldUsePlaceholder] = React.useState<boolean>(!uri);

  React.useEffect(() => setShouldUsePlaceholder(!uri), [uri]);

  const onError = React.useCallback(() => {
    setShouldUsePlaceholder(true);
  }, []);

  const imageProps: React.ImgHTMLAttributes<HTMLImageElement> = {
    alt: `${name}-avatar`,
    className: twMerge('bg-white w-full h-full aspect-square object-contain', onClick ? 'cursor-pointer' : undefined),
    src: uri ?? 'err',
    // resizeMode: 'contain' as const,
    onError,
    onClick,
  };
  const onPress = onChange
    ? () => {
        if (inputRef.current) {
          inputRef.current.click();
        }
      }
    : undefined;
  const inputRef = React.useRef<HTMLInputElement>();
  const editInput = onChange ? (
    <input
      ref={(r) => {
        if (r) {
          inputRef.current = r;
        }
      }}
      hidden
      id="upload"
      name="logo"
      accept="image/*"
      type="file"
      onChange={onChange}
    />
  ) : null;
  const editIcon = onChange ? (
    <Pressable
      onClick={onPress}
      className={twMerge(
        'absolute z-10 right-0 top-0 p-1 w-[clamp(30px,20%,50px)] h-[clamp(30px,20%,50px)] opacity-60',
        'visible md:invisible md:group-hover/avatar:visible',
        isDark ? 'bg-black' : 'bg-white',
      )}
    >
      <EditIcon fill={isDark ? theme.colors.white : theme.colors.black} width="100%" height="100%" />
    </Pressable>
  ) : null;
  const content = (
    <>
      {editInput}
      {isLoading ? (
        <div className="flex flex-1 justify-center items-center">
          <Loading />
        </div>
      ) : (
        <>
          {shouldUsePlaceholder ? placeholder : <img {...imageProps} />}
          {editIcon}
        </>
      )}
    </>
  );
  return (
    <div
      className={twMerge(
        // flex-col: make sure background is correctly grown horizontally e.g. in ImageUpload
        'flex flex-col justify-center align-center group/avatar',
        withBorder ? 'border-[2px] border-white' : undefined,
        spanDirection !== undefined ? 'aspect-square' : undefined,
        spanDirection === 'row' ? 'w-full' : spanDirection === 'column' ? 'h-full' : undefined,
        className,
      )}
    >
      {content}
    </div>
  );
};
