import useWindowDimensions from './useWindowDimensions';

const breakpoints = {
  base: 0,
  sm: 480,
  md: 768,
  lg: 992,
  xl: 1280,
  '2xl': 1700,
} as const;
const breakpointLabels = Object.keys(breakpoints);
const breakpointValues = Object.values(breakpoints);

export function useBreakpointValue(values: Partial<Record<keyof typeof breakpoints, any>>) {
  const windowWidth = useWindowDimensions()?.width;
  const currentBreakpoint = findCurrentBreakpoint(windowWidth);
  return findLastValidBreakpoint(values, currentBreakpoint);
}

function findCurrentBreakpoint(width: number): number {
  for (let i = 0; i < breakpointValues.length; ++i) {
    if (width === breakpointValues[i]) {
      return i;
    } else if (breakpointValues[i] > width && i > 0) {
      return i - 1;
    }
  }
  if (breakpointValues[breakpointValues.length - 1] < width) {
    return breakpointValues.length - 1;
  }
  return -1;
}

function findLastValidBreakpoint(values: any, currentBreakpoint: number) {
  const valArray = Array.isArray(values) ? values : breakpointLabels.map((bPoint: string) => values[bPoint]);
  return (
    valArray[currentBreakpoint] ??
    valArray
      .slice(0, currentBreakpoint + 1)
      .filter((v: any) => v !== undefined)
      .pop()
  );
}
