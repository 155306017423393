import { ExitIcon } from '@radix-ui/react-icons';
import { useNavigate } from 'react-router-dom';
import { useDarkModeFlag } from '../../contexts/DarkModeProvider';
import { Pressable } from '../Pressable';

export const SignOutButton = ({ onClick }: { onClick?: () => void; }) => {
  const navigate = useNavigate();
  const isDarkMode = useDarkModeFlag();
  return (
    <Pressable
      className="flex w-8 h-8 justify-center items-center"
      onClick={() => {
        onClick?.();
        navigate('/logout');
      }}
    >
      <ExitIcon className={isDarkMode ? 'text-white' : 'text-black'} width="20px" height="20px" />
    </Pressable>
  );
};
