import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  FieldFunctionOptions,
  FieldPolicy,
  HttpLink,
  InMemoryCache,
  Observable,
  Operation,
  split,
} from '@apollo/client';
import { Reference, getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/link-error';
import { print } from 'graphql';
import { Client, createClient } from 'graphql-ws';
import { clearSession, getSessionUnsafe } from '../contexts/SessionProvider';
import {
  CompaniesQueryVariables,
  MeetingsQueryVariables,
  QuestListQuery,
  QuestListQueryVariables,
  QuestSort,
  Sort,
} from '../gql/graphql';
import { getServerUrl, getServerWebSocket, mergeSort } from './helper';

class GraphQLWsLink extends ApolloLink {
  constructor(private client: Client) {
    super();
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: (e) => {
            sink.next(e as FetchResult);
          },
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        },
      );
    });
  }
}

const httpLink = new HttpLink({
  credentials: import.meta.env.MODE === 'development' ? 'include' : undefined,
  uri: `${getServerUrl(false)}/graphql`,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${getServerWebSocket()}/graphql/subscriptions`,
  }),
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const cache = new InMemoryCache({
  typePolicies: {
    SelectOption: {
      keyFields: ['value'],
    },
    Session: {
      keyFields: ['email'],
    },
    MeetingAttendee: {
      keyFields: ['email'],
    },
    Query: {
      fields: {
        meetings: {
          keyArgs: ['questUuid'],
          merge(existing, incoming, { readField }) {
            const a = (existing ?? []).map((ref) => ({
              id: readField<string>('id', ref),
              meetingDate: readField<Date>('meetingDate', ref),
              ref,
            }));
            const b = (incoming ?? []).map((ref) => ({
              id: readField<string>('id', ref),
              meetingDate: readField<Date>('meetingDate', ref),
              ref,
            }));
            const merged = mergeSort(a, b, 'meetingDate', 'id', true);
            return merged.map((res) => res.ref);
          },
        } satisfies FieldPolicy<
          Reference[],
          Reference[],
          never,
          FieldFunctionOptions<
            // hack
            MeetingsQueryVariables | Record<string, any>,
            MeetingsQueryVariables | Record<string, any>
          >
        >,
        companies: {
          keyArgs: ['uuid'],
          merge(existing, incoming, { readField }) {
            const a = (existing ?? []).map((ref) => ({
              uuid: readField<string>('uuid', ref),
              name: readField<string>('name', ref),
              ref,
            }));
            const b = (incoming ?? []).map((ref) => ({
              uuid: readField<string>('uuid', ref),
              name: readField<string>('name', ref),
              ref,
            }));
            const merged = mergeSort(a, b, 'name', 'uuid', true);
            return merged.map((res) => res.ref);
          },
        } satisfies FieldPolicy<
          Reference[],
          Reference[],
          never,
          FieldFunctionOptions<CompaniesQueryVariables, CompaniesQueryVariables>
        >,
        quests: {
          keyArgs: ['uuid'],
          merge(existing, incoming, { readField, args }) {
            // temp hack to make blotter work. Otherwise it merges
            // existing items, which might be wrong if the filter changed
            if (args?.statuses) {
              return incoming;
            }
            let a = (existing?.data ?? []).map((ref) => ({
              uuid: readField<string>('uuid', ref),
              name: readField<string>('name', ref),
              status: readField<string>('status'),
              ref,
            }));
            const b = (incoming?.data ?? []).map((ref) => ({
              uuid: readField<string>('uuid', ref),
              name: readField<string>('name', ref),
              ref,
            }));
            const orderBy = (args?.orderBy as QuestSort[] | undefined)?.[0].name;
            const merged = mergeSort(a, b, 'name', 'uuid', orderBy === Sort.Desc);
            return {
              __typename: incoming.__typename,
              cursor: incoming.cursor,
              data: merged.map((res) => (res as any).ref),
              total: incoming.total + (existing?.total ?? 0),
            };
          },
        } satisfies FieldPolicy<
          { total: number; data: QuestListQuery['quests']['data'] },
          {
            total: number;
            cursor?: string;
            __typename: string;
            data: QuestListQuery['quests']['data'];
          } & Reference,
          never,
          FieldFunctionOptions<QuestListQueryVariables, QuestListQueryVariables>
        >,
      },
    },
  },
  dataIdFromObject: (object) =>
    (object.uuid ?? object.id ?? object.name)?.toString()?.replace(/^/, object.__typename ?? ''),
}).restore((window as any).__APOLLO_STATE__);

export const logout = async () => {
  await cache.reset();
  cache.gc({ resetResultCache: true, resetResultIdentities: true });
  await client.clearStore();
  const session = getSessionUnsafe();
  clearSession();
  if (window.location.pathname !== '/logout') {
    window.localStorage.setItem('prevUrl', window.location.href);
  }
  window.localStorage.setItem('prevAs', session?.session?.as ?? '');
  window.location.replace('/get-started');
};

const errorLink = () =>
  onError(({ graphQLErrors }) => {
    let errorCode;
    if (graphQLErrors) {
      errorCode = graphQLErrors.find((error) => error.extensions?.code)?.extensions?.code;
    }
    switch (errorCode) {
      case 'UNAUTHENTICATED': {
        logout();
        throw new Error('logged out');
      }
      case 'UNAUTHORIZED': {
        window.location.href = '/forbidden';
        break;
      }
    }
  });

const client = new ApolloClient({
  link: errorLink().concat(splitLink),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});
export default client;
