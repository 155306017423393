import React from 'react';
import { Drawer } from '../components/page/Drawer';
import theme from '../theme';
import { createCtx } from './common';

export type DrawerElement = React.ReactElement;
export type DrawerOptions = { left: boolean };

const [useCtx, DrawerContextProvider] = createCtx<{
  isOpen: boolean;
  close: () => void;
  open: (element: DrawerElement, options?: DrawerOptions) => void;
}>();

export const DrawerProvider = ({ children }: { children: React.ReactNode }) => {
  const [drawerElement, setDrawerElement] = React.useState<React.ReactNode>(null);
  const [left, setLeft] = React.useState<boolean>(false);
  const isDrawerOpen = drawerElement !== null;
  const onClose = () => setDrawerElement(null);
  React.useEffect(() => {
    if (isDrawerOpen) {
      document.documentElement.classList.add('no-scroll');
      document.body.classList.add('no-scroll');
      document.getElementById('root')?.classList.add('no-scroll');
    } else {
      document.documentElement.classList.remove('no-scroll');
      document.body.classList.remove('no-scroll');
      document.getElementById('root')?.classList.remove('no-scroll');
    }
    return () => {
      document.documentElement.classList.remove('no-scroll');
      document.body.classList.remove('no-scroll');
      document.getElementById('root')?.classList.remove('no-scroll');
    };
  }, [isDrawerOpen]);
  return (
    <DrawerContextProvider
      value={{
        isOpen: drawerElement !== null,
        open: (newElement, options) => {
          if (options?.left) {
            setLeft(true);
          }
          setDrawerElement(newElement);
        },
        close: onClose,
      }}
    >
      {children}
      <Drawer
        children={drawerElement}
        left={left}
        isOpen={drawerElement !== null}
        onClose={onClose}
        backgroundColor={theme.colors.black}
      />
    </DrawerContextProvider>
  );
};

export const useDrawer = useCtx;
