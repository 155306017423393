import moment, { MomentInput } from 'moment';
import { Location } from 'react-router-dom';
import { Role } from '../constants';
import { getSessionUnsafe } from '../contexts/SessionProvider';
import { As, GetQuestDetailsQuery, GigType, QuestParticipant, Session } from '../gql/graphql';
import { Match } from '../hooks/useReplaceParams';

export type QuestDetailsQuery = Exclude<GetQuestDetailsQuery['quest'], null | undefined>;

const metricLookup = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'K' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'B' },
  { value: 1e12, symbol: 'T' },
  { value: 1e15, symbol: 'P' },
  { value: 1e18, symbol: 'E' },
];

export const urlRegex =
  /^(((https?|ftp):)?\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i;
export const websitePrefix = 'https://';
export const linkedinPrefix = 'https://www.linkedin.com/in/';

export const nFormatter = (num: number, digits = 1) => {
  const rx = /\.0+$|(\.\d*[1-9])0+$/;
  const item = metricLookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  if (!item) {
    return '0';
  }
  let formatted: string | number = num / item.value;
  if (digits !== 0 && formatted % 1 !== 0) {
    formatted = formatted.toFixed(digits);
  } else {
    formatted = String(formatted);
  }
  return formatted.replace(rx, '$1') + item.symbol;
};

export const nUnformatter = (value?: string | number | null, caseSensitive = false) => {
  if (!value) {
    return NaN;
  }
  let str = typeof value === 'number' ? String(value) : value;
  if (!caseSensitive) {
    str = str.toLowerCase();
  }
  const valStrings = str.match(
    new RegExp(
      `([0-9.]+)([${
        caseSensitive
          ? metricLookup.map((x) => x.symbol).join('')
          : metricLookup.map((x) => x.symbol.toLowerCase()).join('')
      }]?)`,
    ),
  );
  if (valStrings === null || valStrings[0] !== str) {
    return NaN;
  }
  valStrings.shift();
  const multiplier =
    metricLookup.find((x) =>
      caseSensitive ? x.symbol === valStrings[1] : x.symbol.toLowerCase() === valStrings[1].toLowerCase(),
    )?.value ?? 1;
  const num = parseFloat(valStrings[0]) * multiplier;
  return multiplier > 1 ? Math.round(num) : num;
};

export const durationFormatter = (num: number) => {
  let val;
  let unit;
  if (num < 60) {
    unit = 'Second';
    val = num;
  } else if (num < 3600) {
    unit = 'Minute';
    val = Math.floor(num / 60);
  } else if (num < 86400) {
    unit = 'Hour';
    val = Math.floor(num / 3600);
  } else {
    unit = 'Day';
    val = Math.floor(num / 86400);
  }
  if (val === 1) {
    return `${val} ${unit}`;
  }
  return `${val} ${unit}s`;
};

export const timeFormatter = (date: Date, format = 'HH:mm, DD MMM') => {
  if (format === 'HH:mm, DD MMM yyyy' && date.getFullYear() === new Date().getFullYear()) {
    format += ' yyyy';
  }
  const m = moment(date);
  if (m.hour() === 0 && m.minute() === 0 && format === 'HH:mm, DD MMM') {
    return m.format('DD MMM');
  }
  return m.format(format);
};

export const dateFormatter = (date: Date, format = 'DD MMM yyyy') => {
  return moment(date).format(format);
};

export const monthFormatter = (date: Date, format = 'MMM yyyy') => {
  return moment(date).format(format);
};

export const getFrom = (location: Location) => {
  if (location.state?.from) {
    return location.state?.from as Location | undefined;
  }
  const prevUrl = window.localStorage.getItem('prevUrl');
  if (prevUrl) {
    window.localStorage.removeItem('prevUrl');
    const sessionContext = getSessionUnsafe();
    if (sessionContext?.session?.as) {
      const as = sessionContext?.session?.as;
      const prevAs = window.localStorage.getItem('prevAs');
      window.localStorage.setItem('prevAs', as);
      if (prevAs !== as) {
        return undefined;
      }
    }
    try {
      const url = new URL(prevUrl);
      return {
        pathname: url.pathname,
        search: url.search,
        hash: url.hash,
        key: 'default',
      } as Location;
    } catch (ex) {
      // ignore
    }
  }
  return undefined;
};

export const queryLocation = (location?: Location) => {
  return new URLSearchParams(location?.search);
};

export const joinSearches = (...searches: string[]) => {
  const res = searches
    .map((search) => new URLSearchParams(search).toString())
    .filter((x) => x && x)
    .join('&');
  return res ? `?${res}` : '';
};

export const locationFormatter = (city?: string | null, country?: string | null) => {
  return [city, country].filter(Boolean).join(', ');
};

export const pick = <T, K extends keyof T>(obj: T, keys: readonly K[]): Pick<T, K> => {
  const ret: any = {};
  for (const key of keys) {
    ret[key] = obj[key];
  }
  return ret;
};

export const omit = <T, K extends keyof T>(obj: T, keys: readonly K[]): Omit<T, K> => {
  const ret = { ...obj };
  keys.forEach((key) => delete ret[key]);
  return ret;
};

export const capitalise = (str: string) => {
  return str[0].toUpperCase() + str.slice(1);
};

export const hasRole = (roles: string[] | null | undefined, roleToCheck: Role) => {
  return roles?.includes(roleToCheck) ?? false;
};

export const hasRoles = (roles: string[] | null | undefined, rolesToCheck: Role[]) => {
  const _roles = new Set(roles);
  return rolesToCheck.find((r) => _roles.has(r)) !== undefined;
};

export const getSlackURL = (slackChannelId: string | undefined) =>
  `slack://channel?team=${import.meta.env.VITE_SLACK_TEAM_ID}&id=${slackChannelId || ''}`;

export const EMPTY_PLACEHOLDER = '--';

export const isCrewLead = (
  session: Session | null,
  participantsOrLeadId: string | Pick<QuestDetailsQuery['participants'][0], 'rainMaker' | 'isLead'>[],
) => {
  return (
    isRainMaker(session) &&
    (typeof participantsOrLeadId === 'string'
      ? getProfileId(session) === participantsOrLeadId
      : participantsOrLeadId.some((p) => getProfileId(session) === p.rainMaker.uuid && p.isLead))
  );
};

export const isAdmin = (session: Session | null) => {
  return session?.as === As.Admin;
};

export const isManager = (session: Session | null) => {
  return session?.as === As.Manager;
};

export const isStartup = (session: Session | null) => {
  return session?.as === 'startup';
};

export const isRainMaker = (session: Session | null) => {
  return session?.as === As.RainMaker;
};

export const isInternal = (session: Session | null) => {
  return session?.email.endsWith('@raincommunity.com') || session?.email.endsWith('@rainmb.com');
};

export const isCorporate = (session: Session | null) => {
  return session?.isCorporate;
};

export const hasEditCapTableAccess = (session: Session | null) => {
  return isAdmin(session);
};

export const hasManageCrewAccess = (session: Session | null) => {
  if (hasRole(session?.roles, 'edit_dealteam')) {
    return true;
  }
  return hasEditCapTableAccess(session);
};

export const hasCrewAccess = (session: Session | null, participants: QuestDetailsQuery['participants']) => {
  if (isAdmin(session) || isManager(session)) {
    return true;
  }
  if (!isRainMaker(session)) {
    return false;
  }
  const profileId = getProfileId(session);
  if (!profileId) {
    return false;
  }
  return Boolean(participants.find((p) => p.rainMaker.uuid === profileId && Boolean(p.crewUuid)));
};

export const getProfileId = (session: Session | null) => {
  if (!session) {
    return null;
  }
  if (isStartup(session)) {
    return session.companyId ?? null;
  }
  if (isRainMaker(session)) {
    return session.rainMakerId ?? null;
  }
  if (isAdmin(session) || isManager(session)) {
    return session.rainMakerId ?? null;
  }
  return null;
};

export const getDaysLeft = (numValidDays: number, started: MomentInput) => {
  const now = moment();
  const endDate = moment(started).add(numValidDays, 'days');
  const remaining = Math.ceil(moment.duration({ from: now, to: endDate }).asDays());
  if (remaining < 1) {
    return 'last day';
  }
  return `${remaining} day${remaining > 1 ? 's' : ''} left`;
};

export const timeRemaining = (numValidDays: number, started: MomentInput) => {
  return moment(started).add({ days: numValidDays }).fromNow(true);
};

export const walletShortAddress = (walletAddress: string) =>
  `${walletAddress.slice(0, 6)}...${walletAddress.slice(walletAddress.length - 4, walletAddress.length)}`;

export function arrayBufferToBase64(buffer: ArrayBufferLike) {
  return window.btoa(String.fromCharCode(...new Uint8Array(buffer)));
}

export const apostrophe = (v: string) => {
  if (!v) {
    return v;
  }
  if (/s$/i.test(v)) {
    return `${v}'`;
  }
  return `${v}'s`;
};

export const computeRewardShare = (share: string | number, allocation: number) => {
  if (typeof share === 'string') {
    const parts = share.split('/').map((p) => parseInt(p, 10));
    return (allocation * parts[0]) / parts[1];
  }
  return (share / 100) * allocation;
};

export const formatEquity = (amount?: number | null, fraction?: number) => {
  if (amount === null || amount === undefined) {
    return '0';
  }
  if (Number.isInteger(amount) && !fraction) {
    return String(amount);
  }
  if (fraction) {
    return amount?.toFixed(fraction);
  }
  return amount?.toPrecision(5);
};

export const formatGigType = (type?: GigType) => {
  switch (type) {
    case GigType.GtmWorkshop:
      return 'GTM';
    case GigType.TechWorkshop:
      return 'TECH';
    case GigType.FactSheet:
      return 'FACT SHEET';
    default:
      return '';
  }
};

type Comparable = string | Date | number | undefined;
export const mergeSort = <
  K extends string,
  ID extends 'id' | 'uuid',
  T extends { [Key in K | ID]: string | Date | number | undefined },
>(
  a: T[],
  b: T[],
  field: K,
  id: ID,
  desc: boolean = true,
) => {
  const res: T[] = [];
  let ia = 0;
  let ib = 0;
  let ir = 0;
  const comp = (_a: Comparable, _b: Comparable) => {
    if (_a === undefined || _b === undefined) {
      return false;
    }
    if (typeof _a === 'string' && typeof _b === 'string') {
      return desc ? _b.localeCompare(_a) : _a.localeCompare(_b);
    }
    return desc ? _b >= _a : _a >= _b;
  };
  while (ia < a.length && ib < b.length) {
    if (a[ia][id] === b[ib][id]) {
      res[ir++] = a[ia++];
      ib++;
    } else if (comp(a[ia][field], b[ib][field])) {
      res[ir++] = a[ia++];
    } else {
      res[ir++] = b[ib++];
    }
  }
  return res.concat(a.slice(ia)).concat(b.slice(ib));
};

export const getCompanyBaseUrl = (companyId: string) => {
  return `/companies/${companyId}`;
};

export const getStartupBaseUrl = (companyId: string) => {
  return `/clients/${companyId}`;
};

export const getStartupUrl = (companyId: string) => {
  return `${getAppUrl()}${getStartupBaseUrl(companyId)}`;
};

export const getStartupQuestFormBaseUrl = (companyId: string) => {
  return `/clients/${companyId}/deals#create-deal`;
};

export const getQuestBaseUrl = (companyId: string, questUuid: string) => {
  return `/clients/${companyId}/deals/${questUuid}`;
};

export const getQuestUrl = (companyId: string, questUuid: string) => {
  return `${getAppUrl()}${getQuestBaseUrl(companyId, questUuid)}`;
};

export const getQuestResolveUrl = (questUuid: string) => {
  return `${getServerUrl()}/r/q/${questUuid}`;
};

export const getGigBaseUrl = (companyId: string, questUuid: string, gigUuid: string) => {
  return `/clients/${companyId}/deals/${questUuid}/steps/${gigUuid}`;
};

export const getAdminRewardsBaseUrl = (rainMakerId: string) => {
  return `/admin/rm-rewards/${rainMakerId}`;
};

export const getRmRewardsBaseUrl = () => {
  return `/rm/rewards`;
};

export const getAppUrl = () => {
  return `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? ':' + window.location.port : ''
  }`;
};

const getServerHost = () => {
  return import.meta.env.MODE === 'development'
    ? import.meta.env.VITE_SERVER_HOST
    : `${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;
};

export const getServerUrl = (includeSuffix = true) => {
  return `${window.location.protocol}//${getServerHost()}${
    includeSuffix ? import.meta.env.VITE_SERVER_PATH_SUFFIX ?? '' : ''
  }`;
};

export const getServerWebSocket = () => {
  const protocol = window.location.protocol.replace('http', 'ws');
  return `${protocol}//${getServerHost()}`;
};

export const getFromDeal = ({ location, match }: { location?: Location<{ fromDeal?: string }>; match?: Match }) => {
  if (location?.state?.fromDeal) {
    return location.state.fromDeal;
  }
  const { companyId, questId } = match?.params ?? {};
  if (companyId && questId) {
    return getQuestBaseUrl(companyId, questId);
  }
  return undefined;
};
