import { Cross1Icon } from '@radix-ui/react-icons';
import { motion } from 'framer-motion';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Pressable } from '../components/Pressable';
import { SecondaryButton } from '../components/buttons/core/SecondaryButton';
import { Ordering, ToggleSort } from '../components/table/ToggleSort';
import { useActionCentre } from '../contexts/ActionCenterProvider';
import { Sort } from '../gql/graphql';
import { twSidePadding, twVerticalPadding } from '../theme';
import { dateFormatter } from '../utils/helper';

type ActionCentreOrdering = Ordering<'date' | 'descr'>;

const slideFromTop = {
  visible: { y: 0 },
  hidden: { y: '-100%' },
};

export default function ActionCentre({ onClose }: { onClose: () => void }) {
  const actionCentre = useActionCentre();
  const navigate = useNavigate();
  const [sort, setSort] = React.useState<ActionCentreOrdering>({ field: 'date', dir: Sort.Asc });

  const sortedItems = React.useMemo(() => {
    if (sort.field === 'date' && sort.dir === Sort.Asc) {
      return actionCentre.items;
    }
    return actionCentre.items?.slice(0).sort((i1, i2) => {
      const [a, b] = sort.dir === Sort.Asc ? [i1, i2] : [i2, i1];
      let v1, v2;
      if (sort.field === 'descr') {
        v1 = a.description;
        v2 = b.description;
      } else {
        v1 = a.createdAt;
        v2 = b.createdAt;
      }
      return v1!.localeCompare(v2!);
    });
  }, [actionCentre, sort]);

  const actions = sortedItems ?? [];
  return (
    <motion.div
      className="h-full w-full z-50 absolute bg-neutral-200 max-h-full overflow-scroll"
      initial="hidden"
      animate="visible"
      exit="hidden"
      transition={{ bounce: 0 }}
      variants={slideFromTop}
    >
      <Pressable className={`flex flex-row justify-end py-2 ${twSidePadding} ${twVerticalPadding}`} onClick={onClose}>
        <Cross1Icon width={20} height={20} />
      </Pressable>
      <div className={`w-full ${twSidePadding} ${twVerticalPadding}`}>
        <div className="w-full overflow-auto ">
          <table className="w-full">
            <thead>
              <tr>
                <th colSpan={7} />
              </tr>
              <tr>
                <Header text="Date" field="date" sort={sort} onSort={setSort} />
                <Header text="Description" field="descr" sort={sort} onSort={setSort} />
                <Header text="" border={false} />
              </tr>
            </thead>
            <tbody>
              {actions.length === 0 ? (
                <tr>
                  <td colSpan={3} className="text-center p-4">
                    All caught up!
                  </td>
                </tr>
              ) : null}
              {actions.map((action) => (
                <tr key={action.uuid}>
                  <Td>{dateFormatter(action.createdAt)}</Td>
                  <Td>{action.description}</Td>
                  <Td border={false}>
                    <SecondaryButton
                      onClick={() => {
                        navigate(action.url);
                        onClose();
                      }}
                    >
                      Click to complete
                    </SecondaryButton>
                  </Td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </motion.div>
  );
}

const Header = ({
  border,
  field,
  onSort,
  sort,
  text,
}: {
  text: string;
  field?: ActionCentreOrdering['field'];
  border?: boolean;
  sort?: ActionCentreOrdering;
  onSort?: (s: ActionCentreOrdering) => void;
}) => {
  return (
    <th
      className={`border-b border-neutral-400 border-b-black p-4 pt-0 text-left min-w-[175px] ${
        border !== false ? 'border-r' : ''
      }`}
    >
      {sort ? (
        <ToggleSort field={field} sort={sort} onSort={onSort!}>
          {text}
        </ToggleSort>
      ) : null}
    </th>
  );
};

const Td = ({ children, border }: { children: React.ReactNode; border?: boolean }) => {
  return (
    <td className={`${border !== false ? 'border-r' : ''} border-neutral-400 border-b border-b-black p-4`}>
      {children}
    </td>
  );
};
