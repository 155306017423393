import { useDarkModeFlag } from "../contexts/DarkModeProvider";
import theme from "../theme";

export const useFormLabelStyle = (otherStyles: Record<string, any>, hidden: boolean | undefined) => {
  const isDark = useDarkModeFlag();
  if (hidden === undefined) {
    return {};
  }
  return {
    ...otherStyles,
    ...(hidden ? { ...styles.labelHidden, transform: `translateY(4px) scale(0.9)` } : styles.label),
    color: isDark ? theme.colors.neutral[500] : theme.colors.neutral[700],
  };
};

const styles = {
  label: {
    top: '50%',
    transform: 'translateY(-50%)',
    transformOrigin: '0',
    transition: 'all 100ms linear',
  },
  labelHidden: {
    top: 0,
    transformOrigin: 0,
    transition: 'all 100ms cubic-bezier(0, 0, 0.2, 1)',
  },
};
