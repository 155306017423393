export const Role = {
  Admin: 'admin',
  Founder: 'founder',
  Manager: 'manager',
  Staff: 'rain_staff',
  Research: 'researcher',
} as const;

export const Permission = ['edit_blotter', 'edit_dealteam', 'view_conflicts'] as const;

export type Role = (typeof Role)[keyof typeof Role] | (typeof Permission)[number];

export const ANON_PATH = '/get-started';

export const QUEST_INVITATION_VALID_DAYS = 7;

export const TERMS_AND_CONDITIONS_PERCENT_VIEWED_ENABLED_ACCEPT_THRESHOLD = 10;

export const GIG_DISPUTE_DAYS = 7;

export const REVENUE_DISPUTE_DAYS = GIG_DISPUTE_DAYS;
export const TECH_WORKSHOP_DISPUTE_DAYS = GIG_DISPUTE_DAYS;
export const FACT_SHEET_DISPUTE_DAYS = TECH_WORKSHOP_DISPUTE_DAYS;
export const TICKET_DISPUTE_DAYS = TECH_WORKSHOP_DISPUTE_DAYS;

export const STRIPE_PAYOUT_DELAY_DAYS = 3;

export const STRIPE_MAX_AMOUNT = 84000000; // 84130407

export const LEFT_WIDTH_MIN_PIXEL = 324;
export const LEFT_WIDTH_MAX_PIXEL = 450;
export const LEFT_WIDTH_PERCENT = 33.3;
export const TEXT_MAX_WIDTH = '80ch';
