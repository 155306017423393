import { gql, useMutation, useQuery } from '@apollo/client';
import { ExternalLinkIcon } from '@radix-ui/react-icons';
import moment from 'moment';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  DocusignSessionStatus,
  DocusignTemplatesQuery,
  DocusignTemplatesQueryVariables,
  InitDocusignSessionMutation,
  InitDocusignSessionMutationVariables,
} from '../gql/graphql';
import { Card } from './Card';
import { HStack } from './HStack';
import { Loading } from './Loading';
import { Topic } from './Topic';
import { VStack } from './VStack';
import { PrimaryButton } from './buttons/core/PrimaryButton';
import { SecondaryButton } from './buttons/core/SecondaryButton';
import { renderError } from './form/ErrorMessage';
import { Radio } from './form/input/Radio';
import { Body1 } from './typography/Body1';
import { Body2 } from './typography/Body2';

const NDA_TEMPLATES_QUERY = gql`
  query docusignTemplates {
    docusignTemplates {
      lastUsed
      name
      pageCount
      templateId
    }
  }
`;

const INIT_DOCUSIGN_SESSION_MUTATION = gql`
  mutation initDocusignSession($input: InitDocusignSessionInput) {
    initDocusignSession(input: $input)
  }
`;

export function DocusignLabel() {
  return (
    <HStack space="2" className="items-center">
      Docusign
      <img
        src="https://docucdn-a.akamaihd.net/olive/images/2.15.0/favicons/favicon.ico"
        style={{
          height: '16px',
          width: '16px',
        }}
      />
    </HStack>
  );
}

export function DocusignForm(props: { startupUuid?: string; questUuid?: string }) {
  const { questUuid, startupUuid } = props;
  const [params, setParams] = useSearchParams();
  const status = params.get('status');
  const { data, loading } = useQuery<DocusignTemplatesQuery, DocusignTemplatesQueryVariables>(NDA_TEMPLATES_QUERY);
  const [initDocusignSession, { loading: loadingNdaSession }] = useMutation<
    InitDocusignSessionMutation,
    InitDocusignSessionMutationVariables
  >(INIT_DOCUSIGN_SESSION_MUTATION);
  const [error, setError] = React.useState<string>();
  const [templateId, setTemplateId] = React.useState<string>();
  const renderSortedTemplates = React.useCallback(
    (currentTemplateId: string | undefined, setSelectedemplateId: typeof setTemplateId) => {
      return [...(data?.docusignTemplates ?? [])]
        .sort((a, b) => +new Date(b.lastUsed) - +new Date(a.lastUsed))
        .map((template) => (
          <Template
            key={template.templateId}
            template={template}
            setSelectedTemplateId={setSelectedemplateId}
            selectedTemplateId={currentTemplateId}
          />
        ));
    },
    [data?.docusignTemplates],
  );
  const onConfirmTemplate = React.useCallback(() => {
    return initDocusignSession({
      variables: {
        input: {
          templateId,
          questUuid,
          startupUuid,
        },
      },
      onCompleted(data) {
        window.open(data.initDocusignSession, '_self');
      },
      onError() {
        setError('Something went wrong!');
      },
    });
  }, [initDocusignSession, questUuid, startupUuid, templateId]);
  const onReset = React.useCallback(() => {
    setParams((prev) => {
      prev.delete('status');
      return prev;
    });
  }, [setParams]);
  if (loading) {
    return <Loading />;
  }
  return (
    <Card>
      <Topic title="Send a Docusign envelope">
        <VStack space="3" className="mt-4">
          {status != null ? (
            <SessionStatus status={status} onReset={onReset} />
          ) : (
            <>
              <Body1>Choose a template (Optional)</Body1>
              <VStack space="3">{renderSortedTemplates(templateId, setTemplateId)}</VStack>
              <div className="ml-auto">
                {renderError(error)}
                <SecondaryButton border onClick={onConfirmTemplate} isLoading={loadingNdaSession}>
                  <HStack space="2" className="items-center">
                    {templateId === undefined ? 'Continue without template' : 'Continue'}
                    <ExternalLinkIcon />
                  </HStack>
                </SecondaryButton>
              </div>
            </>
          )}
        </VStack>
      </Topic>
    </Card>
  );
}

function SessionStatus(props: { onReset: () => void; status: string }) {
  const { onReset, status } = props;
  let text;
  switch (status) {
    case DocusignSessionStatus.Cancelled:
      text = 'Sending was cancelled';
      break;
    case DocusignSessionStatus.Error:
      text = 'Something went wrong!';
      break;
    case DocusignSessionStatus.Expired:
      text = 'Session timedout';
      break;
    case DocusignSessionStatus.Success:
      text = 'Successfully sent NDA';
      break;
    default:
      onReset();
      return null;
  }
  const button = (['Cancelled', 'Error', 'Expired'] as (keyof typeof DocusignSessionStatus)[])
    .map((x) => DocusignSessionStatus[x])
    .includes(status) ? (
    <PrimaryButton onClick={onReset}>Start over</PrimaryButton>
  ) : null;
  return (
    <VStack space="4" className="mb-4 items-center justify-center self-center">
      {text ? <Body1>{text}</Body1> : null}
      <div>{button}</div>
    </VStack>
  );
}

function Template(props: {
  template: DocusignTemplatesQuery['docusignTemplates'][number];
  selectedTemplateId?: string;
  setSelectedTemplateId: (tid: string | undefined) => void;
}) {
  const { setSelectedTemplateId, template, selectedTemplateId } = props;
  return (
    <Radio
      iconPlacement="left"
      onSelect={() => setSelectedTemplateId(template.templateId)}
      onToggle={(selected) => setSelectedTemplateId(selected ? template.templateId : undefined)}
      isSelected={selectedTemplateId === template.templateId}
    >
      <VStack className="flex-1">
        <Body1 className="whitespace-nowrap overflow-hidden text-ellipsis">{template.name || '[Untitled]'}</Body1>
        <Body2 className="text-neutral-700">
          {template.pageCount} page{parseInt(template.pageCount, 10) > 1 ? 's' : ''}
        </Body2>
        <Body2 className="text-neutral-700">Last used {moment(template.lastUsed).fromNow()}</Body2>
      </VStack>
    </Radio>
  );
}
