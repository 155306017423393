import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useDarkModeFlag } from '../../../contexts/DarkModeProvider';
import { Label } from './Label';

export type NamedTextareaProps = React.HTMLProps<HTMLTextAreaElement> & {
  containerClassname?: string;
  isOptional?: boolean;
  error?: string;
};

export const NamedTextarea = React.memo(
  React.forwardRef<HTMLTextAreaElement, NamedTextareaProps>((props, ref) => {
    const { onChange, className, containerClassname, id, isOptional, label, placeholder, ...textAreaProps } = props;
    const isDark = useDarkModeFlag();
    const inputRef = React.useRef<HTMLTextAreaElement>();

    const _onChange = React.useCallback(
      (e: React.FormEvent<HTMLTextAreaElement>) => {
        if (onChange) {
          onChange(e);
        }
      },
      [onChange],
    );

    const inputRefHandler = React.useCallback(
      (r: HTMLTextAreaElement | null) => {
        if (r && inputRef.current !== r) {
          inputRef.current = r;
          if (isDark) {
            inputRef.current.classList.add('dark');
          }
        }
        if (typeof ref === 'function') {
          ref(r);
        }
        if (r) {
          const valueProperty = 'value';
          const inputPrototype = Object.getPrototypeOf(r);
          const descriptor = Object.getOwnPropertyDescriptor(inputPrototype, valueProperty);
          Object.defineProperty(r, valueProperty, {
            set: function (...args) {
              const oldValue: any = this[valueProperty];
              descriptor?.set?.apply(this, args);
              const newValue: string = this[valueProperty];
              if (oldValue !== newValue) {
                const target = { name: props.name!, value: newValue };
                _onChange({ target, currentTarget: target, nativeEvent: { type: 'input' } } as any);
              }
              return newValue;
            },
          });
        }
      },
      [ref, isDark, props.name, _onChange],
    );

    const textColor = props.color ? props.color : isDark ? 'text-white' : 'text-black';
    const placeholderColor = isDark ? 'placeholder-neutral-500' : 'placeholder-neutral-700';
    const backgroundColor = isDark ? 'bg-black' : 'bg-white';
    const borderColor = props.error ? 'border-red-600' : 'border-neutral-500';
    return (
      <div
        className={twMerge(
          'relative items-center flex-1 border-[1px] overflow-hidden',
          isDark ? 'focus-within:border-white' : 'focus-within:border-black',
          borderColor,
          backgroundColor,
          containerClassname,
        )}
      >
        {label ? <label className="inline-block" /> : null}
        <textarea
          className={twMerge(
            'border-0 block px-3 w-full appearance-none focus:outline-none peer resize-none',
            textColor,
            placeholderColor,
            backgroundColor,
            label ? 'min-h-[57px]' : '!py-2',
            className,
          )}
          id={id ?? props.name}
          onChange={_onChange}
          ref={inputRefHandler}
          // https://stackoverflow.com/a/35302732
          placeholder={placeholder ?? ' '}
          {...textAreaProps}
        />
        {label ? (
          <Label
            label={label}
            id={id}
            name={props.name}
            hasPlaceholder={Boolean(placeholder)}
            isOptional={isOptional}
          />
        ) : null}
      </div>
    );
  }),
);
