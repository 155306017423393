import { twMerge } from 'tailwind-merge';
import { useDarkModeFlag } from '../../contexts/DarkModeProvider';
import { twFonts } from '../../theme';

export type ButtonTextProps = React.HTMLAttributes<HTMLSpanElement> & { variant?: 'contrast' };

export const ButtonText = (props: ButtonTextProps) => {
  const { children, className, variant, ...rest } = props;
  const isDark = useDarkModeFlag();
  let color;
  if (variant === 'contrast') {
    if (isDark) {
      color = 'text-black';
    } else {
      color = 'text-white';
    }
  } else {
    if (isDark) {
      color = 'text-white';
    } else {
      color = 'text-black';
    }
  }
  return (
    <span {...rest} className={twMerge(twFonts.ButtonText, color, className)}>
      {children}
    </span>
  );
};
