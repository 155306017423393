import { twMerge } from 'tailwind-merge';
import { useDarkModeFlag } from '../../contexts/DarkModeProvider';
import { ButtonSize } from '../../hooks/useButtonPadding';
import { HStack } from '../HStack';
import { PrimaryButton } from '../buttons/core/PrimaryButton';
import { AlertStatus } from '../internal/AlertBanner';
import { StatusText } from './StatusText';

export default function SaveFormButton({
  buttonLeftElement,
  className,
  handleSubmit,
  isDirty,
  isSubmitDisabled,
  isSubmitting,
  isValid,
  saveButtonText,
  size,
  status,
}: {
  buttonLeftElement?: React.ReactElement;
  className?: string;
  handleSubmit: () => void;
  isDirty: boolean;
  isSubmitDisabled?: boolean;
  isSubmitting: boolean;
  isValid: boolean;
  saveButtonText?: string;
  size?: ButtonSize;
  status?: { status: AlertStatus; content?: React.ReactNode };
}) {
  const isDark = useDarkModeFlag();
  let space;
  let minW;
  switch (size) {
    case 'sm':
      space = '2' as const;
      minW = 'min-w-[60px]';
      break;
    default:
      space = '6' as const;
      minW = 'min-w-[120px]';
      break;
  }
  return (
    <HStack className="items-center" space={space}>
      {buttonLeftElement ?? null}
      <PrimaryButton
        isDisabled={!isValid || !isDirty || isSubmitting || isSubmitDisabled}
        isLoading={isSubmitting}
        onClick={handleSubmit}
        className={twMerge(`justify-center ${minW}`, !isValid ? 'invisible' : undefined, className)}
        id="btnSaveForm"
        size={size}
        variant={isDark ? 'dark' : undefined}
      >
        {saveButtonText ?? 'Save'}
      </PrimaryButton>
      {status ? <StatusText status={status} /> : null}
    </HStack>
  );
}
