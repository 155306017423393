import { createBrowserRouter, createMemoryRouter } from 'react-router-dom';
import { appRoutes } from './Routes';

export const getCurrentPathname = (req?: any) => {
  if (!req) {
    return window.location.pathname;
  }
  return req.path;
};

export const getRouter = (ssr = false) => {
  if (ssr) {
    return createMemoryRouter(appRoutes);
  }
  return createBrowserRouter(appRoutes);
};
