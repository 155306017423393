import { twMerge } from 'tailwind-merge';

export const Card = ({
  children,
  className,
  isParentOverflowHidden,
}: {
  children: React.ReactNode;
  className?: string;
  isParentOverflowHidden?: boolean;
}) => {
  return (
    <div
      className={twMerge(
        'flex flex-col gap-y-6 p-2 md:p-4 shadow-md bg-neutral-100',
        isParentOverflowHidden && 'ml-[5px] mb-[9px] mr-[5px] mt-[5px]',
        className,
      )}
    >
      {children}
    </div>
  );
};
