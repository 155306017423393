import { useQuery } from '@apollo/client';
import { BellIcon } from '@radix-ui/react-icons';
import { motion } from 'framer-motion';
import React from 'react';
import { useLocation, useMatches, useNavigate, useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { LEFT_WIDTH_MAX_PIXEL, LEFT_WIDTH_MIN_PIXEL, LEFT_WIDTH_PERCENT } from '../../constants';
import { useActionCentre } from '../../contexts/ActionCenterProvider';
import { DarkModeProvider, useDarkModeFlag } from '../../contexts/DarkModeProvider';
import { HeaderHeightProvider } from '../../contexts/HeaderHeightProvider';
import { useSession } from '../../contexts/SessionProvider';
import { useStartupProfileQuery } from '../../contexts/StartupProfileQueryProvider';
import { As, GetQuestDetailsQuery, GetQuestDetailsQueryVariables, Session } from '../../gql/graphql';
import { useBreakpointValue } from '../../hooks/useBreakpointValue';
import { useIsMobileView } from '../../hooks/useIsMobile';
import { Match } from '../../hooks/useReplaceParams';
import theme, { twSidePadding, twVerticalPadding } from '../../theme';
import { GET_QUEST_DETAILS } from '../../utils/graphql';
import { Divider } from '../Divider';
import { HStack } from '../HStack';
import { Logo } from '../Logo';
import { Pressable } from '../Pressable';
import { ScrollView } from '../ScrollView';
import { VStack } from '../VStack';
import { ProfileButton } from '../buttons/ProfileButton';
import { Breadcrumb } from '../navigation/Breadcrumb';

export function SplitScreen({
  backgroundColor,
  fadeLogo,
  footer,
  fullScreen,
  left,
  noLogout,
  noNav,
  noPadding,
  right,
}: Readonly<{
  backgroundColor?: string;
  fadeLogo?: boolean;
  footer?: React.ReactNode;
  fullScreen?: boolean;
  left: React.ReactNode;
  noLogout?: boolean;
  noNav?: boolean;
  noPadding?: boolean;
  right: React.ReactNode;
}>) {
  const { session } = useSession();
  const { questId } = useParams();
  const matches = useMatches() as Match[];
  const location = useLocation();
  const navigate = useNavigate();
  // TODO: avoid making query here
  const [{ data: dataStartup }] = useStartupProfileQuery();
  const { data: dataQuest } = useQuery<GetQuestDetailsQuery, GetQuestDetailsQueryVariables>(GET_QUEST_DETAILS, {
    variables: {
      uuid: questId!,
      asStartup: false, // TODO: remove hard-coding
    },
    skip: !questId,
    fetchPolicy: 'cache-first',
  });
  const showRightHeader = !noNav || !noLogout;
  const isMobileView = useIsMobileView();
  const headerHeight = isMobileView ? 56 : 80;

  const breadcrumbs = React.useMemo(() => {
    if (noNav) {
      return null;
    }
    return (
      <Breadcrumb
        breadcrumbs={matches
          .filter(({ handle }) => Boolean(handle?.crumb))
          .flatMap((match) => {
            return match.handle!.crumb(
              {
                ...dataStartup,
                ...dataQuest,
              },
              {
                location,
                match,
                matches,
                navigate,
              },
            );
          })}
      />
    );
  }, [matches, dataStartup, dataQuest, location, noNav, navigate]);

  const desktopHeaderContent = (
    <HStack className="flex-1 justify-between">
      <HStack className={`shrink md:gap-x-4 lg:gap-x-9 items-center ${twSidePadding}`}>{breadcrumbs}</HStack>
      <RightHeader noNav={noNav} />
    </HStack>
  );
  const mobileHeaderContent = (
    <div className="flex flex-1 justify-end">
      {noNav && noLogout ? null : (
        <HStack space="6" className="mr-3 items-center">
          <NotificationButton />
          {getProfileButton(session, noNav)}
        </HStack>
      )}
    </div>
  );

  const content = React.useMemo(() => {
    return (
      <div
        id="sccontent"
        className={`${noPadding || !right ? '' : twSidePadding} ${noPadding ? '' : twVerticalPadding} ${
          !noPadding ? 'xl:max-w-[1280px]' : ''
        } flex flex-1 flex-col box-border`}
      >
        {right}
      </div>
    );
  }, [noPadding, right]);

  const Stack = isMobileView ? VStack : HStack;

  return (
    <HeaderHeightProvider value={`-${headerHeight}px`}>
      <Stack className="flex-1">
        {fullScreen && !isMobileView ? null : (
          <LeftContainer
            backgroundColor={backgroundColor}
            breadcrumbs={breadcrumbs}
            fadeLogo={fadeLogo}
            headerContent={mobileHeaderContent}
          >
            {left}
          </LeftContainer>
        )}
        <VStack
          className={
            isMobileView
              ? 'flex-1' /* fill entire height of screen with content, else S3 file browser doesn't fill available space */
              : 'flex-[2_2_0%]'
          }
        >
          {!isMobileView && showRightHeader ? (
            <HStack className="justify-between items-center" style={{ height: `${headerHeight}px` }}>
              {desktopHeaderContent}
            </HStack>
          ) : null}
          {noNav && noLogout ? null : <Divider />}
          {getContent()}
          {footer ? <StickyFooter children={footer} /> : null}
        </VStack>
      </Stack>
    </HeaderHeightProvider>
  );

  function getContent() {
    if (isMobileView) {
      return content;
    }
    return <ScrollView id="rightScrollViewContainer" children={content} />;
  }

  // function getContent() {
  //   const content = tabs ? (
  //     <TabList
  //       tabs={tabs.tabs}
  //       default={tabs.default}
  //       noPadding={noPadding}
  //       maxW={!noPadding ? 'xl:max-w-[1280px]' : undefined}
  //     />
  //   ) : (
  //     <div
  //       className={`${noPadding || !right ? '' : twSidePadding} ${noPadding ? '' : twVerticalPadding} ${
  //         !noPadding ? 'xl:max-w-[1280px]' : ''
  //       } flex flex-1 flex-col box-border`}
  //     >
  //       {right}
  //     </div>
  //   );
  //   if (isMobileView) {
  //     return content;
  //   }
  //   return <ScrollView id="rightScrollViewContainer">{content}</ScrollView>;
  // }
}

export const SplitScreenBg = ({ noNav, noLogout }: { noNav?: boolean; noLogout?: boolean }) => (
  <SplitScreen noNav={noNav} noLogout={noLogout} left={null} right={null} />
);

const LeftHeader = ({
  backgroundColor,
  breadcrumbs,
  fadeLogo,
  headerContent,
  isMobileView,
}: {
  backgroundColor: string;
  breadcrumbs: React.ReactNode;
  fadeLogo?: boolean;
  headerContent: React.ReactNode;
  isMobileView: boolean;
}) => {
  const navigate = useNavigate();
  const logo = (
    <Pressable onClick={() => navigate('/')}>
      <Logo height={isMobileView ? '40px' : '80px'} />
    </Pressable>
  );
  return (
    <VStack style={{ backgroundColor }}>
      <HStack
        className={`items-center ${twSidePadding} ${isMobileView ? 'py-2' : ''}`}
        space={isMobileView ? '4' : '12'}
      >
        {fadeLogo ? <motion.div exit={{ opacity: 0 }}>{logo}</motion.div> : logo}
        {isMobileView ? headerContent : null}
      </HStack>
      {isMobileView ? <div className={`${twSidePadding}`}>{breadcrumbs}</div> : null}
    </VStack>
  );
};

const leftWidth = `clamp(${LEFT_WIDTH_MIN_PIXEL}px, ${LEFT_WIDTH_PERCENT}%, ${LEFT_WIDTH_MAX_PIXEL}px)`;
const LeftContainer = ({
  backgroundColor = theme.colors.rain,
  breadcrumbs,
  children,
  fadeLogo,
  headerContent,
}: {
  backgroundColor?: string;
  breadcrumbs: React.ReactNode;
  children?: React.ReactNode;
  fadeLogo?: boolean;
  headerContent: React.ReactNode;
}) => {
  const isMobileView = useIsMobileView();
  return (
    <DarkModeProvider>
      <div className="flex flex-col" style={{ backgroundColor, width: isMobileView ? undefined : leftWidth }}>
        <VStack className="flex-1" space={isMobileView ? '2' : undefined}>
          <LeftHeader
            backgroundColor={backgroundColor}
            breadcrumbs={breadcrumbs}
            fadeLogo={fadeLogo}
            headerContent={headerContent}
            isMobileView={isMobileView}
          />
          {children && (
            <div
              // md:pt-2 - top padding is too much in desktop view
              className={twMerge('flex flex-1 flex-col overflow-y-auto h-fit', twSidePadding)}
              style={{ backgroundColor }}
            >
              {children}
            </div>
          )}
        </VStack>
      </div>
    </DarkModeProvider>
  );
};

const RightHeader = ({ noNav }: { noNav?: boolean }) => {
  const { session } = useSession();
  const isRightHeaderDark = useBreakpointValue({
    base: true,
    md: false,
  });
  return (
    <DarkModeProvider value={isRightHeaderDark}>
      <HStack className={`items-center ${twSidePadding} pl-0`} space="6">
        <NotificationButton />
        {getProfileButton(session, noNav)}
      </HStack>
    </DarkModeProvider>
  );
};
function getProfileButton(session: Session | null, noNav?: boolean) {
  if (noNav) {
    return null;
  }
  switch (session?.as) {
    case As.RainMaker:
      return <ProfileButton kind="rm" />;
    case As.Admin:
      return <ProfileButton kind="admin" />;
    case As.Manager:
      return <ProfileButton kind="manager" />;
    default:
      return null;
  }
}

const NotificationButton = () => {
  const actionCentre = useActionCentre();
  const isDarkMode = useDarkModeFlag();
  const count = (actionCentre.items?.length ?? 0) >= 100 ? '99+' : actionCentre.items?.length;
  return (
    <Pressable
      aria-disabled={!actionCentre.items?.length}
      onClick={() => actionCentre.setOpen(true)}
      className={!actionCentre.items?.length ? 'opacity-50' : 'relative'}
    >
      {count ? (
        <div className="text-xs absolute -translate-x-1/2 -translate-y-1/2 left-[75%] rounded border-black md:border-neutral-200 border px-1 bg-rain text-white">
          {count}
        </div>
      ) : null}
      <BellIcon color={isDarkMode ? theme.colors.white : theme.colors.black} height="24px" width="24px" />
    </Pressable>
  );
};

function StickyFooter({ children }: Readonly<{ children: React.ReactNode }>) {
  return (
    <div className="flex flex-col justify-center items-center sticky bottom-0 w-full bg-white">
      <Divider />
      <VStack className="p-6 justify-center items-center w-full bg-rainLight">{children}</VStack>
    </div>
  );
}
