import React from 'react';
import { twMerge } from 'tailwind-merge';
import { HStack } from '../../HStack';
import { Loading } from '../../Loading';
import { ButtonText } from '../../typography/ButtonText';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  fillColor?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  textAlign?: 'left' | 'right';
  textColor?: string;
  variant?: 'dark' | 'contrast' | 'contrast-dark';
};

export const Button = React.memo(
  React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const {
      children,
      className,
      fillColor,
      rightIcon,
      leftIcon,
      isLoading,
      isDisabled,
      onClick,
      textAlign,
      textColor,
      ...rest
    } = props;
    const twTextAlign =
      textAlign === 'left' ? 'justify-start' : textAlign === 'right' ? 'justify-end' : 'justify-center';
    const buttonWidth = React.useRef<string>();
    const buttonRef = React.useRef<HTMLButtonElement>();
    const _onClick: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick?.(e);
      },
      [onClick],
    );

    const refHandler = (r: HTMLButtonElement) => {
      if (r) {
        buttonRef.current = r;
        buttonWidth.current = getComputedStyle(buttonRef.current).width;
        if (typeof ref === 'function') {
          ref(r);
        } else if (ref) {
          ref.current = r;
        }
      }
    };

    return (
      <button
        disabled={isDisabled}
        className={twMerge('items-center justify-center select-none', className)}
        {...rest}
        style={
          isLoading && buttonWidth.current
            ? {
                ...rest.style,
                width: buttonWidth.current,
              }
            : rest.style
        }
        onClick={_onClick}
        ref={refHandler}
      >
        {isLoading ? (
          <Loading color={fillColor} size="sm" />
        ) : (
          <HStack className={twMerge('items-center w-full', rightIcon || leftIcon ? undefined : twTextAlign)} space="3">
            {leftIcon ?? null}
            <ButtonText className={twMerge(textColor, 'flex flex-1', rightIcon || leftIcon ? undefined : twTextAlign)}>
              {children as any}
            </ButtonText>
            {rightIcon ?? null}
          </HStack>
        )}
      </button>
    );
  }),
);
