import { useMutation } from '@apollo/client';
import { GearIcon, HamburgerMenuIcon } from '@radix-ui/react-icons';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { DarkModeProvider, useDarkModeFlag } from '../../contexts/DarkModeProvider';
import { useDrawer } from '../../contexts/DrawerProvider';
import { useSession } from '../../contexts/SessionProvider';
import { UpdateRmProfileMutation } from '../../gql/graphql';
import { useProfile } from '../../hooks/useProfile';
import { UPDATE_RM_PROFILE_MUTATION } from '../../utils/graphql';
import { HStack } from '../HStack';
import { Loading } from '../Loading';
import { Pressable } from '../Pressable';
import { VStack } from '../VStack';
import { Avatar } from '../profile/Avatar';
import { ProfileContent, RoleSwitch } from '../profile/BasicProfile';
import { SignOutButton } from './SignOutButton';
import { TertiaryButton } from './core/TertiaryButton';

export const ProfileButton = ({ kind }: { kind: 'rm' | 'admin' | 'manager' }) => {
  const profileQuery = useProfile(kind);
  const sessionTuple = useSession();
  const isDarkMode = useDarkModeFlag();
  const { open, close, isOpen: isDrawerOpen } = useDrawer();
  const updateRmProfileMutation = useMutation<UpdateRmProfileMutation>(UPDATE_RM_PROFILE_MUTATION);
  const profile = profileQuery.profile;
  if (profileQuery.loading) {
    return <Loading />;
  }

  return (
    <Pressable
      onClick={() => {
        if (!isDrawerOpen) {
          const content = getContent();
          if (content) {
            open(<DarkModeProvider>{content}</DarkModeProvider>);
          }
        } else {
          close();
        }
      }}
    >
      {getIcon()}
    </Pressable>
  );

  function getIcon() {
    if (profile) {
      return (
        <div className="bg-white p-[2px]">
          <Avatar name={profile.name} isPerson uri={profile.avatar} className="h-[44px] w-[44px]" />
        </div>
      );
    }
    return (
      <HamburgerMenuIcon
        className={twMerge(' h-[32px] w-[32px]', isDarkMode ? 'text-white' : 'bg-neutral-200 fill-neutral-400')}
      />
    );
  }

  function getContent() {
    switch (kind) {
      case 'rm':
        if (profile) {
          return (
            <>
              <ProfileContent profile={profile} updateMutation={updateRmProfileMutation} />
              <div className="mt-8 flex justify-end">
                <SignOutButton />
              </div>
            </>
          );
        }
        break;
      case 'admin':
        return (
          <VStack space="2">
            <ManageUsersButton />
            <RoleSwitch sessionTuple={sessionTuple} />
            <HStack className="justify-end items-center mt-4">
              <SignOutButton />
            </HStack>
          </VStack>
        );
      case 'manager':
        return (
          <VStack space="2">
            <RoleSwitch sessionTuple={sessionTuple} />
            <HStack className="justify-end items-center mt-4">
              <SignOutButton />
            </HStack>
          </VStack>
        );
      default:
        return null;
    }
    return null;
  }
};

function ManageUsersButton() {
  const navigate = useNavigate();
  return (
    <TertiaryButton
      leftIcon={<GearIcon className="text-white" />}
      onClick={() => {
        navigate('/admin/users');
      }}
    >
      Manage users
    </TertiaryButton>
  );
}
