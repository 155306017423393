import { useQuery } from '@apollo/client';
import { useIsPresent } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { WelcomeScreen } from '../components/page/Welcome';
import { useSession } from '../contexts/SessionProvider';
import { RmHomeQuestsQuery } from '../gql/graphql';
import theme from '../theme';
import { RM_HOME_QUESTS_QUERY } from '../utils/graphql';
import { isAdmin, isManager, isRainMaker, isStartup } from '../utils/helper';

export default function Welcome() {
  const { session } = useSession();
  const navigate = useNavigate();
  const isPresent = useIsPresent();
  useQuery<RmHomeQuestsQuery>(RM_HOME_QUESTS_QUERY, {
    skip: !isRainMaker(session) || !isPresent,
  });
  return (
    <WelcomeScreen
      onStart={() => {
        import('./startup/Index');
        import('./quest/Details');
        import('./quest/Index');
      }}
      onNext={() => {
        let to;
        if (isStartup(session)) {
          to = `/client/${session.companyId}`;
        } else if (isRainMaker(session)) {
          to = `/rm/profile`;
        } else if (isAdmin(session) || isManager(session)) {
          to = `/deals`;
        } else {
          to = '/points';
        }
        navigate(to, { replace: true });
      }}
      leftExitColor={isRainMaker(session) ? theme.colors.rain : theme.colors.black}
    />
  );
}
