import React, { Children } from 'react';
import { twMerge } from 'tailwind-merge';
import { SpaceScale } from '../theme';

const DEFAULT_CLASS_NAME = 'flex flex-row min-h-0 min-w-0 items-stretch relative basis-auto shrink-0';

export type HStackProps = {
  children: React.ReactNode;
  className?: string;
  divider?: React.ReactElement<any>;
  onClick?: () => void;
  space?: SpaceScale;
  style?: React.CSSProperties;
};
export const HStack = React.forwardRef<HTMLDivElement, HStackProps>((props, ref) => {
  const gapClass = props.space ? `gap-x-${props.space}` : '';
  const className = twMerge(
    DEFAULT_CLASS_NAME,
    gapClass,
    props.className,
    props.onClick ? 'cursor-pointer' : undefined,
  );
  const children = React.useMemo(() => {
    if (!props.divider) {
      return props.children;
    }
    const res: React.ReactElement<any>[] = [];
    const divider = props.divider;
    let first = true;
    Children.forEach(props.children, (child, index) => {
      if (child === null) {
        return;
      }
      if (first) {
        first = false;
      } else {
        res.push(React.cloneElement(divider, { key: `divider_${index}` }));
      }
      res.push(child as React.ReactElement);
    });
    return res;
  }, [props.children, props.divider]);

  return (
    <div ref={ref} className={className} style={props.style} onClick={props.onClick}>
      {children}
    </div>
  );
});
