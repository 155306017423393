import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useDarkModeFlag } from '../../contexts/DarkModeProvider';
import { Sizes, twFonts } from '../../theme';

export const Display = (
  props: Omit<React.HTMLAttributes<HTMLDivElement>, 'color'> & { size: Sizes; variant?: 'contrast' },
) => {
  const { children, size, className: _className, variant, ...rest } = props;
  const isDark = useDarkModeFlag();
  const className = twMerge(getFont(size), getColor(variant === 'contrast', isDark), _className);
  return (
    <div className={className} {...rest}>
      {children}
    </div>
  );
};

function getFont(size: Sizes): string {
  switch (size) {
    case '2xl':
      return twFonts.DisplayXXLarge;
    case 'xl':
      return twFonts.DisplayXLarge;
    case 'lg':
      return twFonts.DisplayLarge;
    case 'md':
      return twFonts.DisplayMedium;
    case 'sm':
      return twFonts.DisplaySmall;
  }
}

function getColor(isContrast: boolean, isDark: boolean) {
  if (isContrast) {
    return isDark ? 'text-bodyContrast' : 'text-blackContrast';
  } else {
    return isDark ? 'text-white' : 'text-black';
  }
}
