import { MutationTuple, gql, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { SymbolIcon } from '@radix-ui/react-icons';
import React from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';
import { useLogger } from '../../contexts/LoggingProvider';
import { SessionContextInterface, useSession } from '../../contexts/SessionProvider';
import { As, RainMakerProfileQuery, SwitchAsMutation } from '../../gql/graphql';
import { useFormWrapper } from '../../hooks/useFormWrapper';
import { useUploadProfileMedia } from '../../hooks/useUploadMedia';
import theme, { preWrap } from '../../theme';
import { GET_SESSION_FRAGMENT, updateProfile } from '../../utils/graphql';
import { getProfileId, hasRoles, isRainMaker, locationFormatter } from '../../utils/helper';
import { intl } from '../../utils/intl';
import { HStack } from '../HStack';
import { VStack } from '../VStack';
import { SecondaryButton } from '../buttons/core/SecondaryButton';
import SaveFormButton from '../form/SaveFormButton';
import { StatusText } from '../form/StatusText';
import { FormTextArea } from '../form/input/FormTextArea';
import { ImageUpload } from '../form/input/ImageUpload';
import { AlertStatus } from '../internal/AlertBanner';
import { Body1 } from '../typography/Body1';
import { Body2 } from '../typography/Body2';
import { Display } from '../typography/Display';
import { Link } from '../typography/Link';
import { Avatar } from './Avatar';

const SWITCH_AS_MUTATION = gql`
  ${GET_SESSION_FRAGMENT}
  mutation switchAs($as: As!) {
    switchAs(as: $as) {
      ...SessionInfo
    }
  }
`;

type Values = {
  avatarUuid?: string;
  bio?: string;
};

const schema = Yup.object().shape({
  avatarUuid: Yup.string().optional(),
  bio: Yup.string()
    .optional()
    .max(500, intl.formatMessage({ id: 'formMaxBio' }, { max: 500 })),
});

export function ProfileContent<Variables, Mutation>(props: {
  onClose?: () => void;
  updateMutation: MutationTuple<Mutation, Variables>;
  profile: Exclude<RainMakerProfileQuery['rainMakerProfile'], null | undefined>;
}) {
  const { updateMutation } = props;
  const sessionTuple = useSession();
  const { session } = sessionTuple;
  const [profile, setProfile] = React.useState<Exclude<RainMakerProfileQuery['rainMakerProfile'], null | undefined>>(
    props.profile,
  );
  const methods = useFormWrapper<Values>({
    resolver: yupResolver<Values>(schema),
    mode: 'onBlur',
  });
  const { register, handleSubmit, formState } = methods;
  const isEditable = isRainMaker(session) && getProfileId(session) === profile.uuid;
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [isUploadingAvatar, setIsUploadingAvatar] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<{ status: AlertStatus; content?: React.ReactNode } | undefined>();
  const { logError } = useLogger();
  const onSave: SubmitHandler<Values> = async (values) => {
    const input: { uuid: string; about?: string; avatar?: string } = { uuid: profile.uuid };
    if (values.bio !== undefined) {
      input.about = values.bio;
    }
    if (values.avatarUuid !== undefined) {
      input.avatar = values.avatarUuid;
    }
    try {
      const updated = await updateProfile({
        mutation: updateMutation,
        variables: { input, ...(input.avatar ? { withAvatar: true } : null) } as Variables,
        setStatus,
      });
      const updatedData = updated[Object.keys(updated)[0]];
      setProfile((p) => ({
        ...p,
        ...updatedData,
      }));
      setIsEditing(false);
    } catch (error) {
      logError('could not save updates to profile', { error });
    }
  };
  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4 divide-y divide-neutral-800">
        <VStack space="6">
          {isEditable ? (
            <AvatarUpload
              name={profile.name}
              isPerson
              uri={profile.avatar}
              id={profile.uuid}
              onSuccess={async (avatarUuid) => onSave({ avatarUuid })}
              uploading={isUploadingAvatar}
              setUploading={setIsUploadingAvatar}
            />
          ) : (
            <Avatar isPerson name={profile.name} uri={profile.avatar} spanDirection="row" className="max-w-[200px]" />
          )}
          <VStack space="2">
            <Display size="lg" children={profile.name} />
            {profile.city || profile.country ? (
              <Body2
                children={locationFormatter(profile.city, profile.country?.label)}
                color={theme.colors.neutral[500]}
              />
            ) : null}
            {profile.linkedin ? <Link href={profile.linkedin}>Linkedin</Link> : null}
          </VStack>
          {isEditing ? (
            <>
              <FormTextArea
                {...register('bio')}
                formState={formState}
                label="bio"
                charLimit={500}
                defaultValue={profile.about ?? ''}
                color="text-white"
                containerClassname="border-neutral-100 focus-within:border-neutral-100"
                rows={7}
              />
              <SaveFormButton
                handleSubmit={handleSubmit(onSave)}
                isDirty={formState.isDirty}
                isSubmitDisabled={formState.isSubmitting || !formState.isDirty}
                isSubmitting={formState.isSubmitting}
                isValid={formState.isValid}
                status={status}
              />
            </>
          ) : (
            <>
              <Body2 children={profile.about} style={preWrap} />
              {isEditable ? (
                <HStack className="items-center" space="6">
                  <SecondaryButton variant="contrast-dark" className="md:w-[120px]" onClick={() => setIsEditing(true)}>
                    Edit Bio
                  </SecondaryButton>
                  <StatusText status={status} />
                </HStack>
              ) : null}
            </>
          )}
        </VStack>
        <RoleSwitch sessionTuple={sessionTuple} />
      </div>
    </FormProvider>
  );
}

type AvatarUploadProps = {
  name: string;
  isPerson: boolean;
  uri?: string | null;
  id: string;
  uploading: boolean;
  setUploading: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: (avatarUuid: string) => void;
};
export const AvatarUpload = (props: AvatarUploadProps) => {
  const { name, isPerson, uri, id, setUploading, uploading, onSuccess } = props;
  const [getUploadMediaQuery] = useUploadProfileMedia();
  return (
    <ImageUpload
      autosave
      avatarClassName="max-w-[200px] max-h-[200px]"
      avatarSpanDirection="row"
      fieldName="avatar"
      getUploadMediaUrl={getUploadMediaUrl}
      isPerson={isPerson}
      logo={uri}
      name={name}
      setUploading={setUploading}
      showIcon
      submitImageData={onSuccess}
      uploading={uploading}
    />
  );
  async function getUploadMediaUrl(file: File) {
    const res = await getUploadMediaQuery({
      variables: {
        profileId: id,
        usage: 'avatar',
        type: file.type,
      },
    });
    return { apolloClient: res.client, data: res.data?.uploadUrl };
  }
};

export function RoleSwitch({ sessionTuple: { session, update } }: { sessionTuple: SessionContextInterface }) {
  const [switchAsMutation] = useMutation<SwitchAsMutation>(SWITCH_AS_MUTATION);
  const logger = useLogger();
  let currentRole: string | undefined;
  let switchedRole: string | undefined;
  let switchedAs: string | undefined;
  const showRoleSwitch = hasRoles(session.roles, ['admin', 'manager']) && session.rainMakerId;
  switch (session.as) {
    case As.RainMaker:
      currentRole = 'DM';
      switchedRole = session.roles?.includes(As.Admin) ? 'Admin' : 'Manager';
      switchedAs = switchedRole.toLowerCase();
      break;
    case As.Manager:
      currentRole = 'Manager';
      switchedRole = 'DM';
      switchedAs = As.RainMaker;
      break;
    case As.Admin:
      currentRole = 'Admin';
      switchedRole = 'DM';
      switchedAs = As.RainMaker;
      break;
  }
  if (!currentRole || !showRoleSwitch) {
    return null;
  }
  return (
    <div className="flex flex-row place-content-between pt-4">
      <Body1>{`Signed in as: ${currentRole}`}</Body1>
      <Link
        onClick={async () => {
          const res = await switchAsMutation({
            variables: {
              as: switchedAs,
            },
          });
          if (res.errors) {
            logger.logError('could not switch role', { errors: res.errors });
            return;
          }
          if (res.data?.switchAs) {
            update(res.data.switchAs);
            window.location.reload();
          }
        }}
      >
        <span className="flex flex-row gap-x-1 items-center">
          <SymbolIcon color="currentColor" />
          {`Switch to ${switchedRole}`}
        </span>
      </Link>
    </div>
  );
}
