import React from 'react';
import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { useLogger } from '../../../contexts/LoggingProvider';
import { handleFileChange, UploadMediaUrlResponse } from '../../../utils/forms/common';
import { HStack } from '../../HStack';
import { Pressable } from '../../Pressable';
import { Avatar, AvatarProps } from '../../profile/Avatar';
import { Body1 } from '../../typography/Body1';

export const ImageUpload = ({
  autosave,
  avatarClassName,
  twAvatarSize = 'h-[100px] w-[100px]',
  avatarSpanDirection,
  fieldName,
  getUploadMediaUrl,
  isPerson,
  logo,
  name,
  onClick,
  setUploading,
  showIcon,
  submitImageData,
  title,
  uploading,
  withBorder,
}: {
  autosave?: boolean;
  avatarClassName?: string;
  twAvatarSize?: string;
  avatarSpanDirection?: 'row' | 'column';
  fieldName: string;
  getUploadMediaUrl: (file: File) => Promise<UploadMediaUrlResponse>;
  logo?: string | null;
  name: string;
  onClick?: () => void;
  isPerson: boolean;
  setUploading: (state: boolean) => void;
  showIcon?: boolean;
  submitImageData?: (updatedUuid: string) => void;
  title?: string;
  uploading: boolean;
  withBorder?: boolean;
}) => {
  const loggingContext = useLogger();
  const uploadInputRef = React.useRef<HTMLInputElement | null>();
  const { setError, setValue, getValues } = useFormContext();

  const content = getContent();
  if (showIcon) {
    return content;
  }
  return (
    <Pressable onClick={onSelectFile}>
      <HStack className="items-center" space="4">
        {content}
        {title ? <Body1 className="text-rain">{title}</Body1> : null}
      </HStack>
      <input
        ref={(r) => {
          if (r) {
            uploadInputRef.current = r;
          }
        }}
        hidden
        id="upload"
        name="logo"
        accept="image/*"
        type="file"
        onChange={onChange}
      />
    </Pressable>
  );

  function getContent() {
    const avatarProps: AvatarProps = {
      className: twMerge('relative', avatarClassName, avatarSpanDirection ? undefined : twAvatarSize),
      isLoading: uploading,
      isPerson,
      name,
      onChange: showIcon ? onChange : undefined,
      onClick,
      spanDirection: avatarSpanDirection as any,
      uri: getValues(fieldName) ?? logo,
      withBorder,
    };
    return <Avatar {...avatarProps} />;
  }

  function onSelectFile() {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    handleFileChange({
      onLoadStart() {
        setError(fieldName, {});
        setUploading(true);
      },
      async getUploadMediaUrl({ file }) {
        return getUploadMediaUrl(file);
      },
      getFetchOptions({ file }) {
        return {
          method: 'PUT',
          body: file,
        };
      },
      onUploadSuccess({ data }, { fileReader }) {
        setValue(fieldName, fileReader.result);
        setValue(`${fieldName}Uuid`, data.uuid, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
        if (autosave && submitImageData) {
          submitImageData(data.uuid);
        }
      },
      onUploadFail() {
        setValue(fieldName, undefined);
        setValue(`${fieldName}Uuid`, undefined, { shouldValidate: true });
      },
      onError(err) {
        setError(fieldName, { message: 'Upload failed' });
        loggingContext.logError('Logo upload failed', { error: err });
      },
      onCleanup() {
        e.target.value = '';
        setUploading(false);
      },
    })(e);
  }
};
