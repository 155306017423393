import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useDarkModeFlag } from '../../../contexts/DarkModeProvider';
import { ButtonSize, useButtonPadding } from '../../../hooks/useButtonPadding';
import { Button, ButtonProps } from './Button';

export type TertiaryButtonProps = Omit<ButtonProps, 'kind'> & {
  rightIcon?: React.ReactNode;
  size?: ButtonSize;
};

export const TertiaryButton = React.memo(
  React.forwardRef<HTMLButtonElement, TertiaryButtonProps>((props, ref) => {
    const isDark = useDarkModeFlag();
    const { className, size, ...rest } = props;
    const isDisabled = props.isDisabled || props.disabled || props.isLoading;
    let bgColor;
    let bgPressed;
    let bgDisabled;
    let textColor;
    let fillColor;
    if (isDark) {
      bgColor = 'bg-neutral-800';
      bgPressed = 'active:bg-neutral-700 enabled:hover:bg-neutral-700';
      bgDisabled = 'disabled:bg-neutral-800';
      textColor = 'text-neutral-200';
      fillColor = 'fill-neutral-200';
    } else {
      bgColor = 'bg-neutral-200';
      bgPressed = 'active:bg-neutral-300 enabled:hover:bg-neutral-300';
      bgDisabled = 'disabled:bg-neutral-200';
      textColor = 'text-neutral-700';
      fillColor = 'fill-neutral-700';
    }
    const padding = useButtonPadding(size);
    return (
      <Button
        {...rest}
        className={twMerge(bgColor, bgPressed, bgDisabled, padding, isDisabled ? 'opacity-50' : undefined, className)}
        textColor={textColor}
        fillColor={fillColor}
        ref={ref}
      >
        {props.children as any}
      </Button>
    );
  }),
);
