import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ANON_PATH } from '../../constants';
import { useSession } from '../../contexts/SessionProvider';
import { hasRole } from '../../utils/helper';
import { PresenceWrapper } from '../page/PresenceWrapper';
import { AuthenticatedRoute } from './AuthenticatedRoutes';
import { checkRedirects } from './checkRedirects';

export const CompanyRoutes = ({ children }: { children: React.ReactNode }) => {
  return (
    <PresenceWrapper>
      <Content children={children} />
    </PresenceWrapper>
  );
};

const Content = ({ children }: { children: React.ReactNode }) => {
  const { session } = useSession();
  const location = useLocation();

  if (!session) {
    return <Navigate to={ANON_PATH} state={{ from: location }} replace />;
  }

  const redirect = checkRedirects(session);
  if (redirect) {
    return redirect;
  }

  if (!hasRole(session.roles, 'rain_staff')) {
    return <Navigate to="/error" state={{ from: location }} />;
  }

  return <AuthenticatedRoute children={children} />;
};
