import { gql, QueryResult, useQuery, useSubscription } from '@apollo/client';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon, ExclamationTriangleIcon } from '@radix-ui/react-icons';
import * as Toast from '@radix-ui/react-toast';
import React from 'react';
import { useParams } from 'react-router-dom';
import { PrimaryButton } from '../components/buttons/core/PrimaryButton';
import { HStack } from '../components/HStack';
import { Body1 } from '../components/typography/Body1';
import { Body2 } from '../components/typography/Body2';
import {
  StartupProfileQuery,
  StartupProfileQueryVariables,
  StartupUpdateSubscription,
  StartupUpdateSubscriptionVariables,
} from '../gql/graphql';
import '../radix.css';
import { FACT_FRAGMENT, RM_CONFLICT_FRAGMENT, STARTUP_PROFILE_FIELDS } from '../utils/graphql';
import { createCtx } from './common';

const STARTUP_UPDATE_SUBSCRIPTION = gql`
  ${FACT_FRAGMENT}
  subscription startupUpdate($companyId: String!) {
    startupUpdate(companyId: $companyId) {
      companyId
      factSheet {
        ...FactSheetFields
      }
    }
  }
`;

export const STARTUP_PROFILE_QUERY = gql`
  ${FACT_FRAGMENT}
  ${RM_CONFLICT_FRAGMENT}
  ${STARTUP_PROFILE_FIELDS}
  query startupProfile($companyId: ID!) {
    startupProfile(companyId: $companyId) {
      ...StartupFields
    }
    factSheet(companyId: $companyId) {
      ...FactSheetFields
    }
    rainMakerConflict(companyId: $companyId) {
      ...RmConflictFields
    }
  }
`;

const [useCtx, Provider] =
  createCtx<[QueryResult<StartupProfileQuery, StartupProfileQueryVariables>, (isMutating: boolean) => void]>();

export const StartupProfileQueryProvider = ({ children }: { children: React.ReactNode }) => {
  const { startupId } = useParams();
  const result = useQuery<StartupProfileQuery, StartupProfileQueryVariables>(STARTUP_PROFILE_QUERY, {
    variables: {
      companyId: startupId!,
    },
    skip: !startupId,
    fetchPolicy: 'cache-first',
  });
  const localFactSheetVersion = result.data?.factSheet?.version;
  const [remoteFactSheetVersion, setRemoteFactSheetVersion] = React.useState<number>();
  const [isWarningOpen, setIsWarningOpen] = React.useState<boolean>(false);
  const [isMutating, setIsMutating] = React.useState<boolean>(false);
  useSubscription<StartupUpdateSubscription, StartupUpdateSubscriptionVariables>(STARTUP_UPDATE_SUBSCRIPTION, {
    variables: {
      companyId: startupId!,
    },
    skip: !startupId,
    fetchPolicy: 'network-only',
    onData: ({ data }) => {
      const update = data.data?.startupUpdate;
      if (!update) return;
      if (update.companyId === startupId) {
        // result.updateQuery((prev) => ({
        //   ...prev,
        //   factSheet: {
        //     ...prev.factSheet,
        //     ...update.factSheet,
        //   },
        // }));
        setRemoteFactSheetVersion(update.factSheet.version);
      }
    },
  });
  React.useEffect(() => {
    if (isMutating || remoteFactSheetVersion === undefined || localFactSheetVersion === undefined) {
      return;
    }
    setIsWarningOpen(remoteFactSheetVersion > localFactSheetVersion);
  }, [isMutating, localFactSheetVersion, remoteFactSheetVersion]);
  return (
    <Provider
      value={[result, setIsMutating]}
      children={
        <>
          {children}
          <Toast.Provider swipeDirection="left">
            <Toast.Viewport className="ToastViewport">
              {/* using Toast.Root will make the dialog auto disappear */}
              {/* modal=false to make the area outside interactable */}
              <Dialog.Root open={isWarningOpen} onOpenChange={setIsWarningOpen} modal={false}>
                {/* point-events-auto: make sure content is interactable. Toast.Viewport set pointer events to none. */}
                <Dialog.Content
                  className="ToastRoot pointer-events-auto"
                  onPointerDownOutside={(e) => {
                    // prevent closing on pointer down outside
                    e.preventDefault();
                  }}
                >
                  <Toast.Title className="ToastTitle">
                    <HStack space="2" className="items-center">
                      <ExclamationTriangleIcon />
                      <Body1>Outdated info</Body1>
                    </HStack>
                  </Toast.Title>
                  <Toast.Description className="ToastDescription">
                    <Body2>This page has been updated. Reload to view the latest version.</Body2>
                  </Toast.Description>
                  <Toast.Action className="ToastAction" asChild altText="Reload">
                    <PrimaryButton size="sm" variant="contrast" className="self-end" onClick={() => location.reload()}>
                      Reload
                    </PrimaryButton>
                  </Toast.Action>
                  {/* position top right */}
                  <Dialog.Close className="self-start justify-self-end ToastAction">
                    <Cross2Icon />
                  </Dialog.Close>
                </Dialog.Content>
              </Dialog.Root>
            </Toast.Viewport>
          </Toast.Provider>
        </>
      }
    />
  );
};

export const useStartupProfileQuery = useCtx;
